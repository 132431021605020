// filters.js

const { isDate } = require('../js-types')
const { _v } = require('../_v')

/**
 * Returns the default filter operator given a filter type.
 * @param {string} type - Filter type (e.g. string, number, etc).
 * @returns {string} - Returns the default operator for the filter type. Allowed values are: contains, eq.
 */
const defOp = type => {
  return type === 'string' ? 'contains' : 'eq'
}

/**
 * Resets a grid filter to default operator and value.
 * @param {object} cmpObj - cmp object representing a filter <input> element.
 */
const resetFilter = cmpObj => {
  const defaultOp = defOp(cmpObj.type)
  cmpObj.op = cmpObj.op === defaultOp ? cmpObj.op : defaultOp
  cmpObj.el.value = cmpObj.el.value === '' ? cmpObj.el.value : ''
}

/**
 * Accepts an operator, family, and value, and returns a processed value based on the three. Intended to process values used during client-side filter execution.
 * @param {string} operator - Filter operator (e.g. 'nmNEq'). See filterOperators object for allowed operators.
 * @param {string} family - Filter family (e.g. 'datetime'). See filterOperators object for allowed  families.
 * @param {any} val - Value to process.
 * @returns {any}
 */
const processVal = (operator, family, val) => {
  let procVal

  switch (family) {
    case 'int':
    case 'float':
      procVal = typeof val === 'string' ? Math.fround(parseFloat(val)) : Math.fround(val)
      break
    case 'string':
      procVal = val.toLowerCase()
      break
    case 'datetime':
      if (operator === 'sameDay' || operator === 'sameHour' || operator === 'sameMinute') {
        procVal = val
      } else {
        procVal = val.getTime()
      }
      break
    case 'date':
      procVal = val.getTime()
      break
    default:
      procVal = val
  }
  return procVal
}

/**
 * Defines filter operator names.
 * @constant
 * @type {object}
 * @property {object} - string
 * @property {object} - number
 * @property {object} - date
 * @property {object} - time
 * @property {object} - datetime
 * @property {object} - enums
 */
const filterOperators = {
  string: {
    notBlank: 'FILTERCELL:STRING:notBlank',
    contains: 'FILTERCELL:STRING:contains',
    startswith: 'FILTERCELL:STRING:startswith',
    eq: 'FILTERCELL:STRING:eq',
    neq: 'FILTERCELL:STRING:neq',
    doesnotcontain: 'FILTERCELL:STRING:doesnotcontain',
    endswith: 'FILTERCELL:STRING:endswith'
  },
  number: {
    eq: 'FILTERCELL:NUMBER:eq',
    neq: 'FILTERCELL:NUMBER:neq',
    gte: 'FILTERCELL:NUMBER:gte',
    gt: 'FILTERCELL:NUMBER:gt',
    lte: 'FILTERCELL:NUMBER:lte',
    lt: 'FILTERCELL:NUMBER:lt'
  },
  date: {
    eq: 'FILTERCELL:NUMBER:eq',
    neq: 'FILTERCELL:NUMBER:neq',
    gte: 'FILTERCELL:DATETIME:gte',
    gt: 'FILTERCELL:DATETIME:gt',
    lte: 'FILTERCELL:DATETIME:lte',
    lt: 'FILTERCELL:DATETIME:lt'
  },
  datetime: {
    eq: 'FILTERCELL:NUMBER:eq',
    neq: 'FILTERCELL:NUMBER:neq',
    gte: 'FILTERCELL:DATETIME:gte',
    gt: 'FILTERCELL:DATETIME:gt',
    lte: 'FILTERCELL:DATETIME:lte',
    lt: 'FILTERCELL:DATETIME:lt',
    sameDay: 'FILTERCELL:DATETIME:sameDay',
    sameHour: 'FILTERCELL:DATETIME:sameHour',
    sameMinute: 'FILTERCELL:DATETIME:sameMinute'
  },
  time: {
    eq: 'FILTERCELL:NUMBER:eq',
    neq: 'FILTERCELL:NUMBER:neq',
    gte: 'FILTERCELL:DATETIME:gte',
    gt: 'FILTERCELL:DATETIME:gt',
    lte: 'FILTERCELL:DATETIME:lte',
    lt: 'FILTERCELL:DATETIME:lt'
  },
  enums: {
    eq: 'FILTERCELL:NUMBER:eq',
    neq: 'FILTERCELL:NUMBER:neq'
  }
}

/**
 * Defines functions used for different filter operations.
 * @constant
 * @type {object}
 */
const filterFunctions = {
  sameDay(v, fv) {
    if (!v && !fv) return true
    if (!isDate(v) || !isDate(fv)) return false
    if (v.getDate() !== fv.getDate()) return false
    if (v.getMonth() !== fv.getMonth()) return false
    if (v.getFullYear() !== fv.getFullYear()) return false
    return true
  },

  sameHour(v, fv) {
    if (!filterFunctions.sameDay(v, fv)) return false
    if (v.getHours() !== fv.getHours()) return false
    return true
  },

  sameMinute(v, fv) {
    if (!filterFunctions.sameHour(v, fv)) return false
    if (v.getMinutes() !== fv.getMinutes()) return false
    return true
  },

  notBlank(v) {
    return typeof v === 'string' && v ? true : false
  },

  contains(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.indexOf(fv) > -1
  },

  doesnotcontain(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.indexOf(fv) === -1
  },

  startswith(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.substr(0, fv.length) === fv
  },

  endswith(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.substr(-1 * fv.length) === fv
  },

  logic(v, fv) {
    return (v && fv.true) || (!v && fv.false)
  },

  gt(v, fv) {
    return v > fv
  },

  gte(v, fv) {
    return v >= fv
  },

  lt(v, fv) {
    return v < fv
  },

  lte(v, fv) {
    return v <= fv
  }
}

/**
 * Compares a record and a filter and determines if they match.
 * @param {object} r - Object containing data from a single grid row.
 * @param {object} f - Object representing the filter value.
 * @property {string} f.operator - String representing the filter operator (e.g. 'nmGT'). See filterOperators for full list of possilble filter operators.
 * @property {any} f.value - Value of filter.
 * @param {object[]} cols - Array of objects representing the grid columns.
 * @property {string} cols[].field - String representing the name of the grid column.
 * @property {string} cols[].type - String representing the grid column type (e.g. number, string, boolean, etc). See filterOperators for full list of possilble types.
 * @returns {boolean}
 */
const applyFilter = (r, f, cols) => {
  const op = f.operator
  if (typeof op === 'undefined' || op == null) return true

  const fv = f.value
  if (typeof fv === 'undefined' || fv == null || fv === '') return true

  const found = cols.find(element => element.field === f.field)
  if (!found) return true
  const type = found.type

  var v = _v(r, f.field)
  if (typeof v === 'undefined' && type === 'boolean') v = false //Treat undefined Boolean values as false.

  if (op === 'null') return v == null
  if (op === 'notnull') return true

  if (typeof v === 'undefined' || v == null) return false

  const procV = processVal(op, type, v)
  const procFV = processVal(op, type, fv)

  if (type === 'number' && isNaN(procFV)) return true

  if (op === 'neq') return procV !== procFV
  if (op === 'eq') return procV === procFV

  const fn = filterFunctions[op]
  if (fn) return fn(procV, procFV)

  return true
}

module.exports = {
  filterFunctions,
  applyFilter,
  filterOperators,
  defOp,
  resetFilter
}
