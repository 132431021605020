const { cssStringToObject, camel } = require('../css')
const { $map } = require('../element-map')
const { isObject } = require('../js-types')
const { cmpCollection } = require('./cmpCollection')
const { $cmp } = require('./$cmp')
const { c, isCmp } = require('./c')
const { render, renderAsync, renderTo } = require('./cmp-renderer')
const { bindState } = require('./cmp-state')
const { html } = require('./html')
const { isElement } = require('./isElement')
const { mergeProps } = require('./mergeProps')
const { on, trigger } = require('../events')

const normProps = {
  isNorm: true,

  toString() {
    return '<' + this.el.tagName + '></' + this.el.tagName + '>' // todo
  },

  renderAttrs() {
    // do nothing
  },

  renderStyles() {
    // do nothing
  },

  on(...args) {
    on(this.el, ...args)
    return this
  },

  trigger(...args) {
    trigger(this.el, ...args)
    return this
  },

  off() {
    console.error('Cmp.off() is not supported') // unsupported
    return this
  },

  addClass(cls) {
    cls
      .split('.')
      .join(' ')
      .split(' ')
      .forEach(x => x && this.el.classList.add(x))
    return this
  },

  removeClass(cls) {
    cls
      .split('.')
      .join(' ')
      .split(' ')
      .forEach(x => x && this.el.classList.remove(x))
    return this
  },

  hasClass(cls) {
    return this.el.classList.contains(cls)
  },

  toggleClass(cls) {
    this.hasClass(cls) ? this.removeClass(cls) : this.addClass(cls)
    return this
  },

  setAttrs(attrs) {
    if (attrs.style) this.css(attrs.style)
    if (attrs.class) attrs.class.split(' ').forEach(cls => this.addClass(cls))

    let attr
    Object.keys(attrs).forEach(k => {
      if (k === 'style' || k === 'class') return

      attr = attrs[k]
      if (k === 'value') {
        this.el.value = attr
        return this
      }
      if (attr === undefined) {
        if (this.el.hasAttribute(k)) this.el.removeAttribute(k)
        return // next
      }
      this.el.setAttribute(k, attr)
    })
    return this
  },

  attr(name, value) {
    if (typeof name === 'object') return this.setAttrs(name)
    if (arguments.length > 1) return this.setAttrs({ [name]: value })
    return this.el
      ? name === 'value'
        ? this.el.value
        : this.el.getAttribute(name)
      : this.attrs[name]
  },

  css(cssToMerge) {
    if (typeof cssToMerge === 'string') {
      if (cssToMerge !== '' && cssToMerge.split(':').length === 1)
        return this.el.style[camel(cssToMerge)]
      cssToMerge = cssStringToObject(cssToMerge)
    }
    let i,
      p,
      keys = Object.keys(cssToMerge)
    for (i = 0; i < keys.length; i++) {
      p = keys[i]
      if (cssToMerge[p] !== this.el.style[p]) {
        if (cssToMerge[p] === undefined) this.el.style[p] = ''
        else {
          // !important is invalid here
          this.el.style[p] = (cssToMerge[p] + '')
            .replace(' !important', '')
            .replace('!important', '')
            .replace(' ! important', '')
            .replace('! important', '')
        }
      }
    }
    return this
  },

  applyState(prevState = {}) {
    return prevState // this state
  },

  bindState(...args) {
    return bindState(this, ...args)
  },

  render(scope) {
    render(this, scope)
    return this.el
  },

  renderTo(parentEl) {
    return renderTo(this, parentEl)
    // parentEl.appendChild(this.el)
    // let renderScope = {
    //   parentEl,
    //   inits: []
    // }
    // renderChildren(this, renderScope)
    // renderScope.inits.forEach(f => f())
    // return this.el
  },

  /**
   *
   * @returns promise
   */
  renderAsync() {
    return renderAsync(this)
  },

  find(selector) {
    return Array.from(this.el.querySelectorAll(selector))
  },

  /**
   * sets the kids on a norm el and renders them replacing the
   * @param {qc[]} content
   * @returns this if setting content, else cmpCollection
   */
  kids(content) {
    if (arguments.length === 0)
      return cmpCollection(
        Array.from(this.el.childNodes).map(el => (el.tagName ? qc(el) : html(el.nodeValue)))
      )

    this.children = qc(Array.isArray(content) ? content : [content]).map(x => x ?? [])

    if (this.el && !this.rendering) render(this)
    return this
  },

  /**
   * sets properties on the norm object (not the element) using Object.assign()
   * convenient for chaining
   * returns this
   */
  props(props) {
    if (props.attrs) {
      const attrs = props.attrs
      delete props.attrs

      const style = attrs.style
      delete attrs.style
      if (style) this.css(style)

      const classes = attrs.class
      delete attrs.class
      if (classes) this.addClass(classes)

      this.attr(attrs)
    }
    Object.assign(this, props)
    return this
  }
}

/**
 * A standard DOM element that isn't controlled by Cmp
 * @param {HTMLElement} el
 */
const norm = el => {
  const me = mergeProps({ el }, normProps)
  me.isNorm = true
  me.tag = el.tagName.toLowerCase()

  // const { decompose } = require('../qc-code')
  me.children = undefined // Array.from(me.el?.childNodes ?? []).map(node => decompose(node))

  $map(el).cmp = me
  return me
}

const Qc = Object.create(normProps)
delete Qc.toCode

/**
 * qc() Quick Component -
 * @type { qc }
 *
 * eg qc('div#id.class1.class2', children)
 *
 * @arg {string} tag - selector with id and classes 'div#id.class1.class2'
 * @arg {string | array | object} children
 *
 * @prop {function} kids
 * @prop {function} addClass
 * @prop {function} removeClass
 * @prop {function} css
 * @prop {function} attr
 * @prop {function} renderTo
 * @prop {function} renderAsync
 * @prop {function} bindState
 * @see bindState
 * @prop {HTMLElement} el
 *
 * @returns qc
 *
 */
const qc = function (tag, children) {
  let initOrProps = arguments[2]
  let arg4 = arguments[3]

  if (isElement(tag)) return $cmp(tag)
  if (isCmp(tag)) return tag // if we ever have qc(qc(?))
  if (Array.isArray(tag)) return cmpCollection(tag)
  const isHTML = s => typeof s === 'string' && s[0] === '<'
  if (isHTML(tag)) return html(tag)

  if (isObject(tag)) {
    const me = c({
      ...tag,
      isQc: true,
      qcRendered() {
        delete me.isCmp
        Object.setPrototypeOf(me, Qc)
      }
    })

    const _on = me.on
    me.on = (et, handler) => {
      if (et === 'init') return _on.call(me, et, handler)
      me.el ? on(me.el, et, handler) : me.on('init', () => on(me.el, et, handler))
      return me
    }
    return me
  }

  if (typeof tag !== 'string') throw 'Unexpected condition in qc()'
  if (!Array.isArray(children))
    if (children && isObject(children) && !isCmp(children)) {
      const props = { tag, attrs: children, children: initOrProps }
      if (typeof arg4 === 'function') props.init = arg4
      return qc(props)
    }

  let props = initOrProps && typeof initOrProps === 'object' ? initOrProps : {}
  if (typeof initOrProps === 'function') props.init = initOrProps
  props.tag = tag
  props.children = children
  return qc(props)
}

module.exports.qc = qc
module.exports.norm = norm
