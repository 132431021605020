// data-model.js

// Notes:
// No presentation level functionality here.
//
// Grid ORDERING/Sorting is a presentation issue, not data
// it can be based on data but does not change the ordering in the arrays.
// Never reorder array model members
//
// Deleting - When a child is deleted, mark the record as deleted
// The appearance of deleted records is presentation NOT data
// Do not remove them
//
// The original dataset array index is meta.reci (no longer rowi)

let metaMap = new WeakMap()

const $meta = model => {
  const newValue = {}
  if (!metaMap.has(model)) metaMap.set(model, newValue)
  return metaMap.get(model)
}

const { $cmp } = require('../cmp/$cmp')
const { isDate, isObject } = require('../js-types')
const { _v } = require('../_v')

const schemaReserved = {
  isModel: 1,
  isCollection: 1,
  itemSchema: 1,
  ignore: 1,
  validation: 1,
  required: 1,
  prePopulate: 1
}

/**
 *
 * @param {object} model
 * @returns Array of field Definitions
 */
const schemaFields = model =>
  Object.keys($meta(model).schema)
    .filter(key => !(key in schemaReserved))
    .map(key => {
      const result = { fieldName: key }
      Object.assign(result, $meta(model).schema[key])
      return result
    })

const compareVal = (v, fieldSch = {}) => {
  if (fieldSch.type === 'boolean') return v ? true : false
  return isDate(v) ? v.valueOf() : v
}

/**
 * Creates a "meta" object associated using a Weak map
 * the object maintains data change tracking information
 * @param {Object} model - the hierarchical data object
 * @param {string} name - top level should be '' used as dsName for the change notifications to the Cmp objects in the DOM
 * @param {object} schema - Object where keys are fieldnames and values describe field schema
 * @param {object} parentMeta meta of the parent data object in the data hierarhy
 * @returns object - meta
 */
const initModel = (model, name, schema, parentMeta) => {
  if (!model) console.warn('model cannot be null')
  const meta = $meta(model)
  meta.model = model

  meta.orig = {}
  meta.prev = {}
  meta.changes = {}

  meta.name = name

  if (parentMeta) meta.parent = parentMeta
  meta.fullName = (parentMeta && parentMeta.fullName) || ''
  meta.fullName += (meta.fullName ? '.' : '') + name

  schema = schema || meta.schema || {}
  meta.schema = schema

  if (schema.prePopulate) schema.prePopulate(model)

  if (Array.isArray(model)) {
    schema.itemSchema = schema.itemSchema || {}
    let i, item
    for (i = 0; i < model.length; i++) {
      item = model[i]
      if (item && isObject(item) && !isDate()) {
        const kidM = initModel(item, i.toString(), schema.itemSchema, meta)
        kidM.reci = i
      }
      meta.orig[i.toString()] = item
    }
  } else {
    Object.keys(model).forEach(f => {
      schema[f] = schema[f] || {}
      let kid = model[f]

      if (schema[f].isModel || (kid && isObject(kid) && !isDate(kid))) {
        schema[f].isModel = true

        if (schema[f].isCollection !== true && Array.isArray(kid)) schema[f].isCollection = true
        if (!kid) model[f] = schema[f].isCollection ? [] : {}
        if (kid) initModel(kid, f, schema[f], meta) // if not null or undefined
      }

      // convert string dates
      if (schema[f].type === 'date' || schema[f].type === 'datetime') {
        if (typeof kid === 'string') {
          kid = kid === '' ? undefined : new Date(model[f])
          model[f] = kid
        }
      }

      meta.orig[f] = compareVal(kid, schema[f])
    })
  }

  return meta
}

/**
 * tests for changes to the model since before
 *
 * @param {Object} view
 * @param {Object} model
 * @param {string?} fieldName optional
 */
const react = (view, model, ...fieldNames) => {
  const meta = $meta(model)
  if (!meta) return
  if (!fieldNames.length) return react(view, model, ...Object.keys(model))

  fieldNames.forEach(fieldName => {
    if (!(fieldName in meta.schema)) meta.schema[fieldName] = { ignore: false }

    const sch = meta.schema[fieldName]

    const vNew = compareVal(model[fieldName], sch)

    const vPrev = compareVal(
      fieldName in meta.prev ? meta.prev[fieldName] : meta.orig[fieldName],
      sch
    )

    const hasChanged = vNew !== vPrev || (vNew === false && !!vPrev)

    if (hasChanged) {
      meta.prev[fieldName] = vNew
      if (!sch.ignore) {
        if (vNew !== meta.orig[fieldName]) meta.changes[fieldName] = true
        else delete meta.changes[fieldName]

        if (sch.isModel) {
          if (model[fieldName]) initModel(model[fieldName], fieldName, sch.itemSchema, meta)
        }

        const boundCmps = (view.qTop ? view.qTop.find('[data-model-name]') : []).map($cmp)

        reactUpstream(boundCmps, meta, fieldName, vPrev)
      }
    }
  })
}

const modelBind = (view, cmp, model) => {
  const meta = $meta(model)
  cmp.dsName = meta.fullName
  cmp.model = model
  cmp.attr({ 'data-model-name': meta.fullName || 'model' })

  if (cmp.fieldName)
    cmp.bindState(
      () => _v(model, cmp.fieldName),
      function (v) {
        if (document.activeElement !== cmp.el)
          cmp.val ? cmp.val(v, model, true) : cmp.el?.val ? cmp.el?.val(v, model, true) : undefined
      }
    ) // called before render() - checks state

  return cmp.on('ow-change', e => {
    if (cmp.el === e.target) {
      if (cmp.fieldName) {
        const unknown = {}
        const v = cmp.val
          ? cmp.val(v, model, true)
          : cmp.el?.val
          ? cmp.el?.val(v, model, true)
          : unknown

        if (v !== unknown) {
          _v(cmp.model, cmp.fieldName, v)
          react(view, cmp.model, cmp.fieldName)
        }
      }
    }
  })
}

const reactUpstream = (boundCmps, meta, fieldName, vPrev) => {
  let i,
    cmp,
    unused = []
  for (i = 0; i < boundCmps.length; i++) {
    cmp = boundCmps[i]
    if (cmp.model === meta.model) {
      if (
        !cmp.fieldName ||
        cmp.fieldName === fieldName ||
        cmp.fieldName.split('.')[0] === fieldName // for displaying nested fields
      ) {
        cmp.trigger('model-change', meta.model, fieldName, vPrev)
      }
      cmp.renderAsync && cmp.renderAsync()
    } else unused.push(cmp)
  }

  if (meta.parent) {
    if (hasChanges(meta.model)) meta.parent.changes[meta.name] = true
    else delete meta.parent.changes[meta.name]
    reactUpstream(unused, meta.parent, meta.name + '.' + fieldName, vPrev)
  }
}

const hasChanges = model => Object.keys($meta(model).changes).length > 0

/**
 * Note, react() isn't called
 *
 * @param {*} model
 * @returns model
 */
const cancelChanges = model => {
  const meta = $meta(model)
  if (meta.new) meta.deleted = true

  // remove all the values
  Object.keys(model).forEach(k => delete model[k])

  // restore all the orig values
  Object.keys(meta.orig).forEach(k => (model[k] = meta.orig[k]))

  // initMeta(rec, meta.rowi, meta.reactFields)
  meta.prev = {}

  // rowReact(grid, rowi)
  // grid.trigger( 'ow-grid-change', model, tr, 'cancel')

  return model
}

// const set = (view, model, fieldName, value) => {
//   model[fieldName] = value
//   react(view, model, fieldName)
// }

const addRow = (model, item) => {
  const meta = $meta(model)
  const reci = model.length
  model[reci] = item
  const recMeta = initModel(item, reci.toString(), meta.schema.itemSchema, meta)
  recMeta.new = true
  recMeta.reci = reci
  return item
}

const isNewBlank = model => {
  if (!model) return false
  const meta = $meta(model)
  return meta.new && Object.keys(meta.changes).length === 0
}

const deletedFieldName = model => $meta(model).schema.deletedFieldName || 'Deleted'

const markDeleted = model => {
  const meta = $meta(model)

  const f = deletedFieldName(model)

  const value = f in model ? !model[f] : !meta.deleted

  if (!isNewBlank(model)) model[f] = value
  meta.deleted = value
}

const isDeleted = model => {
  if (!model) return false
  const meta = $meta(model)
  return meta.deleted || model[deletedFieldName(model)]
}

module.exports = {
  react,
  initModel,
  hasChanges,
  $meta,
  reactUpstream,
  addRow,
  cancelChanges,
  isNewBlank,
  modelBind,
  schemaFields,
  markDeleted,
  isDeleted,
  deletedFieldName
}
