const { qc } = require('./cmp/cmp')

/**
 * sets removes disabled state and also
 * sets state on the div.resource_set if it has one.
 *
 * @param {HTMLElement|HTMLElement[]} el usually input
 * @param {boolean} val disabled or not
 */
const odisable = (el, val = true) => {
  if (Array.isArray(el)) return el.forEach(x => odisable(x, val))
  val = val !== false ? true : false

  const qEl = qc(el)

  if (qEl.disable) return qEl.disable(val)

  val ? (el.disabled = val) : delete el.disabled

  qEl[val ? 'addClass' : 'removeClass']('ow-disabled')
    .attr({
      readonly: val ? 'readonly' : undefined,
      disabled: val ? 'true' : undefined
    })
    .props({ disabled: val })

  const wrapper = el.parentElement
  wrapper &&
    qc(wrapper).hasClass('ow-ctl-wrap') &&
    (val ? qc(wrapper).addClass('ow-disabled') : qc(wrapper).removeClass('ow-disabled'))

  const rs = el.closest('.resource_set')
  rs && (val ? qc(rs).addClass('ow-disabled') : qc(rs).removeClass('ow-disabled k-state-disabled'))
}

/**
 * sets removes disabled state and also
 * sets state on the div.resource_set if it has one.
 *
 * @param {Cmp} control to apply disabled state too usually input
 * @param {boolean} val disabled or not
 */
const disable = (me, v = true) => {
  v ? (me.disabled = true) : delete me.disabled
  me.attr({
    disabled: v ? 'true' : undefined,
    tabIndex: v ? '-1' : undefined
  })
  v ? me.addClass('ow-disabled') : me.removeClass('ow-disabled')
  v ? me.wrap?.().addClass('ow-disabled') : me.wrap?.().removeClass('ow-disabled')
  v ? me.rs?.().addClass('ow-disabled') : me.rs()?.removeClass('ow-disabled k-state-disabled')
}

module.exports = { odisable, disable }
