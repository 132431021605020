// This should not be used anymore except in existing ow6 views.
// We need to convert the existing ow6 to use the qc() event model which uses browser events
const { camel } = require('../css')

function fire(cmp, et, e, ...args) {
  const handlerName = camel('on-' + et)
  if (!cmp[handlerName]) return

  if (Array.isArray(cmp[handlerName])) {
    let j
    for (j = 0; j < cmp[handlerName].length; j++)
      if (cmp[handlerName][j].call(cmp, e, ...args) === false) return false
  } else return cmp[handlerName](e, ...args)
}

const on = (cmpObj, eventName, handler) => {
  const k = camel('on-' + eventName)
  if (k in cmpObj) {
    if (!Array.isArray(cmpObj[k])) cmpObj[k] = [cmpObj[k]]
  } else {
    cmpObj[k] = []
  }
  cmpObj[k].push(handler)
  return cmpObj
}

module.exports = { fire, on }
