// var chrMap = {
//   '&': '&amp;',
//   '<': '&lt;',
//   '>': '&gt;',
//   '"': '&quot;',
//   "'": '&#39;',
//   '/': '&#x2F;',
//   '`': '&#x60;',
//   '=': '&#x3D;'
// }

const chr = { '"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;' }

const htmlEncode = (() => {
  'use strict'
  return s => (!s && s !== 0 ? '' : s.toString().replace(/["&<>]/g, a => chr[a]))
})()

module.exports = { htmlEncode, escapeHtml: htmlEncode }
