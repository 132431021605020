const { qc } = require('../cmp/qc')
const { addCss } = require('./stylesheet')

const buildTabs = (tabs, changeTab) =>
  tabs.forEach((tab, i) => {
    const { active, content, name = '', caption } = tab

    tab.div = qc('div.fit.tab-content', [
      qc('div.tab_content', { style: 'background: #fff;' }, content).bindState(
        () => tab.content,
        function () {
          this.kids(tab.content)
        }
      )
    ])
      .props({
        tab,
        show() {
          this.css({ display: 'block' })
        },
        hide() {
          this.css({ display: 'none' })
        }
      })
      .addClass(active ? 'ow-state-active' : '')
      .attr({ role: 'tabpanel' })
      .css({ display: active ? 'block' : 'none' })

    tab.li = qc('li.tab-item.ow-state-default.' + name.concat('-tab'), [
      qc('span.ow-loading'),
      qc('span.ow-link', caption).attr({ unselectable: 'on' })
    ])
      .addClass(active ? 'tab-on-top ow-state-active' : '')
      .props({ tab })
      .attr({ role: 'tab', 'aria-controls': i })
      .on('click', () => changeTab(tab))
  })

const tabstrip6 = props => {
  props.tag = props.tag || 'div.tabstrip6-wrapper'

  let activeTab

  const { tabs } = props

  const me = qc(props)

  const changeTab = tab => {
    if (activeTab) {
      delete activeTab.active
      activeTab.li.removeClass('ow-state-active').removeClass('tab-on-top')
      activeTab.div.hide()
    }
    activeTab = tab
    if (tab) {
      tab.active = true
      tab.li.addClass('ow-state-active').addClass('tab-on-top')
      tab.div.show()
    }

    me.renderAsync().then(() => me.trigger('ow-change', tab))
  }

  activeTab = tabs[0]
  activeTab.active = true
  buildTabs(tabs, changeTab)

  me.kids([
    qc('div.fit.tabstrip6.ow-tabstrip.tabstrip6-top', [
      qc(
        'ul.ow-tabstrip-items',
        tabs.map(t => t.li)
      ),
      tabs.map(t => t.div)
    ]).css({ background: 'transparent', border: '0 none' })
  ])
  me.changeTab = changeTab

  return me
}

/*


.tabstrip6 .tab_content > div.fit {
  margin: 1.23em;
}

.tabstrip6.fit > .tab_content {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: initial;
  height: initial;
}

.tabstrip6 .tab_content.k-state-active {
  background-color: #ffffff;
  color: #515967;
}

.tabstrip6 .tab_content {
    overflow-y: hidden;
}
.tabstrip6 .tabstrip6-top.fit > .tab_content {
    top: 2.31em;
}

.tabstrip6 > .tab_content {
    margin: 0;
    padding: 1.5em;
    border: 1px solid #ceced2;
    border-radius: 0.31em;
}

.tabstrip6 > .tab_content {
    display: none;
    overflow: auto;
}

.tabstrip6 .tab_content {
  outline: 0;
}

.tabstrip6 {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}
.tabstrip6 .k-content.k-state-active { background-color: #ffffff; color: #515967; }

.tabstrip6-wrapper { width: 100%; }
.tabstrip6 .tabstrip6-top.fit > .k-content {
  top: 2.31em;
  margin: 0;
  padding: 1.5em;
  border: 1px solid #ceced2;
  border-radius: 0.31em;  
}

.tabstrip6 .ow-tabstrip-items {
  padding: 0px;
}
.tabstrip6 .ow-tabstrip-items .k-state-default {
	border-color: rgba(0,0,0,0);
	margin-right: 0.25em;
	background: #fff;
	border-top-left-radius: .31em;
	border-top-right-radius: .31em; 
}
.tabstrip6 .ow-tabstrip-items .k-state-active {
	background-color: #fff;
	background-image: none;
	font-weight: 600;
	border-color: #ceced2 !important;
	border-width: 1px 1px 0 1px !important;
	border-top-left-radius: .31em;
	border-top-right-radius: .31em;
}
.tabstrip6 .ow-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.tabstrip6 .ow-tabstrip-items .ow-link {
  display: inline-block;
  border-bottom-width: 0;
  padding: .5em .92em;
}
.tabstrip6 .ow-tabstrip-items .ow-loading { border-top: 0 none; }

.tabstrip6 .ow-tabstrip-items .k-item { width: auto; min-width: 7.7160em; border-width: 0; text-align: center; }
.tabstrip6 .ow-tabstrip-items .k-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top; 
}


 */

addCss(
  'tabstrip6-css',
  `
#scope .tabstrip6.fit > .tab_content {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: initial;
  height: initial;
}

#scope .tabstrip6 .tab_content {
  overflow-y: hidden;
}
#scope .tabstrip6 .tab_content {
  outline: 0;
}

#scope .tabstrip6.tabstrip6-wrapper { width: 100%; }
#scope .tabstrip6 {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}

#scope .tabstrip6 .tab-content.ow-state-active, 
#scope .tabstrip6 .tab-content { background-color: #fff; color: #515967; }

#scope .tabstrip6-top.fit > .tab-content {
  top: 2.31em;
  margin: 0;
  padding: 1.5em;
  border: 1px solid #ceced2;
  border-radius: 0.31em;  
}

#scope .tabstrip6 .ow-tabstrip-items {
  padding: 0px;
}
#scope .tabstrip6 .ow-tabstrip-items .ow-state-default {
  border-color: rgba(0,0,0,0);
  margin-right: 0.25em;
  background: #fff;
  border-top-left-radius: .31em;
  border-top-right-radius: .31em; 
}
#scope .tabstrip6 .ow-tabstrip-items .ow-state-active {
  background-color: #fff;
  background-image: none;
  font-weight: 600;
  border-color: #ceced2 !important;
  border-width: 1px 1px 0 1px !important;
  border-top-left-radius: .31em;
  border-top-right-radius: .31em;
}
#scope .tabstrip6 .ow-tabstrip-items .tab-on-top {
  z-index: 1;
}
#scope .tabstrip6 .ow-tabstrip-items .ow-link {
  cursor: pointer;
  display: inline-block;
  border-bottom-width: 0;
  padding: .5em .92em;
}
#scope .tabstrip6 li.tab-item:hover {
  background-color: #cdd1d7;
}
#scope .tabstrip6 .ow-tabstrip-items .ow-loading { border-top: 0 none; }

#scope .tabstrip6 .ow-tabstrip-items .tab-item { width: auto; min-width: 7.7160em; border-width: 0; text-align: center; }
#scope .tabstrip6 .ow-tabstrip-items .tab-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top; 
} 
`
)

module.exports = {
  tabstrip6
}
