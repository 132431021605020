const { qc } = require('../cmp/qc')
const { mergeProps } = require('../cmp/mergeProps')
const { killEvent } = require('../killEvent')
const { addCss } = require('./stylesheet')

/**
 * Creates a cmp component representing a single radio button.
 * @param {object} props - Properties for radio6 component.
 * @property {string} [props.label] - Label to add to the radio button.
 * @returns {object} - cmp radio button component.
 */
const radio6 = props => {
  let { label, labelRight = true } = props
  props.value = props.value || false

  props.tag = 'a.radio6'
  const input = qc(
    mergeProps(
      {
        applyState(prevState = {}) {
          this.attr(
            this.disabled ? { disabled: true, href: undefined } : { disabled: undefined, href: '#' }
          )
          this.disabled
            ? this.wrap().addClass('ow-disabled')
            : this.wrap().removeClass('ow-disabled')

          this.value ? this.addClass('on') : this.removeClass('on')

          return prevState
        },

        val(v) {
          if (typeof v !== 'undefined') {
            if (this.value !== v) {
              this.value = v
              this.value ? this.addClass('on') : this.removeClass('on')
              this.renderAsync() // render change to UI
            }
          }
          return this.value
        }
      },
      props
    )
  )
    .on('click', function () {
      if (input.disabled) return false
      input.val(!input.value)
      setTimeout(() => input.trigger('ow-change'), 10)
    })
    .on('keypress', function (e) {
      if (e.which === 32) {
        // space
        input.el.click()
        return killEvent(e)
      }
    })

  input.kids([labelRight ? label : ''])

  input.wrap = () => input

  return input
}

module.exports = { radio6 }

const sCss = `
  a.radio6 {
    line-height: 1.65em;
    display: inline-block;
    padding: 0.154em 0.309em;
  }
  a.radio6::before {
    font-family: 'FontAwesome';
    content: '\\f00c';
    color: transparent;
    border: 0.077em solid #c5c5c5;
    background: #fff;
    border-radius: 0.231em;
    padding: 0.231em;
    font-size: 0.9em;
  }
  a.radio6::before {
    border-radius: 1.24em;
    content: '\\f111';
    font-size: 0.77em;
  }
  a.radio6::before {
    margin-right: 0.62em;
  }
  a.radio6.on::before {
    color: #535b6a;
  }
  a.radio6:focus::before {
    border-color: #1a87cd;
    -webkit-box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
    box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
  }
  a.radio6.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
  .resource_set .radio6-wrap {
    padding-left: 0;
  }
  .resource_set > span > a.radio6 {
    line-height: 2.25em;
    padding: 0;
  }`

addCss('css-radio6', sCss)
