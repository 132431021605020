// grid-body.js
const { $find, $outerWidth } = require('../../no-jquery')
const { isNewBlank, $meta } = require('../data-model')
const { gridRow } = require('./grid-row')
const { qc } = require('../../cmp/qc')
const { _v } = require('../../_v')
const { $cmp } = require('../../cmp/$cmp')

const swapPage = (grid, thenFn) => {
  if (grid.state.pageChanging) return setTimeout(() => swapPage(grid, thenFn), 10)

  grid.state.pageChanging = true

  const { dataView } = grid

  let pageTop = grid.body.el.scrollTop
  let gridHeight = Math.max(grid.body.el.clientHeight, 300)

  grid.state.scrolling = grid.state.scrolling || {}
  const scrolling = grid.state.scrolling

  const rowHeight = grid.rowHeight || grid.defaultRowHeight

  grid.rowsPerPage = Math.floor(gridHeight / rowHeight) + 12
  grid.rowsPerPage = Math.max(grid.rowsPerPage, 20) // min 20 rows

  let firstRowIndex = pageTop === 0 ? 0 : Math.max(0, Math.trunc(pageTop / rowHeight) - 6)
  let lastRowIndex = Math.min(firstRowIndex + grid.rowsPerPage, dataView.length - 1)

  if (grid.virtualScoll === false) {
    firstRowIndex = 0
    lastRowIndex = dataView.length - 1
  }

  // if there are only 50 records, don't bother with virtual scrolling
  if (grid.dataView.length < 101) {
    grid.rowsPerPage = grid.dataView.length
    firstRowIndex = 0
    lastRowIndex = dataView.length - 1
  }

  if (scrolling.firstRowIndex === firstRowIndex && scrolling.lastRowIndex === lastRowIndex) {
    grid.state.pageChanging = false
    return thenFn && thenFn()
  }

  scrolling.firstRowIndex = firstRowIndex
  scrolling.lastRowIndex = lastRowIndex

  // log('SWAP-PAGE:', firstRowIndex + '-' + lastRowIndex, pageTop)

  const { tBody } = grid.body

  let activeRec = grid.state.current.reci !== null ? grid.dataSet[grid.state.current.reci] : null
  let activeRowFi = -1
  if (activeRec) {
    activeRowFi = grid.dataView.indexOf(activeRec)
  }

  if (activeRowFi === -1) activeRec = null

  let tr, i, fi, activeRow
  // forget all the Rows we aren't using
  const availableRows = []
  const kids = tBody.kids()
  for (i = 0; i < kids.length; i++) {
    tr = kids[i]
    if (tr.rec === activeRec) {
      activeRow = tr

      if (activeRowFi < firstRowIndex || activeRowFi > lastRowIndex) tr.addClass('hidden-active')
      else {
        tr.removeClass('hidden-active')
        availableRows[activeRowFi] = tr
      }
      tr.fi = activeRowFi
    } else {
      fi = grid.dataView.indexOf(tr.rec)
      if (fi >= firstRowIndex && fi < lastRowIndex) availableRows[fi] = tr
      tr.fi = fi
    }
  }

  const newChildren = []

  if (activeRow && activeRowFi < firstRowIndex) newChildren.push(activeRow)

  let cTr
  for (fi = firstRowIndex; fi <= lastRowIndex; fi++) {
    cTr = availableRows[fi] || gridRow(grid, dataView[fi], fi)
    delete availableRows[fi]
    cTr.fi = fi
    newChildren.push(cTr)
  }

  if (activeRow && activeRowFi > lastRowIndex) newChildren.push(activeRow)

  tBody.kids(newChildren)

  grid.state.pageChanging = false

  thenFn && thenFn()
}

const gridBody = grid => {
  const addRowOnFocusInput = qc('input.add-row-on-focus')
    .css({ height: '0', width: '0', border: 'none' })
    .on('focus', function () {
      if (!grid.tabOutNewRow) return
      var tr = grid.currentRow()
      if (tr)
        // this should be cancelled but check later in case
        setTimeout(() => {
          const model = $cmp(tr).rec
          if (isNewBlank(model)) grid.leaveRow(tr)
          else grid.addRow()
        }, 20)
    })

  const tBody = qc('tbody')
  const table = qc('table', tBody)
    .css({ tableLayout: 'fixed', position: 'absolute' })
    .bindState(
      () => _v(grid, 'state.scrolling.firstRowIndex') ?? 0,
      firstRowIndex => {
        if (grid.virtualScroll !== false) {
          const rowHeight = grid.rowHeight || grid.defaultRowHeight
          const rowsTop = firstRowIndex * rowHeight
          table.css({ top: rowsTop + 'px' })
        }
      }
    )
    .bindState(() => {
      if (grid.state.current.reci === null || grid.state.current.reci === undefined)
        grid.state.current.reci = grid.dataView[0] ? $meta(grid.dataView[0]).reci : null
    })
    .bindState(
      () => grid.totalColWidth,
      function (w) {
        this.css({ width: w + 'px' })
      }
    )
    .bindState(
      () => _v(grid.body, 'el.scrollTop'),
      () => swapPage(grid)
    )
    .bindState(
      () => grid.dataView.length, // for adding new rows and then discarding if new blank
      () => swapPage(grid)
    )
    .bindState(
      () => grid.dataView,
      () => {
        grid.state.scrolling = {}
        swapPage(grid)
      }
    )

  const contentWrap = qc('div.ow-grid-content-wrap', [table, addRowOnFocusInput])
    .bindState(
      () => grid.dataView?.length ?? 0,
      numRows => {
        if (grid.virtualScroll !== false)
          contentWrap.css({
            height: Math.trunc(numRows * (grid.rowHeight || grid.defaultRowHeight)) + 'px'
          })
      }
    )
    .bindState(
      () => grid.totalColWidth,
      function (w) {
        this.css({ width: w + 'px' })
      }
    )

  grid.body = qc('div.ow-grid-content', contentWrap)
    .props({
      grid,
      addRowOnFocusInput,
      tBody,
      focusFirstRow() {
        let firstRow = this.tBody.kids()[0]

        if (firstRow) grid.state.current.reci = $meta(firstRow.rec).reci

        const cell = firstRow && firstRow.kids()[0].find(c => c.hasClass('non-editable-cell'))

        if (cell) {
          // this.grid.current(cell.el)
          // this.tBody.el.parentElement.focus() // focus the table
          // this.grid.resolveFocus()
        } else {
          grid.state.current.reci = null
          grid.state.current.coli = -1
        }

        grid.resolveFocus()
      }
    })
    .on('click', e => {
      if (!e.target.closest('table')) {
        var lastRec = grid.dataView[grid.dataView.length - 1]
        var focusLast = (lastRec && isNewBlank(lastRec)) || !grid.newRowAllowed()

        if (focusLast) {
          var tr = grid.getTr(lastRec)
          return tr && grid.focusCell($find(tr, 'td')[0]) // this will be cancelled.
        }
        return grid.addRow()
      }
    })
    .on('scroll', () => {
      const { el } = grid.body

      grid.prevScrollLeft = grid.body.prevScrollLeft || 0

      Array.from($find(el, '.ow-dropdown-open')).forEach(
        x => $cmp(x).dropdown && $cmp(x).dropdown.close()
      )

      if (grid.body.prevScrollLeft !== el.scrollLeft) {
        grid.body.prevScrollLeft = el.scrollLeft

        grid.setHorizontalScroll(el.scrollLeft)

        if (grid.freezeButtonColumn) {
          let scrollBarWidth = 12

          let left =
            el.scrollLeft - ($outerWidth($find(el, 'table')[0]) - $outerWidth(el)) - scrollBarWidth

          left = left + 'px'
          // frozen-right columns
          if (!grid.el.id) throw 'NO ID for GRID'
          let style = {
            ['#' + grid.el.id + ' .frozen-right']: {
              backgroundColor: '#fff',
              left,
              position: 'relative'
            }
          }
          // $find(grid.el, '#g-' + grid.id)[0].remove()
          StyleSheet.addCss('g-' + grid.el.id, style)
        }
      }

      grid.renderAsync()
    })

  return grid.body
}

module.exports = { gridBody }
