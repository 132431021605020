const { $map } = require('../element-map')

const $cmp = el => {
  let obj = $map(el)
  if (!obj.cmp) obj.cmp = require('./qc').norm(el)
  return obj.cmp
}

if (typeof window !== 'undefined') window.$cmp = window.$cmp || $cmp

module.exports.$cmp = $cmp
