// header-bar.js

const { qc } = require('../cmp/qc')
const { generateButtonType } = require('./button7')

/**
 *
 * @param {Array} opts
 * @param {string} dsName
 * @param {object} view
 * @returns
 */
const headerBar7 = (opts, dsName, view) => {
  if (!view && window.dev) throw 'headerBar7 requires view'

  if (Array.isArray(opts) && opts.length === 0) opts = null
  if (!opts) opts = ['new', 'edit', 'delete', 'divider', 'refresh']

  const buttons = generateButtonType(opts, 'header', dsName, view)

  if (dsName)
    buttons.forEach(btn => btn.opts && (btn.opts.targetRef = btn.opts.targetRef ?? dsName))

  const r = qc('div.ow-buttonstrip', buttons)

  return r
}

module.exports.headerBar7 = headerBar7
