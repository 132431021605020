const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { dataBind } = require('./databind')
const { applyAttrsAndClasses } = require('./qc-applyattrs')

const checklistbox5 = {
  init() {
    var el = this
    const me = qc(el)
    el.opts.textField = el.opts.textField || 'Text'
    el.opts.valueField = el.opts.valueField || 'Value'

    el.val = checklistbox5.val

    me.addClass('ow-checklist')
      .on('command-tickall', () => (el.list || []).filter(d => d._chk.val(true)))
      .on('command-untickall', () => (el.list || []).filter(d => d._chk.val(false)))
      .on('click', ({ target }) => qc(target).hasClass('ow-check') && me.trigger('ow-change'))

    el.list = null
    el.dataList = []
    el.url = function (url) {
      el.opts.url = url
      qc(el).loadList()
    }

    qc(el).loadList()
  },

  async loadList() {
    var el = this
    return (el.opts.url ? window.$ajax(el.opts.url) : Promise.resolve(el.opts.list))
      .then(data => {
        el.list = data.Data || data.data || data
        qc(el).buildList()
        return el.list
      })
      .catch(err => ow.popError(err))
  },

  val(v) {
    var el = this

    const readChecks = () => (el.list || []).filter(d => d._chk.val())

    if (v !== undefined) {
      el.dataList = v || []
      qc(el).updateChecks()
    }

    return el.list === null
      ? el.dataList
      : readChecks().map(x => {
          const { ...r } = x
          delete r._chk
          return r
        })
  },

  updateChecks() {
    var el = this
    ;(el.list || []).forEach(item => {
      const id = _v(item, el.opts.valueField)
      const v = (el.dataList || []).find(d => id === _v(d, el.opts.valueField))
      item._chk.val((v && (v.checked || v.checked === undefined)) || false)
    })
  },

  buildList() {
    var el = this
    const me = qc(el)
    const buildCheckBox = item =>
      (item._chk = qc(
        'a.ow-check.ow-ctl-no-wrap' +
          (el.opts.indentField ? '.indent-' + _v(item, el.opts.indentField) : ''),
        _v(item, el.opts.textField) || ''
      )
        .attr({ href: '#' })
        .on('init', (e, el) => ow5.ctlType(el)))

    me.kids(el.list.map(buildCheckBox))
    me.updateChecks()
  }
}

module.exports.checklistbox5 = checklistbox5

const qChecklist5 = opts => {
  const me = qc('div.ow-checklist')
    .props({ opts })
    .on('init', (e, el) => {
      el.opts = opts
      ow5.ctlType(el)
      me.val(me.val())
      return el
    })

  dataBind(me)
  applyAttrsAndClasses(me)

  me.wrap = () => me

  return me
}

module.exports.qChecklist5 = qChecklist5
