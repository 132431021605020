const { qc } = require('../cmp/qc')

const trailString = id =>
  typeof id !== 'string'
    ? ''
    : // to cater routes.js recognize, else might getting 403 status
    (id || '').indexOf('.') > -1
    ? '/'
    : ''

/**
 * DataController for ow4 views
 *
 * @param {HTMLElement} o - the outer scope, usually qTop.el
 * @param {object} opts
 * @param {boolean} opts.trackChanges if false it won't track changes using readData(), use in combination with overriding the hasChanged method.  Good for owGrid bigdata
 * @param {boolean} opts.closeAfterSave
 * @param {object} opts.$top - original jquery kwindow instance
 * @returns {object}
 */
const dc4 = function (o, opts) {
  o.dcOpts = opts //

  opts.closeAfterSave = opts.closeAfterSave === false ? false : true
  opts.trackChanges = opts.trackChanges === false ? false : true

  if (!opts.view) {
    if (ow.dev) {
      console.warn('Please pass view object into dc4.')
      debugger
    }
  } else {
    opts.$top = opts.$top ?? opts.view.$top
  }

  const view = opts.view ?? opts.$top.view()
  const { viewdata, qTop } = view

  if (o === view.$top) o = qTop.el

  if (opts.hasFilters) {
    ow.controls.filterController(o, opts)
    o.getMyFilterControls().forEach(fc =>
      qc(fc).attr({ 'data-field-for': '', 'data-target-ref': opts.dsName })
    )
  }

  if (o === qTop.el) {
    viewdata.requestClose =
      viewdata.requestClose ||
      function () {
        if (o.hasChanged()) {
          displayName = viewdata.name.split('-')[0]
          ow.confirm(
            displayName,
            __('Are you sure you want to discard changes and close form?'),
            r => r && qTop.closeForm(true)
          )
          return false
        }
        return true
      }
  }
  view.$top.dc = view.$top.dc || o
  var dsName = opts.dsName
  var displayName = opts.displayName
  var idField = opts.idField

  opts.idField = Array.isArray(idField) ? opts.idField : [opts.idField]
  idField = opts.idField[0]

  o.myId = function (rec, alwaysReturnObj) {
    if (opts.idField.length === 1) {
      if (alwaysReturnObj) {
        var res = {}
        res[opts.idField] = rec?.[idField]
        return res
      }
      return rec?.[idField]
    }
    var ids = {}
    opts.idField.forEach(x => (ids[x] = ow.val(rec, x)))
    return ids
  }

  o.idString = function (rec) {
    const id = o.myId(rec)
    return typeof id === 'object' ? JSON.stringify(id) : id
  }

  // rec is the last rec used to call populate
  let rec = {}
  // access for overriding
  o.rec = function (r) {
    if (typeof r !== 'undefined') rec = r
    return rec // rec
  }

  o.changeKeyIgnores = [
    function (fullName) {
      return fullName.indexOf('._display') + 1
    },
    function (fullName) {
      return fullName.indexOf('.order') + 1
    }
  ]

  // please override this for generating string for hasChanges comparator
  // by default it just JSON.stringifys it
  // returns string
  o.changeKey = function (r) {
    var key = ow.arrayify(r, { ignore: o.changeKeyIgnores })
    return JSON.stringify(key)
  }

  var orig = null
  var origKey = ''

  // flag to prevent ow-data-changed events firing while populating
  var populating = false
  // access for overriding
  o.populating = function (v) {
    if (v !== undefined) populating = v
    return populating // rec
  }

  const showBusyIcon = () => view.progress?.(true)
  o.showBusyIcon = showBusyIcon
  const hideBusyIcon = () => view.progress?.(false)
  o.hideBusyIcon = hideBusyIcon

  // Give me values populated into controls
  o.orec = function () {
    return orig
  }

  o.newRec =
    opts.newRec ??
    function () {
      // override this for default values
      return {}
    }

  function newRec() {
    var r = o.newRec?.() ?? {}
    r._isNew = true
    return r
  }

  o.hasChanged = function () {
    if (!opts.trackChanges) return false
    var curr = o.changeKey(o.readData(rec))
    console.log('hasChanged comparing strings of length: ' + curr.length, 'ticktock')
    return curr !== origKey
  }

  if (opts.prePopulate) o.prePopulate = opts.prePopulate

  o.populate = function (r) {
    rec = o.prePopulate?.(r) ?? r
    populating = true
    showBusyIcon()
    o.updateControls()
    if (opts.trackChanges) {
      orig = JSON.parse(JSON.stringify(o.readData(rec)))
      origKey = o.changeKey(o.readData(rec, true))
    } else orig = rec
    qTop.trigger('ow-populate', o, rec)
    hideBusyIcon()
    populating = false
    saveCancelState()
  }

  //update origKey & populate basicEd
  o.partialPopulate = function ($topTarget) {
    console.log('partialPopulate')
    populating = true
    showBusyIcon()

    var _orig = JSON.parse(JSON.stringify(orig))
    ow.controls.populateBasicEds($topTarget, dsName, rec)
    if (opts.trackChanges) {
      orig = JSON.parse(JSON.stringify(o.readData(_orig, true, $topTarget)))
      origKey = o.changeKey(o.readData(_orig, true, $topTarget))
    } else orig = _orig

    //$top.trigger('ow-populate', [o, rec]);
    hideBusyIcon()
    populating = false
  }

  // Like populate doesn't set the orig value...
  // this is for setting other values of the record while it's editing.
  // external code should follow this pattern:
  //   var rec = dc.readData();
  //   rec.blah = Nah;
  //   dc.update(rec);
  o.update = function (r) {
    rec = r
    populating = true
    o.updateControls()
    qTop.trigger('ow-populate', o, r)
    populating = false
    saveCancelState()
  }
  o.updateControls = function () {
    ow.controls.populateBasicEds(view.$top, dsName, rec)
  }
  o.readData = function (baseRec, changeTracking, $target) {
    if (changeTracking && !opts.trackChanges) return baseRec
    baseRec = baseRec || rec || {} // generally baseRec will be rec
    ow.controls.readBasicEds($target || view.$top, dsName, baseRec)
    return baseRec
  }
  o.getEds = function () {
    return ow.controls.getBasicEds(view.$top, dsName)
  }
  o.edChanged = function () {
    if (!populating) saveCancelState()
  }
  o.focusIDField = function () {
    qTop.find("[data-field='" + idField + '"], #txt' + idField)[0]?.focus()
  }

  o.fieldReact = function (f, newValue, caller) {
    // send out to all the controls with that field
    ow.controls
      .getBasicEds(view.$top, dsName)
      .filter(function (i, ed) {
        return ed !== caller && ed.opts && ed.opts.fieldName === f
      })
      .each(function (i, ed) {
        if (ed.val() !== newValue) ed.val(newValue)
      })

    var changes = {}
    changes[f] = newValue
    ow.controls.getBasicEds(view.$top, dsName).trigger('ow-dc-change', [changes])
  }

  if (opts.reactive) {
    // generic dc change detection
    var dcReact = function (e) {
      console.log('dcReact ' + dsName + ' ' + this + ' ' + e.target)

      var f = e.target.opts?.fieldName ?? qc(e.target).attr('data-field')
      var newValue = e.target.val()

      if (o.populating()) return
      o.fieldReact(f, newValue, e.target)
    }
    view.$top
      .on('change', '[data-field-for=' + dsName + ']', dcReact)
      .on('click', 'a.basic-ed[data-field-for=' + dsName + ']', dcReact)
  }

  // change monitor - use ticktock to reduce load during lots of changes and population etc
  var lastRun = new Date().valueOf()
  function handler() {
    console.log(
      'TICKTOCK handler Entered, ticks since run ' + (new Date().valueOf() - lastRun),
      'ticktock'
    )

    if (!populating && new Date().valueOf() - lastRun > 250) {
      lastRun = new Date().valueOf() + 60000 // 1 min in future, prevents it running twice when the changes evaluation process takes a long time.
      o.edChanged()
      lastRun = new Date().valueOf()
      return
    }

    setTimeout(() => handler(), 25)
  }

  view.$top
    .on('change', '[data-field-for="' + dsName + '"],[data-target-ref="' + dsName + '"]', handler)
    .on('keyup', '.basic-ed, .k-input', handler)
    .on('click', '[data-field-for="' + dsName + '"],[data-target-ref="' + dsName + '"]', handler)
    .on(
      'ow-grid-change',
      '[data-field-for="' + dsName + '"],[data-target-ref="' + dsName + '"]',
      handler
    )
    .on(
      'ow-ed-change',
      '[data-field-for="' + dsName + '"],[data-target-ref="' + dsName + '"]',
      handler
    )
    .on('click', 'button[data-target-ref]', handler)

  o.validateData = function (messageArray, onInvalid) {
    return ow.controls.validateBasicEds(view.$top, dsName, messageArray, onInvalid)
  }
  // load the main record - request from server and then populate the controls
  o.load = function (id) {
    if (typeof id === 'object') id = JSON.stringify(id)

    var data = opts.baseData ?? {}

    if (opts.hasFilters) {
      data.filter = {}
      o.readFilterControls(data.filter)
    }

    var trail = trailString(id)
    showBusyIcon()
    $.ajax({
      type: 'GET',
      url: opts.baseURL + (id !== undefined ? '/' + encodeURIComponent(id) : '') + trail,
      contentType: 'application/json',
      dataType: 'json',
      data,
      success(response) {
        o.populate(response)
      },
      error(err) {
        var obj = {}
        try {
          obj = JSON.parse(err.responseText)
        } finally {
          var errorMessage = (obj ? obj.errMsg : err.responseText) || err.responseText
          ow.alert('load returned error ' + errorMessage)
        }
      },
      complete: hideBusyIcon
    })
  }

  o.copy = function (id) {
    showBusyIcon()
    if (typeof id === 'object') id = JSON.stringify(id)

    $.ajax({
      type: 'GET',
      url: opts.baseURL + '/' + id,
      contentType: 'application/json',
      dataType: 'json',
      data: opts.baseData ?? {},
      success(response) {
        var r = newRec()
        Object.assign(response, o.myId(r, true))
        response._isNew = true
        o.populate(response)
      },
      error(err) {
        var obj
        try {
          obj = JSON.parse(err.responseText)
        } catch (err) {}
        var errorMessage = (obj ? obj.errMsg : err.responseText) || err.responseText || err
        ow.alert('load returned error ' + errorMessage)
      },
      complete: hideBusyIcon
    })
  }

  o.delete = function () {
    ow.confirm(
      displayName,
      __('Are you sure you want to delete this record?'),
      r => r && innerDelete()
    )
    var innerDelete = function () {
      showBusyIcon()
      $.ajax({
        type: 'DELETE',
        url:
          opts.baseURL +
          '/' +
          o.idString(o.orec()) +
          (opts.baseData ? '?' + common.$param(opts.baseData ?? {}) : ''),
        data: {},
        success(response) {
          ow.popDeleteOK(response)
          if (opts.closeAfterDelete) {
            viewdata.result = response
            return qTop.closeForm(true)
          } else {
            var r = newRec()
            r.Name = ''
            o.populate(r)
          }
        },
        error(err) {
          ow.popDeleteError(err)
        },
        complete: hideBusyIcon
      })
    }
  }

  o.preSave = opts.preSave ?? (d => d)

  o.save = function () {
    var rec = o.orec()

    if (!o.validateData()) {
      console.log('validation failed')
      return
    }

    var result = o.readData(o.orec())
    result = this.preSave?.(result) ?? result

    // if this is a nested edit
    if (viewdata.record) {
      viewdata.result = result
      return qTop.closeForm(true)
    }

    var isNew = rec._isNew || !rec[idField]
    rec._isNew = isNew

    // check for ID match
    if (isNew && result[idField]) {
      const id = o.idString(result)
      showBusyIcon()
      return $ajax(opts.baseURL + '/' + id + trailString(id))
        .then(response => {
          if (o.idString(response) === id) {
            view.$top
              .find("[data-field='" + idField + "'], #txt" + idField)
              .closest('.resource_set')
              .addClass('k-input-errbg')
            o.focusIDField()
            return ow.popInvalid(__('ID already in use.'))
          }
          innerSave()
        })
        .catch(() => innerSave())
        .finally(() => hideBusyIcon())
    }

    innerSave()

    function innerSave() {
      var trail = trailString(o.idString(result))
      showBusyIcon()
      return $.ajax({
        type: isNew ? 'POST' : 'PUT',
        url:
          opts.baseURL +
          (!isNew ? '/' + encodeURIComponent(o.idString(result)) : '') +
          trail +
          (opts.baseData ? '?' + common.$param(opts.baseData ?? {}) : ''),
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify(result),
        success(response) {
          ow.popSaveOK(response)
          qTop.trigger('ow-data-saved', response)
          if (opts.closeAfterSave) {
            viewdata.result = result
            return qTop.closeForm(true)
          }
        },
        error(err) {
          ow.popSaveError(err)
        },
        complete: hideBusyIcon
      })
    }
  }

  o.cancel = function () {
    var rec = o.orec()
    var result = o.populate(rec)
    console.log(result)
  }

  o.new = function () {
    if (o.hasChanged()) {
      ow.confirm(
        displayName,
        __('Are you sure you want to discard changes and reset form?'),
        r => r && o.populate(newRec())
      )
      return false
    }
    o.populate(newRec())
  }

  o.callajax = function (callName, payload, onSuccess, onError) {
    showBusyIcon()
    $.ajax({
      type: 'POST',
      url:
        opts.baseURL +
        '/method/' +
        callName +
        (opts.baseData ? '?' + common.$param(opts.baseData ?? {}) : ''),
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify(payload),
      success(response) {
        onSuccess?.(response, payload)
      },
      error(err) {
        if (onError) return onError(err)
        var obj = {}
        try {
          obj = JSON.parse(err.responseText)
        } finally {
          var errorMessage = (obj ? obj.errMsg : err.responseText) || err.errMsg || err.responseText
          ow.popError(callName + ' call returned an error ' + errorMessage)
        }
      },
      complete: hideBusyIcon
    })
  }

  // Applies standard view form loading behviour based on viewdata.
  o.loadInitial = function (viewdata) {
    if (viewdata.mode === 'new') {
      o.populate(newRec())
      return
    } else if (viewdata.mode === 'copy') {
      var recToCopy = viewdata.data ? viewdata.data : viewdata.record
      if (!recToCopy) {
        o.copy(viewdata.id)
      } else {
        recToCopy = JSON.parse(JSON.stringify(recToCopy))
        recToCopy[idField] = null
        // what about the other ids?  should be ok for this case
        recToCopy._isNew = true
        o.populate(recToCopy)
      }
    } else {
      if (viewdata.record) {
        o.populate(viewdata.record)
      } else if (viewdata.id) {
        o.load(viewdata.id)
      } else {
        o.populate(newRec())
        viewdata.mode = 'new'
      }
      viewdata.mode = viewdata.mode || 'edit'
    }

    if ((viewdata.mode || 'edit') === 'edit') {
      // this isn't really the right place.  There should be a class on these that says disable-on-edit
      qTop.find('[data-field]').forEach(ctl => {
        if (ctl.opts?.fieldName === idField) ctl.odisable()
      })
      qTop.find('.disable-on-edit').forEach(o => {
        if (o.odisable) o.odisable()
      })
    }
  }

  o.saveCancelState = saveCancelState
  return o

  // the same as for grids - future, get grids using dataController too
  function saveCancelState() {
    var hasChanged = o.hasChanged()
    var broadcast = hasChanged !== o._dirty
    o._dirty = hasChanged

    var state = {
      dataController: o,
      dsName: dsName,
      // editable: opts.editable,
      editing: o._dirty
    }
    console.log('State.editing:' + state.editing, 'dc')
    // Look for save and cancel buttons bound to this grid
    function broadcastStateChange(el) {
      state.called = el
      qc(el).trigger('ow-datastatechange', state)
    }
    if (broadcast) {
      qTop
        .find('[data-field-for="' + dsName + '"],[data-target-ref="' + dsName + '"]')
        .forEach(el => broadcastStateChange(el))
      if (o === qTop.el) broadcastStateChange(qTop.el)
    }
  }
}

module.exports = { dc4 }
