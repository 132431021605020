/**
 * Convert a string from camel case to kebab case (e.g. borderTop/BorderTop to border-top).
 *
 * @param {string} s
 */
const unCamel = s => {
  const transText = s.slice(1).replace(/[A-Z]/g, '-$&')
  return s[0].concat(transText).toLowerCase()
}

/**
 * Convert a string from kebab case to lower camel case (e.g. border-top to borderTop).
 *
 * @param {string} s
 */
const camel = s => {
  return s
    .split('-')
    .map((x, index) => {
      if (index === 0 || !x[0]) return x
      return x[0].toUpperCase() + x.substr(1)
    })
    .join('')
}

/**
 * Convert all key/value pairs in an object into a string of property/value pairs, in CSS format.
 *
 * @param {object} props
 */
const propsToStyle = props =>
  Object.keys(props)
    .map(k => unCamel(k) + ': ' + props[k] + ';')
    .join(' ')

/**
 * Accepts an object--each key is a CSS selector and each value is an object with attributes/values as key/value pairs. Returns a string of selectors + attributes/values, in CSS format.
 *
 * @param {object} css - object
 */
const toStyleSheet = css =>
  Object.keys(css)
    .map(q => q + ' { ' + propsToStyle(css[q]) + ' }')
    .join('\n')

/**
 * Converts a string contining CSS property/value pairs to an object.
 * @param {string} s - String containing containing CSS property/value pairs.
 */
const cssStringToObject = s =>
  (s || '')
    .split(';')
    .filter(x => x)
    .reduce((r, x) => {
      const [k, v] = x.split(':')
      r[camel(k).trim()] = (v || '').trim()
      return r
    }, {})

/**
 * Accepts an object as a parameter, removes any entries with undefined values, and returns the resulting object. Accepts an object as an optional second parameter, onto which the first object can be concatenated.
 * @param {object} obj - Desc
 * @param {object} [onto] - Desc
 */
const copyProps = (obj, onto = {}) => {
  let s
  return Object.entries(obj).reduce((r, [k, v]) => {
    s = camel(k)
    if (v === undefined) delete r[s]
    else r[s] = v
    return r
  }, onto)
}

/**
 * Add styles to an object, which may or may not possess existing styles.
 * @param {string | object} currentStyle - Style (CSS property/value pairs) already present on object.
 * @param {string | object} css - Style (CSS property/value pairs) to be added to object.
 */
const setStyle = (currentStyle, css) => {
  let result =
    typeof currentStyle === 'string' ? cssStringToObject(currentStyle) : copyProps(currentStyle)
  copyProps(typeof css === 'string' ? cssStringToObject(css) : css, result)
  return result
}

// CSS javascript routines
module.exports = {
  propsToStyle,
  toStyleSheet,
  camel,
  unCamel,
  cssStringToObject,
  setStyle
}
