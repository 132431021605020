const isObject = x => typeof x === 'object'
const isFunction = x => typeof x === 'function'
const isDate = x => isObject(x) && x !== null && 'getFullYear' in x
const isString = v => typeof v === 'string'
const isNumber = v => typeof v === 'number'

const isValidDate = d => d instanceof Date && !isNaN(d)
const isInvalidDate = d => d instanceof Date && isNaN(d)

module.exports = { isObject, isFunction, isDate, isString, isNumber, isValidDate, isInvalidDate }
