// set-culture.js
const { isDate } = require('../../lib/js-types')
const { _v } = require('./_v')
const { numberFormats, setNumberFormatOverrides } = require('./number-formats')

// we download the lang only and the locale - sometimes there's no locale for a country with that lang.  eg. en-GT
// then override some of the display settings with lang
// and set the ow.dates settings
// It's usually going to be english
const setCalendarTranslation = (currentCulture, langCulture) => {
  if (!langCulture) langCulture = currentCulture
  const calendar = langCulture.calendars.standard
  currentCulture.calendar.months = calendar.months
  currentCulture.calendar.days = calendar.days
  currentCulture.calendar.AM = calendar.AM
  currentCulture.calendar.PM = calendar.PM

  let { names, namesShort } = calendar.days

  const { dates } = common

  dates.daysOfWeekJs = names
  const [Sunday, ...fromMonday] = namesShort
  dates.daysOfWeek = [...fromMonday, Sunday]

  dates.am = calendar.AM[0]
  dates.pm = calendar.PM[0]

  dates.shortDaysOfWeekJs = namesShort
  const [Sun, ...fromMon] = namesShort
  dates.shortDaysOfWeek = [...fromMon, Sun]

  dates.months = calendar.months.names
  dates.shortMonths = calendar.months.namesAbbr

  //   days: {
  //     names: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  //     namesAbbr: ['dom.', 'lun.', 'mar.', 'mié.', 'jue.', 'vie.', 'sáb.'],
  //     namesShort: ['DO', 'LU', 'MA', 'MI', 'JU', 'VI', 'SA']
  //   },
  //   months: {
  //     names: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ],
  //     namesAbbr: ['ene.','feb.','mar.','abr.','may.','jun.','jul.','ago.','sep.','oct.','nov.','dic.'    ]
  //   },
  //   AM: ['a.m.', 'a.m.', 'A.M.'],
  //   PM: ['p.m.', 'p.m.', 'P.M.']
  // }
}

// This is called from homepage (R1 and R2) after all the user culture settings are loaded
const setCulture = (baseCulture, langCulture, OrbisSettings = {}, kendo) => {
  const { DateFormats = {}, Culture = {} } = OrbisSettings

  const { dates } = common ?? {}

  if (kendo) dates.firstDay = kendo.culture?.().calendars.standard.firstDay ?? 1

  if (Culture.calendar) dates.calendar = Culture.calendar

  if (kendo && Culture.calendar === 'buddhist') {
    const _toString = kendo.toString
    kendo.toString = function (...args) {
      let [value, format] = args
      let result = _toString.call(kendo, ...args)
      // if it is a date format but not a Date time
      if (value && format.indexOf('yy') + 1) {
        //const origValue = value
        value = !isDate(value) ? dates.parseDate(value, format) : value
        return dates.formatDate(value, format)
      }
      return result
    }
    kendo._parseDate = kendo.parseDate
    kendo.parseDate = function (...args) {
      let [s, format] = args
      if (isDate(s)) return s
      return dates.parseDate(s, format, dates.calendar) || null
    }
  }

  setNumberFormatOverrides(baseCulture.name, OrbisSettings)

  // this is a weird general culture override
  Object.entries(Culture).forEach(([key, value]) => {
    if (key === 'calendar') return
    console.log('Trying to apply Culture setting', key)

    if (key === 'numberFormat.decimalpoint') return (baseCulture.numberFormat['.'] = value)
    if (key === 'numberFormat.currency.decimalpoint')
      return (baseCulture.numberFormat.currency['.'] = value)

    let old = _v(baseCulture, key)
    if (old === undefined) return
    // if it's a property of the culture and is same type, set
    if (typeof old === typeof value) return _v(baseCulture, key, value)

    // if it's a property of the culture maybe JSON parse
    try {
      if (typeof old === 'object' && typeof value === 'string')
        _v(baseCulture, key, JSON.parse(value))
    } catch (err) {
      console.error('Error parsing culture setting', key, err)
    }
  })

  if (langCulture) setCalendarTranslation(baseCulture, langCulture)
  dates.datePatterns = baseCulture.calendar.patterns

  dates.DateFormatSetting = DateFormats.DateFormatSetting || dates.DateFormatSetting
  dates.DateTimeFormatSetting = DateFormats.DateTimeFormatSetting || dates.DateTimeFormatSetting
  dates.TimeFormatSetting = DateFormats.TimeFormatSetting || dates.TimeFormatSetting

  dates.SchedulerTimeFormatSetting =
    DateFormats.SchedulerTimeFormatSetting || dates.SchedulerTimeFormatSetting
  dates.SchedulerDateFormatSetting =
    DateFormats.SchedulerDateFormatSetting || dates.SchedulerDateFormatSetting

  setKendoUITranslations(kendo)
}

const setKendoUITranslations = kendo => {
  //Grid
  let target = kendo.ui.Grid?.prototype.options.messages.editable
  setKendoUITranslationKey('GRID:EDITABLE:', target)

  target = kendo.ui.Grid?.prototype.options.messages.commands
  setKendoUITranslationKey('GRID:COMMANDS:', target)

  target = kendo.ui.Grid?.prototype.options.messages.noRecords
  setKendoUITranslationKey('GRID:NORECORDS', target) // string no : at end

  // Pager
  target = kendo.ui.Pager?.prototype.options.messages
  setKendoUITranslationKey('PAGER:', target)

  // Scheduler
  target = kendo.ui.Scheduler?.prototype.options.messages
  setKendoUITranslationKey('SCHEDULER:', target)

  target = kendo.ui.Scheduler?.prototype.options.messages.editable
  setKendoUITranslationKey('SCHEDULER:EDITABLE:', target)

  target = kendo.ui.Scheduler?.prototype.options.messages.views
  setKendoUITranslationKey('SCHEDULER:VIEWS:', target)

  target = kendo.ui.Scheduler?.prototype.options.messages.editor
  setKendoUITranslationKey('SCHEDULER:EDITOR:', target)

  // Upload
  target = kendo.ui.Upload?.prototype.options.localization
  setKendoUITranslationKey('UPLOAD:', target)

  // Treelist
  target = kendo.ui.TreeList?.prototype.options.messages
  setKendoUITranslationKey('TREELIST:', target)

  target = kendo.ui.TreeList?.prototype.options.messages.commands
  setKendoUITranslationKey('TREELIST:COMMANDS:', target)

  // Spreadsheet FilterMenu
  target = kendo.spreadsheet?.messages.filterMenu?.operators?.string
  setKendoUITranslationKey('SPREADSHEET:OPERATORS:STRING:', target)

  target = kendo.spreadsheet?.messages.filterMenu?.operators?.date
  setKendoUITranslationKey('SPREADSHEET:OPERATORS:DATE:', target)

  target = kendo.spreadsheet?.messages.filterMenu?.operators?.number
  setKendoUITranslationKey('FILTERCELL:NUMBER:', target)

  // FilterCell
  target = kendo.ui.FilterCell?.prototype.options.operators.string
  setKendoUITranslationKey('FILTERCELL:STRING:', target)

  target = kendo.ui.FilterCell?.prototype.options.operators.number
  setKendoUITranslationKey('FILTERCELL:NUMBER:', target)

  target = kendo.ui.FilterCell?.prototype.options.operators.date
  setKendoUITranslationKey('FILTERCELL:NUMBER:', target) // use same as NUMBER

  target = kendo.ui.FilterCell?.prototype.options.operators.enums
  setKendoUITranslationKey('FILTERCELL:NUMBER:', target)
}

const setKendoUITranslationKey = (keygroup, target) => {
  if (!target) return
  if (keygroup.slice(-1) === ':') {
    // an object key
    Object.keys(target).forEach(key => {
      const txKey = keygroup + key
      const tx = __(txKey)
      // if a translation doesn't exist it will return the same value
      if (tx !== txKey) target[key] = tx
    })
    return
  }
  const tx = __(keygroup)
  if (tx !== keygroup) target = tx
  return
}

module.exports = { setCulture }
