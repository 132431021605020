// combo-matches.js

const { _v } = require('../_v')

/**
 * Search through an array and find all elements that "match" a given value.
 * The "match" does not have to be an exact match--it is fuzzy in some capacity.
 *
 * used by combo in list filtering
 *
 * @param {array} list - Array in which to search.
 * @param {string | number} sub - Value to match.
 * @param {string} valueField
 * @param {string} textField
 * @param {function} [displayFunction] - Function used to format the matching values.
 */
const subMatches = function (list, sub, valueField, textField, displayFunction) {
  let item, i, val, text, doesMatch, validDispFunc
  var matches = []

  if (!list) {
    console.warn('Missing list parameter--returning zero matches.')
    return matches
  }
  if (!Array.isArray(list)) {
    console.warn('list parameter is not an array--returning zero matches.')
    return matches
  }
  if (typeof sub === 'object') {
    console.warn('sub parameter of incorrect type--returning zero matches.')
    return matches
  }
  validDispFunc = true
  if (typeof displayFunction !== 'function') {
    console.warn(
      'displayFunction parameter is not a function--skipping matching by displayFunction.'
    )
    validDispFunc = false
  }

  if (sub === undefined || sub === null) {
    console.warn('Missing sub parameter--returning all list elements.')
    sub = ''
  }
  var s = sub.toString().toLowerCase()
  if (s === '') return list

  for (i = 0; i < list.length; i++) {
    item = list[i]
    doesMatch = false

    val = _v(item, valueField)
    if (val === undefined || val === null) val = ''
    val = val.toString().toLowerCase()
    if (val.indexOf(s) + 1) doesMatch = true

    if (!doesMatch && valueField !== textField) {
      text = _v(item, textField)
      if (text === undefined || text === null) text = ''
      text = text.toString().toLowerCase()
      if (text.indexOf(s) + 1) doesMatch = true
    }

    if (!doesMatch && validDispFunc) {
      text = displayFunction(item)
      if (text === undefined || text === null) text = ''
      text = text.toString().toLowerCase()
      if (text.indexOf(s) + 1) doesMatch = true
    }

    if (doesMatch) matches.push(item)
  }

  return matches
}

module.exports = { subMatches }
