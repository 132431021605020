const { qc } = require('../cmp/qc')
const { killEvent } = require('../killEvent')
const { ctlParsers, ctlFormatters } = require('../ctl-format')
const { culture, dates } = require('../culture')
const { _v } = require('../_v')
const { dropdown } = require('./dropdown')
const { icon6 } = require('./icons')

const time6 = props => {
  if (ow0.dev && props.tag) throw 'Cannot set tag in time6'
  if (ow0.dev && !props.view) throw 'You must pass opts.view into time6'

  const view = props.view

  // props.tag = 'input.text6.time.text-icon-after'
  props.format = props.format || culture().TimeFormatSetting || 'HH:mm'
  props.parser = props.parser || ctlParsers.time
  props.formatter = props.formatter || ctlFormatters.time // (v, fmt) => (!v ? '' : formatDate(v, fmt || culture().TimeFormatSetting))

  // should this be in DataCtl
  if (!('value' in props) && props.model && props.fieldName)
    props.value = _v(props.model, props.fieldName)

  const me = view.controls.text6(props)

  me.props({
    requestTabOut() {
      this.resolve()
      return true
    },

    resolve() {
      const el = this.el
      if (el.value) {
        let v = dates.resolveTime(el.value, true)
        if (el.value !== v) {
          el.value = v
          this.trigger('ow-change')
        }
      }
    },

    updateContent() {
      const { el, dropdown } = this

      let list = []
      let i = 0,
        hh
      for (; i < 24; i++) {
        hh = ('0' + i).substr(-2)
        list.push(hh + ':00')
        list.push(hh + ':30')
      }
      list.push('24:00')

      dropdown.kids([
        qc(
          'div.combo-list-popup',
          qc(
            'ul',
            !list.length
              ? qc('li', __('No Matches Found'))
              : list.map((x, i) => {
                  let li = qc('li', { class: i === this.listIndex ? 'ow-selected' : '' }, x).on(
                    'click',
                    () => {
                      el.value = x
                      el.focus()
                      dropdown.close()
                      li.trigger('ow-change')
                    }
                  )
                  return li
                })
          ).css({ listStyleType: 'none' })
        )
      ])
      dropdown.renderAsync()

      return el
    },

    openDropDown() {
      this.dropdown = dropdown({
        combo: this,
        listWidth: 1 // el.parentElement.offsetWidth
      })
      this.dropdown.renderTo(view.qTop.el.parentElement)
      this.addClass('ow-dropdown-open')
      this.updateContent()
    }
  })
    .on('keydown', e => {
      const { el } = me

      me.prevValue = me.prevValue || ''

      if (e.which === 13 && (e.shiftKey || me.openOnEnterKey)) {
        if (!me.dropdown) {
          if (me.hasClass('static-text') || me.disabled || me.readOnly) return
          me.openDropDown()
          return killEvent(e, false)
        } else {
          me.dropdown.close()
          return killEvent(e, false)
        }
      }

      // backspace
      if (e.which === 8) return

      // delete
      if (e.which === 46) {
        var charDeleted = (me.prevValue || el.value)[el.selectionEnd - 1]
        if ('/-.'.indexOf(charDeleted) > -1) return
      }
    })
    .on('keyup', e => {
      const { el } = me
      if (el.value !== me.prevValue) {
        // backspace // delete
        if (e.which !== 8 && e.which !== 46) {
          var x = el.selectionEnd
          var caretAtEnd = x === el.value.length
          var v = dates.resolveTime(el.value, false, undefined, { position: x })
          if (v.indexOf('|') > -1) {
            x = v.indexOf('|')
            v = v.split('|').join('')
          }
          if (el.value !== v) el.value = v
          me.prevValue = v
          if (!caretAtEnd) {
            el.selectionStart = x
            el.selectionEnd = x
          }
        }

        const dtVal = me.val()
        if (!el.value || dtVal) me.trigger('ow-change')
      }
    })
    .on('blur', () => {
      setTimeout(() => {
        if (me.el !== document.activeElement) me.trigger('ow-delayed-blur')
      }, 200)
    })
    .on('ow-delayed-blur', e => {
      const { el, dropdown } = me
      if (dropdown) {
        if (me.ignoreNextChange) {
          me.ignoreNextChange = false
          return killEvent(e, false)
        } else dropdown.close()
      }
      if (el === document.activeElement) return killEvent(e, false)
      if (me.resolveBeforeExit) me.resolveBeforeExit(!me.allowUnmatchedText)
    })

  const w = me.wrap().addClass('ow-time-wrap text-icon-after')

  w.kids([
    ...w.kids(),
    icon6('clock')
      .addClass('text-item-icon')
      .addClass('i-time')
      .on('click', e => {
        me.el.focus()
        if (me.dropdown) me.dropdown.close()
        else {
          if (me.hasClass('static-text') || me.disabled || me.readOnly) return
          me.openDropDown()
        }
        return killEvent(e)
      })
  ])

  return me
}

module.exports = { time6 }
