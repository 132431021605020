const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { toStyleSheet } = require('../css')
const { _id } = require('../_id')

// stylesheet.js

const sheets = {}

// used by the view window
const sheetCmp = () =>
  qc('style', html('/* no styles yet, use addCss() to add some */'))
    .addClass('js-stylesheet')
    .bindState('scope', function (scope) {
      if (!scope) return
      let sCss = Object.values(sheets)
        .map(({ sheetID, css }) => '/* ' + sheetID + '*/\n' + css)
        .join('\n')

      if (this.scope) sCss = sCss.split('#scope').join(this.scope || '')
      this.kids([html(sCss)])
    })

function addCss(sheetID, css, el) {
  if (typeof css !== 'string') css = toStyleSheet(css)

  if (el) {
    if (!el.id) {
      el.id = '_' + _id()
      el.setAttribute('id', el.id)
    }
    const id = sheetID + '-' + el.id
    Array.from(el.children).forEach(x => {
      if (x.id === id) x.remove()
    })

    let children = css.split('#scope').join(id ? '#' + id : '')

    return qc('style', children).attr({ id }).renderTo(el)
  } else if (sheets[sheetID] === css) return

  sheets[sheetID] = { sheetID, css }

  // sheetCmp.renderAsync()
}

module.exports = { sheetCmp, addCss }
