const { qc } = require('../../cmp/qc')
const { formatString } = require('../../ctl-format')

const drawColFooter = (grid, col) => {
  if (col.gridCol === false) return ''

  const footer = col.footer || {}

  // const align = footer.align || col.align
  const span = qc('span.cell-liner')

  if (col.footer) {
    if (footer.template)
      span.kids(
        footer.template.call(
          col,
          (grid.dataView.allGroupRows || grid.dataView || []).filter(r => !r.isGroup), // all Rows without the groups
          grid
        )
      )
    else {
      span.addClass(col.type || '')
      // fieldName: 'footer-coli-' + col.coli,
      span.dataType = col.type
      span.bindState(
        () => col.footer.value,
        function () {
          this.kids([
            col.format ? formatString(col.footer.value, col.format) : formatString(col.footer.value)
          ])
        }
      )
    }
  }

  const cell = qc('td.footer', span)
    .attr(footer.attrs || {})
    .props({ col, grid })
    // .css({ paddingLeft: '0', paddingRight: '0' })
    .attr({ 'data-coli': col.coli, role: 'columnfooter', scope: 'col' })
    .addClass('coli-' + col.coli)
    .bindState('col.width', w => cell.css({ width: w.toString() + 'px' }))
    .bindState('col.hidden', hidden => cell.css({ display: hidden ? 'none' : undefined }))

  // if (align) res.css({ textAlign: align }) // addClass('align-' + align)

  let dataType = footer.type || col.type || ''

  if (dataType) {
    if (
      dataType === 'number' ||
      dataType === 'int' ||
      dataType === 'integer' ||
      dataType === 'float' ||
      dataType === 'currency'
    )
      cell.addClass('number-col')
    else cell.addClass(dataType + '-col')
  }

  return cell
}

const gridFooter = grid => {
  const { cols } = grid

  const footerRow = qc('tr.ow-footer-row').bindState(
    () => grid.dataView,
    () =>
      footerRow.kids(cols.filter(col => col.gridCol !== false).map(col => drawColFooter(grid, col)))
  )

  const footerWrap = qc(
    'div.ow-grid-footer-wrap',
    qc('table', qc('tbody', footerRow))
      .css({ backgroundColor: '#f3f3f4' })
      .bindState(
        () => grid.totalColWidth,
        function (w) {
          this.css({ width: w.toString() + 'px' })
        }
      )
  ) //.on('scroll', () => grid.setHorizontalScroll(footerWrap.scrollLeft))

  return qc('div.ow-grid-footer', footerWrap).attr({ tabindex: '-1' })
}

module.exports = { gridFooter }
