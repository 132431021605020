const { _v } = require('../_v')

// filters.js
const isObject = x => typeof x === 'object'
const isDate = x => isObject(x) && x && 'getFullYear' in x

/**
 * Returns the default filter operator given a filter type.
 * @param {string} type - Filter type (e.g. string, number, etc).
 * @returns {string} - Returns the default operator for the filter type. Allowed values are: contains, eq.
 */
const defOp = type => {
  return type === 'string' ? 'contains' : 'eq'
}

/**
 * Resets a grid filter to default operator and value.
 * @param {object} cmpObj - cmp object representing a filter <input> element.
 */
const resetFilter = cmpObj => {
  const defaultOp = cmpObj.defOp ?? defOp(cmpObj.type)
  cmpObj.op = cmpObj.op === defaultOp ? cmpObj.op : defaultOp
  cmpObj.el.value = cmpObj.el.value === '' ? cmpObj.el.value : ''
}

/**
 * Accepts an operator, family, and value, and returns a processed value based on the three. Intended to process values used during client-side filter execution.
 * @param {string} operator - Filter operator (e.g. 'nmNEq'). See filterOperators object for allowed operators.
 * @param {string} family - Filter family (e.g. 'datetime'). See filterOperators object for allowed  families.
 * @param {any} val - Value to process.
 * @returns {any}
 */
const processVal = (operator, family, val) => {
  let procVal

  switch (family) {
    case 'int':
    case 'float':
      procVal = typeof val === 'string' ? Math.fround(parseFloat(val)) : Math.fround(val)
      break
    case 'string':
      procVal = val.toLowerCase()
      break
    case 'datetime':
      if (operator === 'sameDay' || operator === 'sameHour' || operator === 'sameMinute') {
        procVal = val
      } else {
        procVal = val.getTime()
      }
      break
    case 'date':
      procVal = val.getTime()
      break
    default:
      procVal = val
  }
  return procVal
}

/**
 * Defines filter operator names.
 * @constant
 * @type {object}
 * @property {object} - string
 * @property {object} - number
 * @property {object} - date
 * @property {object} - time
 * @property {object} - datetime
 * @property {object} - enums
 */
const filterOperators = (__ = window.__) => {
  const result = {
    boolean: {
      eq: __('FILTERCELL:NUMBER:eq')
      // true: __('true'),
      // false: __('false')
    },
    string: {
      isBlank: __('FILTERCELL:STRING:isBlank'),
      notBlank: __('FILTERCELL:STRING:notBlank'),
      contains: __('FILTERCELL:STRING:contains'),
      startswith: __('FILTERCELL:STRING:startswith'),
      eq: __('FILTERCELL:STRING:eq'),
      neq: __('FILTERCELL:STRING:neq'),
      doesnotcontain: __('FILTERCELL:STRING:doesnotcontain'),
      endswith: __('FILTERCELL:STRING:endswith')
    },
    number: {
      eq: __('FILTERCELL:NUMBER:eq'),
      neq: __('FILTERCELL:NUMBER:neq'),
      gte: __('FILTERCELL:NUMBER:gte'),
      gt: __('FILTERCELL:NUMBER:gt'),
      lte: __('FILTERCELL:NUMBER:lte'),
      lt: __('FILTERCELL:NUMBER:lt')
    },
    date: {
      eq: __('FILTERCELL:NUMBER:eq'),
      neq: __('FILTERCELL:NUMBER:neq'),
      gte: __('FILTERCELL:DATETIME:gte'),
      gt: __('FILTERCELL:DATETIME:gt'),
      lte: __('FILTERCELL:DATETIME:lte'),
      lt: __('FILTERCELL:DATETIME:lt')
    },
    datetime: {
      eq: __('FILTERCELL:NUMBER:eq'),
      neq: __('FILTERCELL:NUMBER:neq'),
      gte: __('FILTERCELL:DATETIME:gte'),
      gt: __('FILTERCELL:DATETIME:gt'),
      lte: __('FILTERCELL:DATETIME:lte'),
      lt: __('FILTERCELL:DATETIME:lt'),
      sameDay: __('FILTERCELL:DATETIME:sameDay'),
      beforeendofday: __('Is before or equal to same day'),
      sameHour: __('FILTERCELL:DATETIME:sameHour'),
      sameMinute: __('FILTERCELL:DATETIME:sameMinute')
    },
    time: {
      eq: __('FILTERCELL:NUMBER:eq'),
      neq: __('FILTERCELL:NUMBER:neq'),
      gte: __('FILTERCELL:DATETIME:gte'),
      gt: __('FILTERCELL:DATETIME:gt'),
      lte: __('FILTERCELL:DATETIME:lte'),
      lt: __('FILTERCELL:DATETIME:lt')
    },
    enums: {
      eq: __('FILTERCELL:NUMBER:eq'),
      neq: __('FILTERCELL:NUMBER:neq')
    }
  }

  result.integer = result.number
  result.float = result.number

  return result
}

/**
 * Defines functions used for different filter operations.
 * @constant
 * @type {object}
 */
const filterFunctions = {
  sameDay(v, fv) {
    if (!v && !fv) return true
    if (!isDate(v) || !isDate(fv)) return false
    if (v.getDate() !== fv.getDate()) return false
    if (v.getMonth() !== fv.getMonth()) return false
    if (v.getFullYear() !== fv.getFullYear()) return false
    return true
  },

  sameHour(v, fv) {
    if (!filterFunctions.sameDay(v, fv)) return false
    if (v.getHours() !== fv.getHours()) return false
    return true
  },

  sameMinute(v, fv) {
    if (!filterFunctions.sameHour(v, fv)) return false
    if (v.getMinutes() !== fv.getMinutes()) return false
    return true
  },

  notBlank(v) {
    return (v ?? '') !== ''
  },

  isBlank(v) {
    return (v ?? '') === ''
  },

  eq(v, fv) {
    if (!fv) return true
    return v === fv
  },

  contains(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.indexOf(fv) > -1
  },

  doesnotcontain(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.indexOf(fv) === -1
  },

  startswith(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.substr(0, fv.length) === fv
  },

  endswith(v, fv) {
    if (!fv) return true
    return typeof v === 'string' && v.substr(-1 * fv.length) === fv
  },

  logic(v, fv) {
    return (v && fv.true) || (!v && fv.false)
  },

  true(ignore, fv) {
    return fv === true
  },

  false(ignore, fv) {
    return !fv
  },

  gt(v, fv) {
    return v > fv
  },

  gte(v, fv) {
    return v >= fv
  },

  lt(v, fv) {
    return v < fv
  },

  lte(v, fv) {
    return v <= fv
  }
}

/**
 * Compares a record and a filter and determines if they match.
 * @param {object} r - Object containing data from a single grid row.
 * @param {object} f - Object representing the filter value.
 * @property {string} f.operator - String representing the filter operator (e.g. 'nmGT'). See filterOperators for full list of possilble filter operators.
 * @property {any} f.value - Value of filter.
 * @param {object[]} cols - Array of objects representing the grid columns.
 * @property {string} cols[].field - String representing the name of the grid column.
 * @property {string} cols[].type - String representing the grid column type (e.g. number, string, boolean, etc). See filterOperators for full list of possilble types.
 * @returns {boolean}
 */
const applyFilter = (r, f) => {
  const op = f.operator
  if (op === undefined || op === null) return true

  const fv = f.value
  if ((fv ?? '') === '' && !allowFilterUndefined[op]) return true

  let v = _v(r, f.field)
  if (f.dataType === 'boolean') v = v ?? false //Treat undefined Boolean values as false.

  if (op === 'null') return v == null
  if (op === 'notnull') return true

  const procV = processVal(op, f.dataType ?? typeof v, v)
  const procFV = processVal(op, f.dataType ?? typeof v, fv)

  if ((typeof v === 'number' || ['number', 'int', 'float'].includes(f.dataType)) && isNaN(procFV))
    return true

  if (op === 'neq') return procV !== procFV
  if (op === 'eq') return procV === procFV

  const fn = filterFunctions[op]
  if (fn) return fn(procV, procFV)

  return true
}

const allowFilterUndefined = {
  notBlank: 1,
  isBlank: 1,
  isnull: 1,
  isnotnull: 1,
  true: 1,
  false: 1
  //, contains: 0,
  // startswith: 0,
  // endswith: 0,
  // doesnotcontain: 0,
  // eq: 0,
  // neq: 0,
  // gt: 0,
  // gte: 0,
  // lt: 0,
  // lte: 0,
  // and: 0,
  // or: 0,
  // in: 0,
  // not: 0,
  // notIn: 0,
  // sameMinute: 0,
  // sameHour: 0,
  // sameDay: 0,
}

module.exports = {
  allowFilterUndefined,
  filterFunctions,
  applyFilter,
  filterOperators,
  defOp,
  resetFilter
}
