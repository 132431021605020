const { isDate } = require('../js-types')
const { _v } = require('../_v')

const handleDateValue = x => (isDate(x) ? x.valueOf() : x)

// a - b  -> asc order
// negative value means A goes first
const bGoesFirst = 1 // the value is basically a - b and assuming desc
const aGoesFirst = -1

/**
 * Array sort function builder
 * @param {Array[]} sorts - Array of arrays of [fieldName(string or function), dir (0 = asc, 1 = desc)]
 * @returns a function to add into Array.sort() to sort the array
 */
const recSorter = sorts => {
  const _sorts = sorts.map(([f, dir]) => ({
    f,
    dir,
    val: typeof f === 'string' ? x => _v(x, f) : f
  }))

  return (a, b) => {
    let i, av, bv
    for (i = 0; i < _sorts.length; i++) {
      const { dir, val } = _sorts[i]
      const isDesc = dir === 1

      av = handleDateValue(val(a))
      bv = handleDateValue(val(b))

      // const _dontSwap = () => {
      //   // console.log('>>>>> ', av, bv, ' = dont bGoesFirst:', aGoesFirst, isDesc ? 'desc' : 'asc')
      //   return aGoesFirst
      // }

      // const _swap = () => {
      //   // console.log('>>>>> ', av, bv, ' = bGoesFirst:', bGoesFirst, isDesc ? 'desc' : 'asc')
      //   return bGoesFirst
      // }

      if (bv === av) continue

      if (!av || !bv) {
        if (isDesc ? bv === undefined : av === undefined) return aGoesFirst
        if (isDesc ? av === undefined : bv === undefined) return bGoesFirst
        if (isDesc ? bv === null : av === null) return aGoesFirst
        if (isDesc ? av === null : bv === null) return bGoesFirst
      }
      if (isDesc ? av > bv : bv > av) return aGoesFirst
      return bGoesFirst
    }
    return 0
  }
}

module.exports = { recSorter, bGoesFirst, aGoesFirst }
