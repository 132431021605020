// data-validation.js
const { _v } = require('../_v')
const { schemaFields, $meta, isDeleted } = require('./data-model')

const validateField = (model, fieldDef) => {
  // if required and nullish fail
  const sch = $meta(model).schema

  if (sch[fieldDef.fieldName] && sch[fieldDef.fieldName].isCollection) {
  }

  const v = _v(model, fieldDef.fieldName)

  if (fieldDef.required && (v === null || v === undefined)) return false

  return true
}

const rightSideVal = (model, rightSide) => {
  return typeof rightSide === 'object' && rightSide.field ? _v(model, rightSide.field) : rightSide
}

const tests = {}

tests.required = fieldName => model => {
  const v = _v(model, fieldName)
  return v === '' || v === null || v === undefined ? { fieldName, model, reason: 'required' } : true
}

tests.eq = (fieldName, rightSide) => model => {
  const v = _v(model, fieldName)
  let valid = v === rightSideVal(model, rightSide)
  return valid ? true : { fieldName, model, reason: 'eq', rightSide }
}

tests.ne = (fieldName, rightSide) => model => {
  const v = _v(model, fieldName)
  let valid = v !== rightSideVal(model, rightSide)
  return valid ? true : { fieldName, model, reason: 'ne', rightSide }
}

tests.gt = (fieldName, rightSide) => model => {
  const v = _v(model, fieldName)
  let valid = v > rightSideVal(model, rightSide)
  return valid ? true : { fieldName, model, reason: 'gt', rightSide }
}

tests.gte = (fieldName, rightSide) => model => {
  const v = _v(model, fieldName)
  let valid = v >= rightSideVal(model, rightSide)
  return valid ? true : { fieldName, model, reason: 'gte', rightSide }
}

tests.lt = (fieldName, rightSide) => model => {
  const v = _v(model, fieldName)
  let valid = v < rightSideVal(model, rightSide)
  return valid ? true : { fieldName, model, reason: 'lt', rightSide }
}

tests.lte = (fieldName, rightSide) => model => {
  const v = _v(model, fieldName)
  let valid = v <= rightSideVal(model, rightSide)
  return valid ? true : { fieldName, model, reason: 'lte', rightSide }
}

const validateCollection = (modelArray, onFail) => {
  if (!Array.isArray(modelArray)) return true
  return (
    modelArray.filter((kid, i) => !isDeleted(kid) && !validateModel(kid, onFail, i)).length === 0
  )
}

/**
 *
 * @param {object} model
 * @param {function} onFail
 * @returns boolean if the model is valid
 */
const validateModel = (model, onFail) => {
  const fieldDefs = schemaFields(model)
  let isValid = true

  let modelValidators = fieldDefs.reduce((r, fieldDef) => {
    let { validation = {} } = fieldDef
    if (fieldDef.required) validation.required = fieldDef.required

    Object.keys(tests).forEach(
      reason => validation[reason] && r.push(tests[reason](fieldDef.fieldName, validation[reason]))
    )

    if (fieldDef.isModel && !fieldDef.ignore) {
      const kid = model[fieldDef.fieldName]
      if (fieldDef.isCollection) {
        r.push(() => (validateCollection(kid, onFail) ? true : false))
      } else r.push(() => (validateModel(kid, onFail) ? true : false))
    }

    return r
  }, [])

  modelValidators.forEach(fn => {
    const result = fn(model)
    if (result !== true) {
      isValid = false
      onFail && result && onFail(result)
    }
  })

  return isValid
}

module.exports = { validateField, validateModel, tests }
