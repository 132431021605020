const { textnode } = require('./textnode')

const contentToString = (c, renderScope) => {
  if (typeof c === 'string') c = textnode(c)

  return Array.isArray(c)
    ? c.map(x => contentToString(x, renderScope)).join('')
    : c.toString(renderScope) || ''
}

module.exports.contentToString = contentToString
