const { card } = require('./scheduler6-card')
const Model = require('../data-model')
const { openContextMenu } = require('../cmenu6')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')

const { getWeekOfYear, weekStarts, formatDate, leftPad } = require('../../dates')

const findFirstFreeSpace = arr => {
  let result = 0
  while (arr[result]) result++
  return result
}

const slotMap = (data, numSlots) => {
  let i,
    j,
    siblingId,
    map = []
  for (i = 0; i <= numSlots; i++) map[i] = []
  data.forEach(r => {
    if (r.rowid <= numSlots) {
      siblingId = findFirstFreeSpace(map[r.rowid])
      for (j = 0; j < (r.slot || 1); j++) if (map[r.rowid + j]) map[r.rowid + j][siblingId] = r
    }
  })

  return map
}
const minsInADay = 60 * 24
const populateFixedRows = (gap, start = 0, end = minsInADay) => {
  let result = [start]
  while (start < end - gap) {
    start += gap
    result.push(start)
  }
  return result
}

const timelineHeader = scheduler =>
  qc('th').css({ minWidth: (scheduler.cols?.colWidth || 100) + 'px' })

const slotCell = (scheduler, rowi, col) => {
  const me = qc('td.droppable-cell')
    .on('dragenter', e => {
      //console.warn('card-dragenter')
      e.preventDefault()
      me.addClass('hovered')
    })
    .on('dragleave', () => {
      //warn('card-dragleave')
      me.removeClass('hovered')
    })
    .on('dragover', e => {
      //warn('card-dragover')
      return killEvent(e)
    })
    .on('drop', e => {
      //warn('card-drop')
      me.removeClass('hovered')

      // get the draggable element
      const { model } = scheduler.draggingCard
      model.rowid = rowi
      model.colid = col
      Model.react(scheduler.view, model, 'rowid', 'colid')

      return killEvent(e)
    })
    .on('context-menu', e => {
      if (e.target?.tagName !== 'TD') return
      const mnu = openContextMenu(e.target, {
        view: scheduler.view,
        point: {
          left: e.pageX, // - $offset(p).left,
          top: e.pageY // - $offset(p).top
        },
        setWidth: false,
        content() {
          let res = qc('ul', scheduler.menu({ rowi, col }) || []).on('click', () => mnu.close())
          res.rowid = rowi
          res.colid = col
          return res
        }
      })
      return killEvent(e)
    })
    .bindState(function () {
      const colData = scheduler.dataSet
        .filter(f => f.colid === col)
        .sort((a, b) => a.rowid - b.rowid)
      const cardData = colData.filter(f => f.rowid === rowi && f.colid === col)

      const colSlotMap = slotMap(colData, minsInADay / scheduler.interval)

      if (cardData.length !== this.kids().length || cardData.find(x => this.kids().model !== x)) {
        this.kids(
          cardData.map(model => {
            const siblingId = colSlotMap[model.rowid].indexOf(model)

            let siblingCount = 1
            for (let i = 0; i < (model.slot || 1); i++) {
              siblingCount = Math.max(siblingCount, colSlotMap[i + model.rowid].length)
            }

            let me

            return (me = card({
              model,
              fieldName: 'id',
              scheduler,
              siblingId,
              siblingCount,
              resizable: { rowid: model.rowid }
            }).on('dragstart', () => (scheduler.draggingCard = me)))
          })
        )
      }
    })

  return me
}

const buildSchedulerHeader = (scheduler, col) => {
  const { colFieldDesc, mode } = scheduler.cols
  return qc(
    'th',
    qc('span', mode ? col['Desc'] : typeof col === 'object' ? col[colFieldDesc] : col)
  )
    .attr({ scope: 'col', role: 'columnheader' })
    .css({
      paddingLeft: 0,
      paddingRight: 0,
      minWidth: (scheduler.cols?.colWidth || 100) + 'px'
    })
}

//body
const drawRow = (scheduler, row, rowi, cols) =>
  qc('tr.ow-scheduler-row.row-' + rowi, [
    qc('td', row % 60 === 0 ? leftPad(row / 60) + ':00' : html('&nbsp')),
    cols.map(col => slotCell(scheduler, rowi, col))
  ])
    .attr({ rowid: rowi })
    .css({ lineHeight: (scheduler.rowHeight || 20) + 'px' })

const schedulerBody = scheduler => {
  const _mode = scheduler.cols?.mode || 0
  const populateWeeklyCols = () => {
    let cols = []
    const { selectedDate } = scheduler.state.current
    const { dateFormat } = scheduler.cols
    const weekNo = getWeekOfYear(selectedDate, new Date().getFullYear())
    const base = weekStarts(weekNo)
    for (let i = 0; i < 7; i++) {
      cols.push({
        Id: base.valueOf().toString(),
        Desc: formatDate(new Date(base), dateFormat || 'yyyy-MM-dd')
      })
      base.setDate(base.getDate() + 1)
    }

    return cols
  }

  let _colObj = _mode ? populateWeeklyCols() : scheduler.cols?.colList || []
  let _cols = _colObj.map(x => {
    let { colFieldName } = scheduler.cols
    return _mode ? x['Id'] : x[colFieldName]
  })

  const tableHeader = qc('tr.ow-scheduler-head').bindState(function () {
    this.kids([timelineHeader(scheduler), _colObj.map(col => buildSchedulerHeader(scheduler, col))])
  })

  return qc(
    'div.ow-scheduler-context',
    qc('table.scheduler6', [
      qc('thead', [tableHeader]),
      Model.modelBind(
        scheduler.view,
        qc('tbody').bindState(
          () => scheduler.dataSet,
          function () {
            console.warn('tbody')
            this.kids(
              populateFixedRows(scheduler.interval || 15, scheduler.start).map((row, index) =>
                drawRow(scheduler, row, index, _cols)
              )
            )
          }
        ),
        scheduler.model
      )
    ])
  ).props({
    scheduler,
    loadData(opts) {
      console.warn('body loadData', opts)
    },
    loadHeader() {
      if (!_mode) return
      _colObj = populateWeeklyCols()
      _cols = _colObj.map(x => x['Id'])
      tableHeader.renderAsync()
    }
  })
}

module.exports = {
  schedulerBody,
  slotMap,
  findFirstFreeSpace
}
