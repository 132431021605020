const { addCss } = require('../add-css')
const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { humanize } = require('../humanize')
const theme = require('../theme')
const { killEvent } = require('../killEvent')

const renderIcon = icon => (icon.name ? qc('i.' + icon.name).attr({ 'aria-hidden': 'true' }) : [])

const buttonOpts = (type, footer = false) =>
  ({
    copy: { name: __('Copy'), icon: { name: 'fa fa-clone' }, command: type, type },
    new: { name: __('New'), icon: { name: 'fa fa-plus' }, command: type, type },
    edit: { name: __('Edit'), icon: { name: 'fa fa-pencil' }, command: type, type },
    refresh: { name: __('Refresh'), icon: { name: 'fa fa-refresh' }, command: type, type },
    confirm: { name: __('Confirm'), icon: { name: 'fa fa-check' }, command: type, type },
    print: { name: __('Print'), icon: { name: 'fa fa-print' }, command: type, type },
    delete: {
      name: __('Delete'),
      icon: { name: 'fa fa-trash' },
      command: type,
      type,
      color: footer ? 'red' : undefined
    },
    'add-row': {
      name: __('Add Row'),
      icon: { name: 'fa fa-plus-circle', color: 'green' },
      command: type,
      type
    },
    clear: {
      name: __('Clear'),
      icon: { name: 'fa fa-eraser', color: 'black' },
      command: type,
      type
    },
    advanced: {
      name: __('Advanced'),
      icon: { name: 'fa fa-caret-down', color: 'black' },
      command: type,
      type
    },
    email: {
      name: __('Email'),
      icon: { name: 'fa fa-envelope' },
      command: type,
      type
    },
    export: {
      name: __('Export'),
      icon: { name: 'fa fa-share', color: 'green' },
      command: type,
      type
    },
    excel: {
      name: __('Excel'),
      icon: { name: 'fa fa-share', color: 'green' },
      command: type,
      type
    },
    select: {
      name: __('Select'),
      color: 'green',
      command: type,
      type
    },
    save: {
      name: __('Save'),
      color: 'green',
      command: type,
      type
    },
    cancel: {
      name: __('Cancel'),
      color: 'red',
      command: type,
      type
    }
  })[type] ?? { command: type, type }

module.exports.buttonOpts = buttonOpts

/**
 *
 * @param {string||object} opts
 *
 * @returns
 */
const button7 = opts => {
  opts = typeof opts === 'string' ? { type: opts } : opts
  opts.type = typeof opts === 'string' ? opts : opts.type ?? opts.name
  Object.assign(opts, { ...buttonOpts(opts.type), ...opts })
  opts.name = opts.name ?? humanize(opts.type)

  if (opts.command && !opts.view && window.dev) throw 'button7 must have opts.view'

  const me = qc('button.ow-button' + (opts.command ? '.command-button' : ''))
    .props({ opts })
    .addClass(Array.isArray(opts.class) ? opts.class.join(' ') : opts.class ?? '')

  opts.align = opts.align || ''
  opts.icon = opts.icon || { name: '' }

  if (opts.align) me.addClass('align-' + opts.align)
  if (opts.color) me.addClass(opts.color + '-button')

  if (opts.icon?.name) {
    opts.icon.align = opts.icon.align || 'left'
    opts.icon.color ? me.addClass('icon-' + opts.icon.color) : null
    opts.icon.align ? me.addClass('icon-' + opts.icon.align) : null
  }

  //Ctrl-D - Reserved for browser bookmark
  //F1 - Reserved for new Tab
  //F3 - Find
  //F6 - URL focus
  const isDefaultShortcut = {
    // 'filter-change': { key: 120 }, // F9
    save: { key: 113, focusFirst: true }, // F2
    refresh: { key: 118 } // F7?
    // close: { key: 115,  }, // F4?
    // 'close-form': { alt: true, key: 67 }, // Alt-C
    // if (e.which === 27) qc(g.closest('.win-con, body'))[0]?.closeForm?.(), 200); // ESC
    // if (e.which && e.altKey) {
    //   if (e.which === 114 && cmd === 'delete') me.el.click() //F3
    //   if (opts.shortcut) {
    //     if (!opts.shortcut.useCtrl && opts.shortcut.key === e.which) me.el.click()
    //     // Ctrl + Alt + key
    //     if (e.ctrlKey)
    //       if (opts.shortcut.useCtrl && opts.shortcut.key === e.which) me.el.click()
    //   }
    // }
  }

  if (!opts.shortcut) opts.shortcut = isDefaultShortcut[opts.command ?? opts.type]

  const { userRole = {} } = opts.view?.viewdata ?? {}

  const cmd = opts.command

  const hasPermissions = !(
    cmd !== 'close' &&
    cmd !== 'refresh' &&
    (!userRole ||
      (!userRole.CanWrite &&
        (cmd === 'new' ||
          (!userRole.CanRead && cmd === 'edit') ||
          cmd === 'copy' ||
          cmd === 'save' ||
          cmd === 'add-row')) ||
      (!userRole.CanDelete && cmd === 'delete'))
  )

  return me
    .props({
      hasPermissions,
      disable(disable = true) {
        if (!hasPermissions) disable = true
        disable = !!disable
        me.disabled = disable
        me.attr({ disabled: disable ? 'true' : undefined })
        disable ? me.addClass('ow-disabled') : me.removeClass('ow-disabled')
      },
      odisable(...args) {
        return me.disable(...args)
      }
    })
    .kids(
      opts.content || [opts.icon ? renderIcon(opts.icon) : undefined, opts.label ?? __(opts.name)]
    )
    .attr({
      'data-command': opts.command,
      // 'data-target-ref': opts.targetRef,
      'data-shortcut-key': opts.shortcut?.key || undefined
    })
    .bindState('disabled', () => me.attr({ disabled: me.disabled ? 'true' : undefined }))
    .on('click', e => me.disabled && killEvent(e))
    .on('click', () => cmd && me.trigger('command-' + cmd))
}

const shortcutKeyHandler = view =>
  function (e) {
    if (e.which === 113) return killEvent(e)
    if (e.altKey) view.qTop.find('[data-shortcutkey]')
  }

module.exports.shortcutKeyHandler = shortcutKeyHandler

/**
 *
 * @param {Array<string||object>} arrButtons array of button options
 * @param {string} type 'header' or 'footer'
 * @param {string} dsName
 * @param {object} view
 * @returns array of qcButtons
 */
const generateButtonType = (arrButtons, type, dsName, view) => {
  const value = []

  if (!Array.isArray(arrButtons)) return generateButtonType([arrButtons], type, dsName, view)

  const isCmp = item => typeof item === 'object' && typeof item.render === 'function'

  // Generate all required button type
  arrButtons.forEach(option => {
    if (option === 'divider') return value.push(qc('label', '|'))
    if (option === 'quick-search') return
    if (option.html) return value.push(html(option.html))

    // if it's already a Cmp object
    if (isCmp(option)) return value.push(option)

    if (typeof option === 'object' && option.type === undefined)
      option.type = option.html ? 'html' : 'custom'

    if (typeof option === 'string')
      option = {
        ...{
          name: humanize(option),
          type: option,
          view,
          command: option,
          class: option === 'save' ? 'green-button' : undefined
        },
        ...buttonOpts(option, type === 'footer')
      }
    else
      option = {
        ...buttonOpts(option.type ?? option.name, type === 'footer'),
        ...option
      }

    option.targetRef = option.targetRef ?? dsName
    option.type = option.type.toLowerCase()
    option.view = view

    if (!option.name && option.type) option.name = humanize(option.type)
    if (option.name) option.name = __(option.name)

    value.push(button7(option))
  })

  return value
}

addCss(
  'button-css',
  `
.ow-button[disabled]  {
  color: #a6a6ad;
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
}

.ow-button {
  display: inline-block;
  margin: 0;
  padding: 2px 7px 2px;
  font-family: inherit;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 100%;
  border-style: solid;
  border-width: 1px;
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  box-sizing: border-box;
  margin: 0 2px;
}

.ow-button:first-child {  margin-left: 0; }
.ow-button:last-child { margin-right: 0; }

.ow-button i { display: inline; }

.ow-button.green-button[disabled], 
.ow-button.green-button[disabled] {
  color: #a6a6ad !important;
  border-color: ${theme.textboxBorderColor};
  background-color: ${theme.grayBackgroundColor};
}
`
)

module.exports.generateButtonType = generateButtonType
module.exports.button7 = button7
