const { button6 } = require('../button6')
const { getWeekOfYear, weekStarts } = require('../../dates')
const { qc } = require('../../cmp/qc')

const navigator = scheduler => {
  const me = qc('div.ow-scheduler-panel')

  const today = () => {
    scheduler.state.current.selectedDate = new Date(new Date().setHours(0, 0, 0, 0))
    scheduler.renderAsync()
  }

  const prev = () => {
    let { selectedDate } = scheduler.state.current
    const base = new Date(selectedDate.valueOf())
    const weekNo = getWeekOfYear(selectedDate, new Date().getFullYear())

    const prevDate = scheduler.cols.mode
      ? weekStarts(weekNo - 1)
      : new Date(base.setDate(base.getDate() - 1))
    scheduler.state.current.selectedDate = prevDate //new Date(base.setDate(base.getDate() - 1))
    scheduler.renderAsync()
  }

  const next = () => {
    let { selectedDate } = scheduler.state.current
    const base = new Date(selectedDate.valueOf())
    const weekNo = getWeekOfYear(selectedDate, new Date().getFullYear())

    const nextDate = scheduler.cols.mode
      ? weekStarts(weekNo + 1)
      : new Date(base.setDate(base.getDate() + 1))
    scheduler.state.current.selectedDate = nextDate //new Date(base.setDate(base.getDate() + 1))
    scheduler.renderAsync()
  }

  const btnToday = button6({
    name: 'today',
    label: __('Today')
  }).on('click', today)

  const btnPrev = button6({
    name: 'prev',
    label: __('LetItBlank'),
    iconName: 'arrow-left'
  }).on('click', prev)

  const btnNext = button6({
    name: 'next',
    label: __('LetItBlank'),
    iconName: 'arrow-right'
  }).on('click', next)

  const dateNote = qc(
    'div',
    qc(
      'div',
      qc('span')
        .bindState(
          () => scheduler.state.current.selectedDate,
          function (selectedDate) {
            let s = selectedDate.toJSON().split(' ')[0]
            if (scheduler.cols.mode) {
              const weekFirstDate = weekStarts(
                getWeekOfYear(selectedDate, new Date().getFullYear())
              )
              const weekLastDate = new Date(
                new Date(weekFirstDate.valueOf()).setDate(weekFirstDate.getDate() + 7)
              )
              s = weekFirstDate.toJSON().split(' ')[0] + ' - ' + weekLastDate.toJSON().split(' ')[0]
            }
            this.kids([s])
          }
        )
        .css({ fontWeight: 'bold' })
    ).css({ paddingLeft: '10px' })
  ).css({ display: 'inline-block' })

  me.kids([btnToday, btnPrev, btnNext, dateNote])

  return me
}

module.exports = {
  navigator
}
