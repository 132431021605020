const unnestArray = arr => {
  if (!Array.isArray(arr)) throw 'unnestArray called on ' + typeof arr

  let i = arr.length - 1,
    x

  for (; i >= 0; i--) {
    x = arr[i]
    if (Array.isArray(x)) {
      // remove it
      arr.splice(i, 1)
      unnestArray(x)
      x.reverse().forEach(y => arr.splice(i, 0, y))
    }
  }

  return arr
}

module.exports = { unnestArray }
