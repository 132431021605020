const { setStyle } = require('../css')

const mergeClasses = (a = '', b = '') => {
  const r = {}
  ;[...a.split(' '), ...b.split(' ')]
    .join('.')
    .split('.') // removes dots
    .filter(x => x) // removes double spaces
    .forEach(cls => (r[cls] = 1))
  return Object.keys(r).join(' ')
}

const mergeAttrs = (a, b) => {
  a = copyProps({}, a)
  const c = {
    style: setStyle(a.style || '', b.style || ''),
    class: mergeClasses(a.class, b.class)
  }

  return copyProps(copyProps(a, b), c)
}

const copyProps = (obj, props = {}) => {
  const result = obj
  // copy the props
  Object.keys(props).forEach(k => {
    if (!{ tag: 1, children: 1, attrs: 1 }[k]) {
      if (k in result) {
        if (k.substr(0, 2) === 'on') {
          // warn('Making event Array property ' + k)
          if (!Array.isArray(result[k])) result[k] = [result[k]]
          result[k].push(props[k])
          return
        }

        // if (k !== 'applyState' && k !== 'populate') log('overriding property ' + k)
      }
      result[k] = props[k]
    }
  })

  return result
}

const mergeProps = (obj, props) => {
  let result = copyProps({}, obj)
  result.tag = obj.tag || props.tag || 'div'

  result.attrs = mergeAttrs(obj.attrs || {}, props.attrs || {})
  const children = obj.children || props.children
  if (children) result.children = children

  return copyProps(result, props)
}

module.exports.copyProps = copyProps
module.exports.mergeProps = mergeProps
module.exports.mergeAttrs = mergeAttrs
