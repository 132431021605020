const { $map } = require('../element-map')
const { htmlEncode } = require('../html-encode')
const { renderAsync } = require('./cmp-renderer')

const CmpTextNode = {
  textnode: true,

  toCode() {
    if (this.s.indexOf('${__(') === 0 && this.s.indexOf(')}') === this.s.length - 2)
      return this.s.substring(2, this.s.length - 1)
    return '`' + this.s + '`'
  },

  toString() {
    return htmlEncode(this.s)
  },

  render() {
    if (!this.el) {
      this.el = document.createTextNode(this.s)
      $map(this.el).cmp = this
    }
    return this.el
  },

  renderAsync() {
    return renderAsync(this)
  }
}

/**
 *
 * @param {string} s - literal string to be escaped and inserted into HTML
 */
const textnode = s => {
  const result = Object.create(CmpTextNode)
  result.s = s
  return result
}

require('./cmp-renderer').textnode = textnode

module.exports.textnode = textnode
