const dataBind = ctl => {
  if (ctl.opts.fieldName) ctl.attr({ 'data-field': ctl.opts.fieldName })
  if (ctl.opts.isFilterControl) {
    if (ctl.opts.dsName) ctl.attr({ 'data-filter-for': ctl.opts.dsName })
    return ctl.addClass('ow-filter-control')
  }
  if (ctl.opts.dsName) ctl.attr({ 'data-field-for': ctl.opts.dsName })
  return ctl
}

module.exports = { dataBind }
