/**
 * Lightweight client side version of inflection.humanize()
 * @param {string} s eg. 'new-row' => 'New Row'
 * @returns replaces hypens with spaces and makes first chars upper case.
 */
const humanize = s =>
  !s
    ? ''
    : s
        .split('-')
        .join(' ')
        .split(' ')
        .map(x => x[0].toUpperCase() + x.substr(1))
        .join(' ')

module.exports.humanize = humanize
