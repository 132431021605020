// win-con.js
const { hasChanges } = require('./data-model')
const { killEvent } = require('../killEvent')
const { $find, $attr, $is } = require('../no-jquery')
const { sheetCmp, addCss } = require('./stylesheet')

const winCon = view => {
  const me = view.qTop

  if (!me.el.parentElement.id) {
    me.el.parentElement.id = '_' + _id()
    qc(me.el.parentElement).attr({ id: me.el.parentElement.id })
  }
  const styles = sheetCmp()
  styles.scope = '#' + me.el.parentElement.id

  if (view.$top) {
    const viewProps = [
      'viewdata',
      'setVisible',
      'width',
      'height',
      'toFront',
      'edInit',
      'preLoads',
      'preLoadList',
      'view',
      'wid'
    ]
    viewProps.forEach(p => (me[p] = me[p] ?? view.$top[p]))
    delete view.$top
  }

  if (!me.dsName && me.dc) me.dsName = me.dc.dsName

  Object.assign(me, {
    dataSources: {},

    requestClose() {
      if (view.model) {
        if (hasChanges(view.model)) {
          common.confirm(
            __('Confirm'),
            __('Are you sure you want to discard changes and close the form?'),
            r => r && me.closeForm(true)
          )
          return false
        }
      }
    }
  })

  me.on('focusin', e => {
    if (new Date().getTime() - (e.target.blurTime || 0) < 100) return // if it lost focus to dropdown etc for moment.

    if (!e.target.classList.contains('.ow-cmenu') || !e.target.closest('.ow-cmenu')) {
      if (document.activeElement && !document.activeElement.closest('.ow-cmenu'))
        $find(me.el.parentElement, '.ow-cmenu').forEach(x => qc(x).close())
    }

    if (ow.windows.activeWindow && me.el !== ow.windows.activeWindow[0]) {
      console.log('>> NOT ACTIVE WINDOW')
      return
    }
    resolveFocus(me)
    me.lastFocused = document.activeElement
    return killEvent(e, false)
  })
    .on('enter-key', e => {
      // prevent the old enterKeyHandler from firing
      return killEvent(e, false)
    })
    .on('resize', () => {
      me.find('.size-con').forEach(x => qc(x).trigger('resize'))
    })
    .on('keydown', e => {
      // F2
      if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        // view/editor
        if (me.dc) if (e.target) qc(e.target).trigger('ow-change') // resolve current editing control

        me.requestSave?.()
      }
    })
    .on('change', e => {
      if (e.currentTarget.classList.contains('k-input-errbg'))
        no$(e.currentTarget).removeClass('k-input-errbg')
      // if (el.hasAttribute('data-filter-for')) {}
    })
    .on('ow-change', e => me.trigger('change', e))
    .on('command-save', e => {
      if (!qc(e.target).attr('data-field-for') && !qc(e.target).attr('data-target-ref'))
        if (me.dc?.save) {
          if (document.activeElement) qc(document.activeElement).trigger('ow-change') // in case there are changes
          setTimeout(() => me.dc.save(), 1)
          return killEvent(e, false)
        }
    })
    .on('command-cancel', e => {
      if (!$attr(e.target, 'data-field-for') && !$attr(e.target, 'data-target-ref')) {
        const { dc } = me
        if (dc?.cancel) {
          dc.cancel()
          return killEvent(e, false)
        }
      }
    })

  styles.renderTo(me.el.parentElement)
  return me
}

const ow6css = require('./ow6-css')
const { qc } = require('../cmp/qc')
const { _id } = require('../_id')
addCss('ow6css', ow6css)

module.exports = { winCon }

const validFocusSelector =
  'input, select, textarea, checkbox, button, a, td.gridcell, .tabstrip6, .focusable, .ow-selectable'

const canFocus = el => !el.hasAttribute('disabled') && $is(el, validFocusSelector)

const resolveFocus = qTop => {
  const focused = document.activeElement

  if (focused && canFocus(focused)) return

  // first try to set Focus to last Focused
  if (qTop.lastFocused) {
    console.log('FOCUSING: ' + qTop.lastFocused)
    if (qTop.lastFocused.resolveFocus) return qTop.lastFocused.resolveFocus() // eg grid
    qTop.lastFocused.focus()
    return
  }

  var defaultEls = qTop.find('.default-focus')
  if (defaultEls.length) {
    console.log('Focusing default: ' + qTop.viewdata.uid)
    defaultEls[0].focus()
    return
  }

  var ctrls = qTop.find(validFocusSelector).filter(canFocus)
  if (ctrls.length) {
    console.log('Focusing first: ' + qTop.viewdata.uid)
    ctrls[0].focus()
  }
}
