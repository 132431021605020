/**
 * usage: return killEvent(event)
 *
 * stops all propagation and prevents default
 *
 * @param {Event} e
 * @param {boolean} preventDefault default = true
 * @returns false
 */
module.exports.killEvent = (e, preventDefault = true) => {
  e.stopImmediatePropagation()
  e.stopPropagation()
  if (preventDefault) e.preventDefault()
  return false
}
