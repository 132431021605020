/**
 *
 * @param {int} val 32-bit rgb number
 */
module.exports.delphiColortoHex = val => {
  var r, g, b
  r = val & 0xff //convert delphi color to RGB
  g = (val >> 8) & 0xff
  b = (val >> 16) & 0xff
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1) //rgb to hex
}
