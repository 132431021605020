// grid6

// upgrading:
// grid.recs is now grid.dataSet
// grid.currentFilter.recs is now grid.dataView
// rowi is no longer used.  dataSet is a model (as per ../data-model.js)

const { $cmp, qc, setScrollLeft, mergeProps } = require('../../cmp/cmp')
const Model = require('../data-model')
const { deleteRow, undeleteRow, saveRec, saveChanges } = require('./grid-data')

const {
  normalizeCols,
  // buildButtonColumn,
  colsToGridColumns,
  totalColWidth
} = require('./grid-cols')

const { getDataForRow, getTr, getRowCmp } = require('./grid-row')
const { gridFooter } = require('./grid-footer')
const { gridHeader, readServerFilterControls } = require('./grid-header')
const { gridBody } = require('./grid-body')
const { pagerBar } = require('./grid-pager')

const { $find, $removeClass } = require('../../no-jquery')
const stylesheet = require('../stylesheet')
const {
  applyClientFilterGroupSort,
  readClientFilterControls,
  findGroup,
  setGroupKey
} = require('../dataset-view')
const { expandAllGroups } = require('./grid-groups')
const { load } = require('../data-source')
const { _v } = require('../../_v')
const { popInvalid } = require('../../pop-box')
const { killEvent } = require('../../killEvent')

const refilter = grid => {
  // grid.dataView = []
  if (grid.dataSet.length === 0) {
    grid.dataView = []
    return
  }

  if (grid.focusFirstRowOnPopulate !== false) grid.focusFirstRowOnPopulate = true

  grid.dataSet.sorts = grid.sorts

  const filters = readClientFilterControls(grid.view.qTop.el, grid.dsName)

  grid.dataView = applyClientFilterGroupSort(
    grid.dataSet,
    filters,
    grid.cols,
    grid.sorts,
    grid.groupBy || false,
    grid.showDeletedRows,
    {
      outerGroupBy: grid.outerGroupBy,
      openGroupMap: groupKey => _v(grid, 'dataView.groups.' + groupKey + '.open'),
      showGroupHeaders: grid.showGroupHeaders !== false,
      showGroupFooters: grid.showGroupFooters !== false
    }
  )

  const ae = document.activeElement
  grid.renderAsync().then(() => {
    if (ae?.closest('body') && ae !== document.activeElement) ae.focus()
  })

  // .then(() => {
  //   if (grid.focusFirstRowOnPopulate) {
  //     delete grid.focusFirstRowOnPopulate
  //     grid.body.focusFirstRow()
  //   }
  // })
}

const fillout = grid => {
  if (!grid.el) return
  let g = grid.el

  let newHeight = grid.el.clientHeight,
    otherElements = Array.from(g.children).filter(x => x !== grid.body.el),
    otherElementsHeight = 0

  if (newHeight < 20) return
  otherElements.forEach(el => (otherElementsHeight += el.offsetHeight))
  grid.body.css({ height: newHeight - otherElementsHeight + 'px' })
}

const resizeGrid = grid => {
  fillout(grid)
  if (grid.header.el) {
    const w = grid.header.el.offsetWidth - grid.header.el.children[0].offsetWidth
    grid.header.topRightFilterIcon.css({
      height: grid.header.el.offsetHeight + 'px',
      width: w + 'px'
    })

    // set column size and hide
    grid.renderAsync()
  }
}

const setHorizontalScroll = (grid, scrollLeft) => {
  if (grid.scrollLeft === scrollLeft) return

  grid.scrollLeft = scrollLeft

  setScrollLeft(grid.header.kids()[0], scrollLeft)
  setScrollLeft(grid.body, scrollLeft)
  setScrollLeft(grid.footer.kids()[0], scrollLeft)
}

const current = (grid, td) => {
  if (td && td[0]) throw 'grid6 does not accept jQuery cells'
  let { coli, reci } = grid.state.current

  if (grid.dataView.length === 0) {
    coli = 0
    reci = null
  }

  let prev

  if (reci !== -1 && reci !== undefined) {
    const rec = grid.dataSet[reci]
    let tr = getTr(grid, rec, true)
    prev = tr && $find(tr, 'td.coli-' + coli)[0]
  }
  if (typeof td === 'undefined') return prev

  let newColi = $cmp(td).coli

  const meta = Model.$meta($cmp(td.parentElement).rec)

  let newReci = meta.reci

  if (reci === newReci && coli === newColi) return prev

  if (reci !== newReci) {
    var leavingRec = reci !== null && grid.dataSet[reci]
    if (leavingRec) {
      var leavingRow = getRowCmp(grid, leavingRec)
      if (leavingRow) leaveRow(grid, leavingRow.el)
      // leavingRow.renderAsync()
    }

    $cmp(td.parentElement).renderAsync()
  }

  grid.state.current.coli = newColi
  grid.state.current.reci = newReci

  grid.renderAsync()

  return td
}

const moveNextCell = (grid, back) => {
  let td = current(grid)
  if (!td) return

  let tds = Array.from(td.parentElement.querySelectorAll('.row td:not(.no-tab)'))
  let ix = tds.indexOf(td)
  var goToCell = tds[ix + (back ? -1 : 1)]
  if (goToCell) focusCell(grid, goToCell)
  else grid.body.addRowOnFocusInput.el.focus()
}

const focusCell = (grid, td) => {
  if (td) $cmp(td).hasClass('non-editable-cell') || td
  if (document.activeElement.closest('td') === td) return

  const q = 'input, a.check6'

  const els = $find(td, q)
  if (els.length) {
    let ci = _v(grid.el, 'state.lastCellControlIndex')
    ci = Math.min(els.length - 1, ci || 0)
    els[ci].focus()
  } else {
    var span = $cmp($find(td, 'span')[0])
    span.attr({ tabindex: '1' }) // will be removed
    span.el.focus() // focus cell because no control
    span.attr({ tabindex: '-1' }) // removed
  }
}

const leaveRow = (grid, tr) => {
  if (!tr) return
  // log('leavingRow')

  var el = document.activeElement
  if (tr === el.closest('tr')) {
    // if focus is still in previous row, clean up.
    qc(el).trigger('ow-change')
    if (document.activeElement.dropdown) document.activeElement.$dropdown.close()
  }
  var data = getDataForRow(tr)

  if (!data) return // if it has reloaded or something

  if (data.isGroup) return

  if (grid.editable && grid.tabOutNewRow !== false && Model.isNewBlank(data)) {
    const reci = grid.dataSet.indexOf(data)

    Model.markDeleted(data)
    const index = grid.dataView.indexOf(data)
    if (index !== -1) grid.dataView.splice(index, 1)

    if (grid.state.current.reci === reci) {
      grid.state.current.reci = null
      // grid.state.current.fi = -1
      // let rec = grid.dataView[index]
      // if (index >= 0 && rec) {
      //   grid.state.current.reci = grid.dataSet.indexOf(rec)
      //   grid.state.current.fi = index
      // }
    }

    grid.body.tBody.kids(grid.body.tBody.kids().filter(tr => tr.model !== data))
    grid.body.renderAsync()
    grid.trigger('ow-grid-change', data, tr)
  } else {
    // allow changes to propagate before validating and saving etc.
    setTimeout(() => {
      if (getDataForRow(tr)) {
        if (grid.groupBy) {
          const groupKey = setGroupKey(grid.groupBy, data)
          const group = findGroup(grid, data)
          // if there's no group OR its group needs to change... refilter
          if (!group || group.key !== groupKey) grid.refilter()
        }

        if (grid.live) grid.saveRec(data)
        else grid.validateRow(tr)
      }
    }, 500)
  }
}

const select = (grid, rec) => {
  if (grid.selectable !== false) {
    const meta = Model.$meta(rec)
    if (!meta.selected) meta.selected = true
    else delete meta.selected
    grid.react(rec)
  }
}

const newRowAllowed = grid => {
  if (grid.editable === false) return false
  if (grid.disallowNewWhenExistingNewInvalid) {
    var res = grid.validate()
    if (res.resVal === 0) return false
  }
  if (typeof grid.allowNewRow === 'function') return grid.allowNewRow()
  return grid.allowNewRow
}

const quietAddRow = (grid, rec) => {
  if (!rec) rec = {}

  // apply defaults if nothing is set already.
  grid.cols.forEach(function (col) {
    if ('defaultValue' in col && col.field && _v(rec, col.field) === undefined) {
      _v(
        rec,
        col.field,
        typeof col.defaultValue === 'function' ? col.defaultValue() : col.defaultValue
      )
    }
  })

  Model.addRow(grid.dataSet, rec)
  grid.dataView.push(rec)

  return rec
}

const addRow = (grid, newRec = {}) => {
  if (!newRowAllowed(grid)) return

  grid.quietAddRow(newRec)

  var atBottom = grid.body.el.scrollTop + 2 > grid.body.el.scrollHeight - grid.body.el.clientHeight
  if (grid.dataView.length && !atBottom) {
    grid.body.el.scrollTop = Math.ceil(grid.body.el.scrollHeight - grid.body.el.clientHeight)
  }

  grid.renderAsync().then(() => {
    let tr = getTr(grid, newRec)
    if (tr) focusCell(grid, $find(tr, 'td:not(.non-editable-cell)')[0])
  }) // try again

  return newRec
}

const displayValidity = function (c, bValid, msg) {
  const invalidClass = 'k-input-errbg'

  if (bValid) {
    c.removeClass(invalidClass)
    delete c.invalidMsg
  } else {
    c.invalidMsg = msg
    c.attr({ title: msg })
    c.addClass(invalidClass)
  }
}

const validateRow = (grid, tr, onInvalid, messageArray = []) => {
  $removeClass($find(tr, '.k-input-errbg'), 'k-input-errbg')

  var result = { resVal: 1, uid: [], errMsg: '' }

  var localMessageArray = messageArray

  const { cols } = grid

  onInvalid =
    onInvalid ||
    function (title, msg, el) {
      // popInvalid('Invalid', title + msg)
      localMessageArray.push(title + ': ' + msg)
      el && tr !== el && displayValidity($cmp(el), false, msg)

      const cmp = $cmp(tr)
      cmp
        .addClass('ow-row-invalid')
        .renderAsync()
        .then(() => setTimeout(() => cmp.removeClass('ow-row-invalid'), 600))
    }

  var rec = getDataForRow(tr)

  // ignore new blank rows
  if (Model.isNewBlank(rec)) return result

  const meta = Model.$meta(rec)

  cols.forEach(function (col) {
    if (!result) return false

    if (meta.deleted) return

    if (typeof col.validation === 'function') {
      var rv = col.validation(rec)
      if (rv && typeof rv === 'string') {
        rv = {
          resVal: 0,
          errMsg: rv,
          uid: [col.coli] // parseInt($attr(tr, 'data-reci'))
        }
      }
      if (rv && rv.resVal === 0) {
        result.resVal = 0
        result.errMsg = rv.errMsg
        result.uid = result.uid.concat(rv.uid)
        onInvalid(
          col.title,
          result.errMsg,
          $find(tr, '.coli-' + col.coli + ' [data-model-name]')[0] ?? tr
        )
      }
    }

    var objectValidation = function (validation) {
      for (var rule in validation) {
        var err = ''

        var v = validation[rule]
        if (typeof v === 'function') {
          v = v(rec, col)
          if (v && typeof v === 'object' && !v.getTime) {
            if (v.errMsg) {
              err = v
              result.resVal = 0
              result.errMsg = err.errMsg
              result.uid.push(col.coli)
              onInvalid(
                col.title,
                err.errMsg ?? __('Invalid') + ' ' + (col.field ?? ''),
                $find(tr, '.coli-' + col.coli + ' [data-model-name]')[0] ?? tr
              )
            }
            continue
          }
        }

        var displayValue = v // if it's a column name

        if (v === undefined) {
          // if the rule refers to another field by Name
          continue
        }

        var val = _v(rec, col.field)

        if (typeof v === 'string') {
          displayValue = (cols.filter(c => c.field === v)[0] || {}).title + ', ' + rec[v]
          v = rec[v]
          if (col.type === 'time') {
            if (val && val.toJSON) val = val.toJSON().split(' ')[1]
            if (v && v.toJSON) v = v.toJSON().split(' ')[1]
          }
        }

        if (!val && val !== 0) {
          if (rule === 'required' && v) err = ' requires a value'
        } else {
          // if (typeof val !== 'undefined' && val !== null && val !== '') {
          if (rule === 'eq' && !(val === v)) {
            err = ' should be equal to ' + displayValue
          } else if (rule === 'neq' && !(val !== v)) {
            err = ' should not be equal to ' + displayValue
          } else if (rule === 'gt' && !(val > v)) {
            err = ' should be greater than ' + displayValue
          } else if (rule === 'gte' && !(val >= v)) {
            err = ' should be greater than or equal to ' + displayValue
          } else if (rule === 'lt' && !(val < v)) {
            err = ' should be less than ' + displayValue
          } else if (rule === 'lte' && !(val <= v)) {
            err = ' should be less than or equal to ' + displayValue
          }
        }

        if (err !== '') {
          result.resVal = 0
          result.errMsg = err
          result.uid.push(col.coli)
          onInvalid(
            col.title,
            result.errMsg,
            $find(tr, '.coli-' + col.coli + ' [data-model-name]')[0] || tr
          )
        }
      }
    }

    if (typeof col.validation === 'object') {
      objectValidation(col.validation)
    }
  })

  if (localMessageArray.length) popInvalid('Invalid', localMessageArray.join('<br />'))

  return result
}

const defaultValidate = (grid, onInvalid, messageArray) => {
  var result = true

  Object.keys(Model.$meta(grid.dataSet).changes)
    .map(x => parseInt(x))
    .forEach(reci => {
      let tr = getTr(grid, grid.dataView[reci], false)
      result = result && grid.validateRow(tr, onInvalid, messageArray)
    })

  return result
}

const readData = (grid, rec, changedRowsOnly) => {
  changedRowsOnly = changedRowsOnly || grid.saveOnlyChangedRows

  const f = grid.fieldName || 'data'

  if (changedRowsOnly) {
    var result = grid.dataView
      .filter(
        rec => Model.hasChanges(rec) // (.deleted && !.new) // either in filteredRows OR has been deleted
      )
      .map(reci => Object.assign({}, grid.dataSet[reci]))
    _v(rec, f, result)
    return rec
  }

  _v(
    rec,
    f,
    grid.dataView
      .filter(r => !Model.$meta(r).deleted && !Model.isNewBlank(r))
      .map(r => Object.assign({}, r))
  )
  return rec
}

const getUserSettings = (grid, prefs) => {
  if (grid.userSettings) prefs[grid.id] = grid.userSettings
}

const hasFilterControls = grid => {
  grid.onOwGridDatabound = function () {
    if (grid.focusFirstRowOnPopulate) {
      delete grid.focusFirstRowOnPopulate
      grid.body.focusFirstRow()
    }
  }

  grid.filterChanged = filterControl => {
    if (filterControl.clientFilter) grid.refilter()
    // grid.renderAsync()
    // const reaction = filterControl.clientFilter ? () => grid.refilter() : () => grid.refresh()
    // setTimeout(reaction, 50)
  }

  grid.onCommandFilterChange = function () {
    setTimeout(() => grid.refresh(), 50)
  }
}

const grid6Props = {
  val(rows) {
    const grid = this

    if (typeof rows !== 'undefined') {
      if (grid.pager) grid.pager.renderAsync()

      if (rows === grid.dataSet) return // if we are repopulating with same data

      if (grid.dataSet) Model.cancelChanges(grid.dataSet) // resets the change control

      grid.state.pageChanging = true
      grid.dataSet = rows // grid.dataSet is the result

      // // append the first row to find the rowHeight
      // if (grid.dataSet.length) {
      //   const firstRow = getTr(grid, grid.dataSet[0], true)
      //   t.appendChild(firstRow)
      //   grid.rowHeight = firstRow.offsetHeight || grid.defaultRowHeight
      //   grid.body.addRowOnFocusInput.css({ height: grid.rowHeight + 'px' })
      //   firstRow.remove()
      // }
      // if (!grid.rowHeight) grid.rowHeight = grid.defaultRowHeight

      const hasFooter = grid.cols.filter(col => col.footer).length > 0
      if (hasFooter) grid.removeClass('no-footer')
      else grid.addClass('no-footer')

      grid.refilter()

      if (grid.groupBy && grid.expandAllGroups === false) {
        // grid.dataView.forEach(rec => rec.isGroup && (rec.open = false))
        // applyGroupVisibility(grid.dataView)
        expandAllGroups(grid, false)
      }

      grid.state.pageChanging = false

      grid.state.current = grid.state.current || { reci: null, coli: 0 }
      grid.state.current.reci = grid.dataView[0] ? Model.$meta(grid.dataView[0]).reci : null

      grid.renderAsync().then(() => grid.el && grid.el.focus())
      // setTimeout(() => grid.resizeGrid(), 200)
    }
    return grid.dataSet
  },

  readData(rec, changedRowsOnly) {
    return readData(this, rec, changedRowsOnly)
  },

  current(td) {
    return current(this, td)
  },

  leaveRow(tr) {
    return leaveRow(this, tr)
  },

  focusCell(td) {
    return focusCell(this, td)
  },

  pageUp() {
    const grid = this

    const rec = grid.dataSet[grid.state.current.reci]
    const index = grid.dataView.indexOf(rec)
    if (index !== -1) {
      const gridHeight = Math.max(grid.body.el.clientHeight, 300)
      const rowHeight = grid.rowHeight || grid.defaultRowHeight
      const rowsInView = Math.floor(gridHeight / rowHeight)

      let fi = Math.max(index - rowsInView, 0)
      grid.state.current.reci = Model.$meta(grid.dataView[fi]).reci
      const h = rowsInView * (grid.rowHeight || grid.defaultRowHeight)
      grid.body.el.scrollTop = grid.body.el.scrollTop - h
      grid.renderAsync().then(() => grid.resolveFocus())
    }
  },

  pageDown() {
    const grid = this
    const rec = grid.dataSet[grid.state.current.reci]
    const index = grid.dataView.indexOf(rec)
    if (index !== -1) {
      const gridHeight = Math.max(grid.body.el.clientHeight, 300)
      const rowHeight = grid.rowHeight || grid.defaultRowHeight
      const rowsInView = Math.floor(gridHeight / rowHeight)

      let fi = Math.min(index + rowsInView, grid.dataView.length - 1)
      grid.state.current.reci = Model.$meta(grid.dataView[fi]).reci
      const h = rowsInView * (grid.rowHeight || grid.defaultRowHeight)
      grid.body.el.scrollTop = grid.body.el.scrollTop + h
      grid.renderAsync().then(() => grid.resolveFocus())
    }
  },

  resolveFocus() {
    const td = current(this)

    if (td) {
      const activeEl = document.activeElement
      if (!activeEl || (activeEl !== td && activeEl.closest('td') !== td)) {
        console.warn('setting grid focus')
        focusCell(this, td)
      }
    }
  },

  getColForCell(td) {
    return $cmp(td).col
  },

  fillout() {
    return fillout(this)
  },

  resizeGrid() {
    return resizeGrid(this)
  },

  getTr(rec, createIfNull) {
    return getTr(this, rec, createIfNull)
  },

  refilter() {
    return refilter(this)
  },

  quietAddRow(rec) {
    return quietAddRow(this, rec)
  },

  newRowAllowed() {
    return newRowAllowed(this)
  },

  saveRow(tr) {
    return saveRec(this, getDataForRow(tr))
  },

  saveRec(rec) {
    return saveRec(this, rec)
  },

  saveChanges() {
    return saveChanges(this)
  },

  undeleteRow(rec) {
    return undeleteRow(this, rec)
  },

  // updates the meta with any changes, or just for field if provided
  react(...args) {
    return Model.react(this.view, ...args)
  },

  select(rec) {
    return select(this, rec)
  },

  currentRow() {
    return getTr(this, this.dataSet[this.state.current.reci], false)
  },

  currentRec() {
    const tr = this.currentRow()
    if (!tr) return
    return getDataForRow(tr)
  },

  isEditable() {
    return this.editable !== false
  },

  moveNextCell(back) {
    return moveNextCell(this, back)
  },

  hasChanges() {
    return Model.hasChanges(this.dataSet)
  },

  getData() {
    return this.dataView
  },

  validate(onInvalid, messageArray) {
    return defaultValidate(this, onInvalid, messageArray)
  },

  validateRow(tr, onInvalid, messageArray) {
    return validateRow(this, tr, onInvalid, messageArray)
  },

  getUserSettings(prefs) {
    return getUserSettings(this, prefs)
  },

  addRow(...args) {
    return addRow(this, ...args)
  },

  setHorizontalScroll(scrollLeft) {
    return setHorizontalScroll(this, scrollLeft)
  },

  addRecords(recs) {
    ;(recs || []).map(r => this.quietAddRow(r))
    this.renderAsync()
  },

  exportRecords(opt) {
    const { incHeader = false, filename = 'export' } = opt || {}
    const cols = this.cols.filter(c => c.gridCol !== false && c.field)

    const processHeader = () => {
      let line = ''
      let last = cols.length
      for (let i = 0; i < cols.length; i++) {
        line += cols[i].field
        line += i === last - 1 ? '\n' : ','
      }
      return line
    }
    const processRow = row => {
      let line = ''
      let last = cols.length
      for (let i = 0; i < cols.length; i++) {
        let v = row[cols[i].field]
        if (v instanceof Date) v = v.toJSON()
        v = v || ''
        line += v
        line += i === last - 1 ? '\n' : ','
      }
      return line
    }

    let csvFile = incHeader ? processHeader() : ''
    let rows = this.dataSet || []
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i])
    }

    let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
    if (navigator?.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', filename.concat('.csv'))
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.dispatchEvent(new MouseEvent('click'))
        document.body.removeChild(link)
      }
    }
  },

  async refresh(force) {
    if (
      !force &&
      Model.hasChanges(this.model) &&
      !(await common.confirm(__('Loading data'), __('Discard changes and continue?')))
    )
      return false

    // load up the filters, sorts, paging
    const meta = Model.$meta(this.model)
    if (meta.loadOpts) {
      meta.loadOpts.paging = this.paging
      meta.loadOpts.sorts = this.sorts
      meta.loadOpts.filter = { filters: [] }
      readServerFilterControls(this.view, '', meta.loadOpts.filter)

      meta.loadOpts.view = this.view
      return load(meta.loadOpts).then(() => true)
    }

    return false
  },

  deleteRow(rec) {
    deleteRow(this, rec)
  },

  rowEdit() {
    // nothing assigned
  }
}

/**
 * For lightweight bulk editing of up to 100K records at once with client-side virtual page swapping
 * no line or cell "edit state" changes
 * @params {string} props.id - used for saving and loading gridPrefs
 * @params {array|object} props.cols -
 * @params {boolean} props.live - will cause edited lines to be saved on row change and deletes to call to the server on delete click.
 * @params {boolean} props.sortable - can click on header to sort by that field on clientside
 * @params {boolean} props.resizable - can resize columns
 * @params {boolean} props.selectable - can select rows (not yet implemented)
 * @params {boolean} props.hasFilters -
 * @params {boolean} props.hasColFilters -
 * @params {boolean} props.hideFilterRow  - defaults to true
 * @params {boolean} props.showDeletedRows - when user deletes a row, it can still be seen but is struck through - disappears on save.
 * @params {boolean} props.indicateCurrentRow -
 * @params {boolean} props.indicateCurrentCell -
 * @params {boolean} props.saveOnlyChangedRows - when calling grid.readData, only records that have changed will be .
 * @params {boolean} props.allowColumnMenu - right click to hide-show columns
 * @params {boolean} props.allowSaveTemplate - in header context menu you can save current column sizes etc as default.
 * @params {string} props.fieldName - this is normally set using attr data-field=fieldName
 * @params {boolean} props.editable - can edit rows
 * @params {boolean} props.markChanges - indicates a row has unsaved changes on the left side
 * @params {object} props.viewdata - if you need to supply data other than the normal top viewdata
 * @params {object} props.disallowNewWhenExistingNewInvalid
 *
 * @params {object} props.paging - can be true (set to default object values) or false meaning no pager
 * @params {int} props.paging.page
 * @params {int} props.paging.pageCount
 * @params {int} props.paging.pageSize
 * @params {int} props.paging.recordCount
 * @params {int} props.paging.pageStart
 * @params {int} props.paging.pageEnd
 *
 */
const grid6 = props => {
  if (!props.id) props.id = props.fieldName || props.dsName || '0'

  const { cols } = props

  props.defaultRowHeight = 24.6

  if (typeof props.groupBy === 'string') props.groupBy = [props.groupBy]

  const viewdata = props.viewdata || props.view.qTop.viewdata || {}
  props.viewdata = viewdata
  props.userSettings = JSON.parse(JSON.stringify(_v(viewdata, 'winpref.grids.' + props.id) || {}))

  const classes = []

  if (props.resizable !== false) classes.push('resizable-columns')
  props.hasColFilters = props.hasColFilters === true
  if (props.hideFilterRow !== false) classes.push('ow-hide-filterrow') // default to not showing
  if (props.hasColFilters !== false) classes.push('ow-has-filterrow')

  props.editable = props.editable !== false
  if (props.allowNewRow === undefined) props.allowNewRow = props.editable
  if (props.tabOutNewRow === undefined) props.tabOutNewRow = props.allowNewRow !== false
  if (props.markChanges !== false) classes.push('ow-mark-changes')

  if (props.indicateCurrentRow !== false) classes.push('indicate-current-row')
  if (props.indicateCurrentCell === true) classes.push('indicate-current-cell')

  props.tag = 'div#' + props.id + '.grid6.fit.focusable.' + classes.join('.')

  const me = qc(mergeProps(grid6Props, props)).attr({ tabindex: '0' })

  me.on('resize', () => me.resizeGrid())
    .on('row-delete', e => {
      let reci = e.reci
      const grid = me
      let rec = grid.dataSet[reci]

      if (Model.$meta(rec).deleted) {
        undeleteRow(grid, rec)
        return
      }

      if (grid.live || !grid.showDeletedRows)
        return common.confirm(
          __('Delete'),
          __('Are you sure you want to delete this row?'),
          r => r && grid.deleteRow(rec)
        )

      grid.deleteRow(rec)
    })
    .on('row-new', () => {
      me.addRow()
      return false
    })
    .on('keydown', e => {
      const grid = me

      if (e.target !== me.el) return
      if (e.which === 38 || e.which === 40) {
        // up arrow, down arrow - change current Row
        const isUp = e.which === 38
        let fi = grid.dataView.indexOf(grid.dataSet[me.state.current.reci])
        let foundRow, moreToCome
        do {
          fi = fi + (isUp ? -1 : 1)
          if (fi >= 0 && fi < grid.dataView.length && !grid.dataView[fi].isGroup) foundRow = true
          moreToCome = isUp ? fi > 0 : fi < grid.dataView.length - 1
        } while (moreToCome && !foundRow)

        if (foundRow) {
          const tr = getRowCmp(me, grid.dataView[fi])
          const td = tr.kids()[0]
          grid.focusCell(td.el)
          grid.current(td.el)
          return false
        }
      }
      // F2
      if ((e.which === 13 || e.which === 113) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        if (me.state.current.reci !== null) {
          const rec = grid.dataSet[me.state.current.reci]
          grid.rowEdit(rec)
        }
      }
    })
    .bindState(() => fillout(me))

  let resize = null
  me.resizeColHandleMouseDown = (e, cell) => {
    var m_pos = e.pageX
    var origWidth = qc(cell).col.width
    resize = ({ pageX }) => {
      const newWidth = origWidth + pageX - m_pos
      let w = newWidth < 5 ? 5 : newWidth
      w = parseFloat(w)
      qc(cell).col.width = w
      _v(me.userSettings, 'cols.' + qc(cell).coli + '.width', w)
      me.totalColWidth = totalColWidth(me)
      me.renderAsync()
      return killEvent(e, false)
    }
    return killEvent(e, false)
  }
  me.on('mousemove', e => resize && resize(e)).on('mouseup', () => resize && (resize = null))

  Model.modelBind(me.view, me, me.model)

  hasFilterControls(this)

  me.state = { populating: 0, pageChanging: false, current: { coli: -1, fi: -1 } }
  me.sorts = []

  const model = me.model
  const dataSet = _v(model, me.fieldName) || []

  if (me.paging) {
    if (me.paging === true)
      me.paging = {
        page: 1,
        pageCount: 1,
        pageSize: me.pageSize || 25,
        recordCount: model.total || 0,
        pageStart: 1,
        pageEnd: 1
      }
    else {
      me.paging.recordCount = me.paging.recordCount || model.total || 0
      me.paging.pageStart = me.paging.pageStart || 1
      me.paging.pageEnd = me.paging.pageEnd || 1
    }
  }

  const meta = Model.$meta(dataSet)
  if (!meta || !meta.schema || !meta.schema.itemSchema) {
    // this shouldn't happen initModel hasn't been called!
    meta.schema = meta.schema || {}
    meta.schema.itemSchema = meta.schema.itemSchema || {}
  }

  const sch = meta.schema.itemSchema

  // this should probably be done earlier on the input schema def
  me.cols.forEach(col => {
    if (col.field) {
      const f = col.field
      if (!(f in sch)) sch[f] = {}
      if (col.type) sch[f].type = col.type

      if (!col.calc || !col.readOnly) if (col.trackChanges !== false) col.trackChanges = true

      if ('trackChanges' in col) {
        sch.reactFields = sch.reactFields || {}
        sch.reactFields[f] = col.trackChanges
        sch[f].ignore = !col.trackChanges
      }
    }
  })

  // gridCols /////
  normalizeCols(cols, me)
  if (me.buttonColumn)
    throw new Error(
      'grid.buttonColumn is no longer supported, please add your own to grid.cols using grid-col-helpers'
    )
  // buildButtonColumn(me)
  colsToGridColumns(cols, me) // check me

  me.header = gridHeader(me)
  me.body = gridBody(me)
  me.footer = gridFooter(me)

  me.kids([me.header, me.body, me.footer])

  if (me.paging) {
    me.pager = pagerBar(me)
    me.kids([...me.kids(), qc('div.ow-grid-pager', me.pager)])
  }

  me.val(dataSet)
  if (me.el) me.trigger('ow-grid-databound')

  me.addClass('size-con').on('resize', () => resizeGrid(me))

  return me
}

const sCss = [
  `

  #scope .grid6 { background-color: #fff; outline: 0 }
  #scope .grid6 .ow-grid-content { position:relative; width:100%; overflow:auto; overflow-x:auto; overflow-y:scroll; zoom:1; min-height:0; }
  #scope .grid6 .fa, 
  #scope .grid6 i.icon6 {
  font-family: 'FontAwesome';
  line-height: 1.2em;
  cursor: pointer;
}
#scope .grid6 .ow-grid-footer-wrap, 
#scope .grid6 .ow-grid-header-wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  border-style: solid;
  border-width: 0 0.077em 0 0;
  border-color: #dedee0;
  zoom: 1;
}
#scope div.ow-grid-footer, #scope div.ow-grid-header {
  padding-right: 17px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  zoom: 1;
  border-color: #ceced2;
  background-color: #f3f3f4;
  background-position: 50% 50%;
}
#scope div.ow-grid-footer {
  border: none;
  padding-right: 23px;
}
#scope .grid6 table {
  margin: 0;
  max-width: none;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  border-width: 0;
  outline: 0;
}
`,
  `
  #scope .tab_content > .grid6.fit,
  #scope .tab_content > div > .grid6.fit,
  #scope .tab_content > div.fit {
  margin: 1.23em;
}
#scope .grid6 th,
#scope .grid6 td {
  border-color: #dedee0;
  border-style: solid;
  border-width: 0 0 0.077em 0.077em;
  overflow: hidden;
  white-space: normal;
  vertical-align: top;
  font-weight: normal;
}
#scope .grid6 td {
  text-overflow: ellipsis;
}
#scope .grid6 .ow-footer-row td > span,
#scope .grid6 .ow-titlerow th > span {
  padding: 0.22em;
  line-height: 1.76em;
  white-space: normal;
  text-overflow: initial;
}
#scope .grid6 .ow-titlerow th {
  border-bottom: transparent;
}
#scope .btn6.ow-grid-btn {
  padding-right: 0;
}
#scope .grid6 > div.ow-grid-header {
  padding-right: 0 !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
#scope .grid6 div.ow-grid-header-wrap {
  border-right: none;
}
#scope .grid6 head {
  height: 2em !important;
}
#scope .grid6 tbody td:first-child,
#scope .grid6 thead th:first-child {
  border-left-width: 0;
}
#scope div.grid6 {
  border: 1px solid #dedee0;
}
#scope .grid6 tr.row td.non-editable-cell {
  background-color: #f3f3f4;
}
#scope .grid6 tr.row,
#scope .grid6 td,
#scope .grid6 tr.filterrow6 th {
  padding: 0;
}
#scope .grid6.indicate-current-cell td.gridcell.non-editable-cell.ow-current-cell > span {
  border: 1px solid rgb(77, 144, 254);
}
#scope .grid6 th > span,
#scope .grid6 td > span {
  padding: 0;
  line-height: 1.6em;
}
#scope .grid6 th > span > span,
#scope .grid6 td > span > span,
#scope .grid6 th > span.read-only-cell,
#scope .grid6 td > span.read-only-cell {
  padding-right: 3px;
  padding-left: 3px;
}
#scope .grid6 .filterrow6 input,
#scope .grid6 td input {
  border: none;
  /* height: 100%; */
  width: 100%;
  padding: 0 0.22em 0 0;
  color: #555;
  box-sizing: border-box;
}
#scope .grid6 td input { background: transparent; }
#scope .grid6 th.boolean-col,
#scope .grid6 td.boolean-col {
  text-align: center;
}
#scope .grid6 .number-col input,
#scope .grid6 td.number-col > span.read-only-cell,
#scope .grid6 th.number-col > span {
  text-align: right;
}
#scope .grid6 .number-col input:focus {
  text-align: left;
}
#scope .grid6 .ow-footer-row td {
  background-color: #f5f5f5;
}
#scope .grid6 {
  overflow-y: hidden;
  overflow-x: auto;
}
#scope .grid6 > .ow-grid-header .filterrow6 th {
  text-align: left;
}
#scope .grid6 > .ow-grid-header .ow-titlerow th {
  position: relative;
}
#scope .grid6 > .ow-grid-header th .check-all-none {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  text-decoration: none;
  padding: 0;
}
#scope .grid6 > .ow-grid-header th .check-all-none::before {
  border: none;
  text-decoration: none;
}
#scope .grid6 {
  position: relative;
}
#scope .grid6 a.col-sort::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 0.4em;
}
#scope .grid6 .ow-header .tri-bl {
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 1.8em solid #fff;
  border-right: 1.8em solid transparent;
}
#scope .grid6 .ow-header .tri-bl::after {
  font-family: 'FontAwesome';
  content: '\\f00c';
  color: #000;
  border: transparent;
  background: transparent;
  padding: 0;
  font-size: 0.9em;
  bottom: -0.5em;
  position: absolute;
  height: 100%;
  left: 0.12em;
}
#scope .grid6.indicate-current-row tbody tr.ow-current-row > td,
#scope .grid6.indicate-current-cell tbody td.ow-current-cell {
  background-color: rgba(57, 161, 232, 0.5);
}
#scope .grid6 td > span {
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

#scope .grid6 .ow-grid-btn-delete-row {
  color: rgba(204, 32, 49, 1) !important;
}
#scope .grid6 .ow-grid-btn {
  border: none;
}
#scope .grid6 input.int,
#scope .grid6 input.float,
#scope .grid6 input.number,
#scope .grid6 input.currency {
  text-align: right;
}
#scope .grid6 input.int.combo {
  text-align: left;
}
#scope .grid6.ow-mark-changes tr.ow-dirty td:first-child > span,
#scope .grid6.ow-mark-changes tr.ow-deleted-row td:first-child > span {
  border-left: 0.33em rgb(77, 144, 254) solid;
  border-right: 0.33em transparent solid;
}
#scope .grid6 tr.ow-deleted-row td > * {
  opacity: 0.5;
}
#scope .grid6 tr.ow-deleted-row * {
  text-decoration: line-through;
}
#scope .grid6 tr.row td {
  background-color: inherit;
  transition: background-color 0.5s;
}
#scope .grid6 tr.row.ow-row-invalid td {
  background-color: pink;
  transition: background-color 1s;
}
#scope .grid6 tr.row.ow-row-invalid td input {
  background-color: pink;
  transition: background-color 1s;
}
#scope .grid6.no-footer div.ow-grid-footer table  {
  display: none;
  height: 0;
}
#scope .grid6 td .ow-ctl-wrap,
#scope .grid6 th .ow-ctl-wrap {
  text-indent: 0;
  padding-top: 0;
  padding-left: 0;
}
#scope .grid6 td .ow-ctl-wrap {
  background: transparent;
}
#scope td .text6,
#scope th .text6 {
  border: none;
  box-sizing: border-box;
}
#scope .grid6 th .resize-col-handle {
  width: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
}
#scope .grid6.resizable-columns th .resize-col-handle {
  cursor: ew-resize;
  width: 0.4em;
}
#scope .grid6 td.ow-footer {
  border-top: 0;
}
#scope .grid6 .number-col input.text6 {
  text-align: right;
}
#scope .grid6 .number-col input.text6:focus {
  text-align: left;
}
`,
  `
#scope .grid6 th , 
#scope .grid6 td { 
  box-sizing: content-box;
  padding-left: 0;
  padding-right: 0; 
}
#scope .grid6 th > span, 
#scope .grid6 td > span {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0;
  min-height: 1.2em; /* for sizing */
}
`,
  `
#scope .grid6 .filterrow6 th { padding-bottom: 3px }
#scope .grid6 .ow-grid-header .filterrow6 { display: none }
#scope .grid6.ow-has-filterrow .ow-grid-header .filterrow6 {display: table-row;}
#scope .grid6.ow-hide-filterrow .ow-grid-header .filterrow6 {display: none;}
#scope .grid6 .ow-grid-header .ow-grid-top-right i { display: none; }
#scope .grid6 .ow-grid-header .ow-grid-top-right { position: absolute; right: 0; top: 0; height: 3.9em; display: inline-block; width: 1.4em; background-color: inherit; z-index: 2; text-align: right; overflow: visible;}
#scope .grid6 tr.ow-alt { background-color: #fbfbfb }
#scope .grid6 .filterrow6,
#scope .grid6 .filterrow6 th { overflow: visible; overflow-x: hidden; }
#scope .grid6 tr.colgroup th { line-height: 0; }
#scope .grid6 tr.hidden-active,
#scope .grid6 tr.hidden-active * { opacity: 0; color: #fff !important; background-color: #fff !important; border-color: #fff !important }
#scope .grid6 table { table-layout:fixed }
#scope .grid6 a.button6, 
#scope .grid6 a.btn6 { line-height: initial; }
#scope .grid6 .col-align-right input { text-align: right; padding-right: 0.33em; }
#scope .grid6 .col-align-right input:focus {
  text-align: left;
}

#scope .grid6 tr { box-sizing: content-box }
#scope .grid6 tr > td { box-sizing: content-box }
#scope .grid6 tr > td > span { box-sizing: border-box }

#scope .grid6 tr > * { line-height: 0.8em; padding-top: 0; padding-bottom: 0 }
#scope .grid6 tr > td span.static-value { margin-top: 0.2em; margin-bottom: 0.2em; display: inline-block }
/* #scope .grid6 tr > td span.ow-ctl-wrap { margin-top: 0; margin-bottom: 0 } */
#scope .grid6 tr .ow-ctl-wrap { height: 2em; line-height: 2em; padding-top: 0; padding-bottom: 0 }
#scope .grid6 tr .ow-ctl-wrap > input,
#scope .grid6 tr .ow-ctl-wrap > a { 
  text-overflow: ellipsis;
  height: 1.77em; 
  line-height: 1.77em; 
  /* padding-top: 0.077em; 
  padding-bottom: 0.077em  */
}

#scope .grid6 .fa, 
#scope .grid6 i.icon6 {
  font-family: 'FontAwesome';
  /* line-height: 1.77em; */
  cursor: pointer;
}

#scope .grid6 td.footer span.cell-liner { text-overflow: ellipsis }

#scope .grid6 tr td.footer span.cell-liner span.static-value {
  max-width: 100%;
  display: inline;
  text-overflow: ellipsis;
}

#scope .grid6 tbody tr.row td:focus-within { background-color: #fff; }
#scope .grid6 tbody tr td.non-editable-cell:focus-within, 
#scope .grid6 tbody tr.ow-group-header td:focus-within { background: transparent; background-color: inherit; }

`
  // '#scope .frozen-left { background-color: #fff; position: relative; }'
].join('')

stylesheet.addCss('grid6-css', sCss)

module.exports = { grid6 }
