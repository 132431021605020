// wireUpView5.js

const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { weekdaysArray } = require('../ow7/multiSelect7')

/**
 * provides a cleaner scoped interface for views
 * adds styles and sets up various
 * @param {object} view
 * @returns
 */
module.exports.wireUpView5 = (view = { ow: ow5 }) => {
  const { qc, html } = common.cmp

  view.ow = view.ow ?? ow5

  const { grid5, combo5, button5, footerBar, headerBar } = common.qcControls

  const result = {
    view,
    common,
    ...common.ow5,

    ...common.qcControls,

    combo5(opts) {
      opts.view = view
      return combo5(opts)
    },

    button5(opts) {
      return button5(
        typeof opts === 'string' ? { name: opts, view, command: opts } : { ...opts, view }
      )
    },

    multiSelect5(opts) {
      opts.view = view
      opts.ow5 = true
      return common.ow7.multiSelect7(opts)
    },

    weekdays5(opts) {
      opts.view = view
      opts.ow5 = true
      return common.ow7.weekdays7(opts)
    },

    inGridMultiSelect(col, model, grid, opts = {}) {
      const fieldName = col.field
      let d

      return (d = qc('div.ow-ctl-wrap.text-icon-after.ow-textbox', [
        qc('input')
          .attr({ readonly: 'true' })
          .bindState(
            () => _v(model, fieldName),
            (v, me) => (me.el.value = (_v(model, fieldName) ?? 0).map(r => r[opts.textField]))
          ),
        qc('i.fa.icon.text-item-icon', html(common.iconCodes.caretDown)).on('click', e => {
          ow.windows.openView({
            // openChildView({
            // parentView: view,
            modal: true,
            settingsLoaded: true,
            name: col.title,
            buttonText: __('Ok'),
            url: 'views/multiselect-view.js',
            width: 460,
            height: 140,
            opts: {
              col,
              model,
              fieldName,
              value: _v(model, fieldName),

              ...opts
            },
            callback($win, { result }) {
              if (!result) return
              _v(model, fieldName)
              d.trigger('ow-change')
              const g = e.target.closest('.ow-grid')
              g?.fieldReact(g.get$tr(model), fieldName, model)
              d.renderAsync()
            }
          })
        })
      ]))
    },

    inGridWeekdays5(col, rec, grid) {
      return result.inGridM2MTemplate(col, rec, grid, weekdaysArray(), 'Value', 'Text')

      // const fieldName = rec.field
      // let d

      // return (d = qc('div.ow-ctl-wrap.text-icon-after.ow-textbox', [
      //   qc('input')
      //     .attr({ readonly: 'true' })
      //     .bindState(
      //       () => _v(rec, fieldName),
      //       (v, me) => {
      //         me.el.value = weekdaysIntToArray(_v(rec, fieldName) ?? 0).map(({ Text }) => Text)
      //       }
      //     ),
      //   qc('i.fa.icon.text-item-icon', html(common.iconCodes.caretDown)).on('click', e => {
      //     ow.windows.openView({
      //       modal: true,
      //       settingsLoaded: true,
      //       name: __('Weekdays'),
      //       buttonText: __('Ok'),
      //       url: 'views/weekdays-view.js',
      //       width: 600,
      //       height: 240,
      //       value: _v(rec, fieldName), // 14 = [Mo,Tu,We] = 2 + 4 + 8
      //       callback($win, { result }) {
      //         if (!result) return
      //         _v(rec, fieldName, result.simplySchedule)
      //         d.trigger('ow-change')
      //         const g = e.target.closest('.ow-grid')
      //         g?.fieldReact(g.get$tr(rec), fieldName, rec)
      //         d.renderAsync()
      //       }
      //     })
      //   })
      // ]))
    },

    inGridM2MTemplate(col, rec, grid, list, valueField, textField) {
      let dropdown

      const index = {}
      _v(rec, col.field)?.forEach?.(x => (index[_v(x, valueField)] = true))

      return qc('div.ow-ctl-wrap.text-icon-after.ow-textbox', [
        qc('input')
          .attr({
            readonly: 'true',
            value:
              _v(rec, col.field)
                ?.map(r => _v(r, textField))
                .join(', ') ?? ''
          })
          .bindState(
            () => _v(rec, col.field),
            (v, me) => (me.el.value = v?.map(r => _v(r, textField)).join(', ') ?? '')
          ),
        qc('i.fa.icon.text-item-icon', html(common.iconCodes.caretDown)).on(
          'click',
          e =>
            (dropdown = common.ow7
              .cmenu7(e.target, {
                view,
                point: { left: e.pageX, top: e.pageY },
                content() {
                  return qc('ul', [
                    !list.length
                      ? [
                          qc('li', __('No items to display'))
                            .on('click', () => dropdown.el.remove())
                            .css({
                              whiteSpace: 'nowrap',
                              margin: '3px 6px',
                              backgroundColor: 'transparent'
                            })
                        ]
                      : list.map(r =>
                          qc('li', [
                            common.ow7
                              .check7({ label: _v(r, textField), labelRight: true })
                              .bindState(
                                () => index[r[valueField]],
                                (v, chk) => chk.val(v)
                              )
                              .on('ow-change', e => {
                                index[r[valueField]] = qc(e.target).val()
                                _v(
                                  rec,
                                  col.field,
                                  list.filter(r => index[r[valueField]])
                                )
                                const $tr = grid.el.get$tr(rec)
                                grid.el?.fieldReact($tr, col.field)
                                qc($tr[0]).renderAsync()
                              })
                              .wrap()
                              .css({ whiteSpace: 'nowrap', border: 'none' })
                          ])
                        )
                  ])
                }
              })
              .css({ minWidth: '120px' }))
        )
      ])
    },

    checklist5(opts) {
      return common.controls.qChecklist5(opts)
    },

    footerBar(opts, dsName) {
      return footerBar(opts, dsName, view)
    },

    headerBar(opts, dsName) {
      return headerBar(opts, dsName, view)
    },

    $ajax(...args) {
      const opts = typeof args[0] === 'string' ? args[1] ?? { url: args[0] } : args[0]
      opts.view = view
      opts.LRTask = true
      return $ajax(opts)
    },

    grid5(opts) {
      opts.view = view
      return grid5(opts)
    },

    collectionController5(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return ow5.collectionController5(opts)
    },

    dc5(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return ow5.dc5(opts)
    },

    styles(scope = view.qTop.el?.id ? '#' + view.qTop.el.id : '.css5') {
      return html(`<style>
/* css5 */
/* ${scope} * { float: none; } */
</style>`)
    }
  }

  view.afterInit = () => {
    const { viewWrap } = view

    // prevent filter change propagating out from the view
    // especially from filters
    viewWrap
      .addClass('ow5')
      .on('click keyup change ow-change', () => common.wait(50).then(() => viewWrap.renderAsync()))
      .on('change ow-change', e => killEvent(e))

    result.styles().renderTo(viewWrap.el)
  }
  return result
}
