const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const theme = require('../theme')

const styles = () =>
  qc(
    'style.tabstrip7-css',
    html(
      `
 .tabstrip7.fit > .tab_content {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: initial;
  height: initial;
}

 .tabstrip7 .tab_content {
  overflow-y: hidden;
}
 .tabstrip7 .tab_content {
  outline: 0;
}

 .tabstrip7.tabstrip7-wrapper { width: 100%; }
 .tabstrip7 {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}

 .tabstrip7 .tab-content.ow-state-active, 
 .tabstrip7 .tab-content { background-color: #fff; color: #515967; }

 .tabstrip7-top.fit > .tab-content {
  top: 2.31em;
  margin: 0;
  padding: 1rem;
  border: 1px solid ${theme.textboxBorderColor};
  border-radius: 4px;
  border-top-left-radius: 0;
}

.tabstrip7 .ow-tabstrip-items {
  padding: 0px;
}

.tabstrip7 .ow-tabstrip-items .ow-state-default {
  border-color: ${theme.textboxBorderColor};
  margin-right: 0.25em;
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; 
}

.tabstrip7 .ow-tabstrip-items .ow-state-active {
  background-color: #fff;
  background-image: none;
  font-weight: 600;
  border-color: ${theme.textboxBorderColor} !important;
  border-width: 1px 1px 0 1px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.tabstrip7 .ow-tabstrip-items .tab-on-top {
  z-index: 1;
}
.tabstrip7 .ow-tabstrip-items .ow-link {
  cursor: pointer;
  display: inline-block;
  border-bottom-width: 0;
  padding: 2px 6px;
}

.tabstrip7 li.tab-item:hover {  background-color: #cdd1d7; }
.tabstrip7 li.tab-item.ow-state-active:hover { background-color: #fff; }


.tabstrip7 .ow-tabstrip-items .tab-item { width: auto; min-width: 6rem; border-width: 0; text-align: center; }
.tabstrip7 .ow-tabstrip-items .tab-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top; 
} 
`
    )
  )

const tabstrip7 = opts => {
  let activeTab

  const { tabs } = opts

  const me = qc('div.tabstrip7-wrapper')

  const buildTabs = (tabs, changeTab) =>
    tabs.forEach((tab, i) => {
      const { active, content, name = '', caption } = tab

      tab.div = qc('div.fit.tab-content', [
        qc('div.tab_content', content)
          .css({ background: '#fff' })
          .bindState(
            () => tab.content,
            function () {
              this.children = tab.content
            }
          )
      ])
        .props({
          tab,
          show() {
            this.css({ display: 'block' })
          },
          hide() {
            this.css({ display: 'none' })
          }
        })
        .addClass(active ? 'ow-state-active' : '')
        .attr({ role: 'tabpanel' })
        .css({ display: active ? 'block' : 'none' })

      tab.li = qc(
        'li.tab-item.ow-state-default.' + name.concat('-tab'),
        qc('span.ow-link', caption).attr({ unselectable: 'on' })
      )
        .addClass(active ? 'tab-on-top ow-state-active' : '')
        .attr({ role: 'tab', 'aria-controls': i })
        .on('click', function () {
          changeTab(tab)
        })
    })

  const changeTab = tab => {
    if (activeTab) {
      delete activeTab.active
      activeTab.li.removeClass('ow-state-active').removeClass('tab-on-top')
      activeTab.div.hide()
    }
    activeTab = tab
    if (tab) {
      tab.active = true
      tab.li.addClass('ow-state-active').addClass('tab-on-top')
      tab.div.show()
    }

    me.renderAsync().then(() => me.trigger('ow-change', { target: me.el }, tab))
  }

  activeTab = tabs[0]
  activeTab.active = true
  buildTabs(tabs, changeTab)

  me.kids(
    qc('div.fit.tabstrip7.ow-tabstrip.tabstrip7-top', [
      styles(),
      qc(
        'ul.ow-tabstrip-items',
        tabs.map(t => t.li)
      ),
      tabs.map(t => t.div)
    ]).css({ background: 'transparent', border: '0 none' })
  )
  me.changeTab = changeTab

  me.on('command-add-row', e => {
    const gridsInTab = me.find('.k-grid, .ow-grid')

    if (!gridsInTab[0]) return console.warn('no grid found')

    if (gridsInTab.length > 1)
      console.log(
        'WARNING: More than one grid found to send AddRow event, please overide behaviour'
      )

    qc(gridsInTab[0].el).trigger('row-new', null, null, $(e.target).data('comp'))

    return false
  })

  return me
}

/*
.tabstrip7 .tab_content > div.fit {
  margin: 1.23em;
}

.tabstrip7.fit > .tab_content {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: initial;
  height: initial;
}

.tabstrip7 .tab_content.k-state-active {
  background-color: #ffffff;
  color: #515967;
}

.tabstrip7 .tab_content {
    overflow-y: hidden;
}
.tabstrip7 .tabstrip7-top.fit > .tab_content {
    top: 2.31em;
}

.tabstrip7 > .tab_content {
    margin: 0;
    padding: 1.5em;
    border: 1px solid ${theme.textboxBorderColor};
    border-radius: 0.31em;
}

.tabstrip7 > .tab_content {
    display: none;
    overflow: auto;
}

.tabstrip7 .tab_content {
  outline: 0;
}

.tabstrip7 {
  margin: 0;
  padding: 0;
  zoom: 1;
  position: relative;
}
.tabstrip7 .k-content.k-state-active { background-color: #ffffff; color: #515967; }

.tabstrip7-wrapper { width: 100%; }
.tabstrip7 .tabstrip7-top.fit > .k-content {
  top: 2.31em;
  margin: 0;
  padding: 1.5em;
  border: 1px solid ${theme.textboxBorderColor};
  border-radius: 0.31em;  
}

.tabstrip7 .ow-tabstrip-items {
  padding: 0px;
}
.tabstrip7 .ow-tabstrip-items .k-state-default {
	border-color: rgba(0,0,0,0);
	margin-right: 0.25em;
	background: #fff;
	border-top-left-radius: .31em;
	border-top-right-radius: .31em; 
}
.tabstrip7 .ow-tabstrip-items .k-state-active {
	background-color: #fff;
	background-image: none;
	font-weight: 600;
	border-color: ${theme.textboxBorderColor} !important;
	border-width: 1px 1px 0 1px !important;
	border-top-left-radius: .31em;
	border-top-right-radius: .31em;
}
.tabstrip7 .ow-tabstrip-items .k-tab-on-top {
  z-index: 1;
}
.tabstrip7 .ow-tabstrip-items .ow-link {
  display: inline-block;
  border-bottom-width: 0;
  padding: .5em .92em;
}
.tabstrip7 .ow-tabstrip-items .ow-loading { border-top: 0 none; }

.tabstrip7 .ow-tabstrip-items .k-item { width: auto; min-width: 7.7160em; border-width: 0; text-align: center; }
.tabstrip7 .ow-tabstrip-items .k-item {
  list-style-type: none;
  display: inline-block;
  position: relative;
  border-style: solid;
  border-width: 1px 1px 0;
  margin: 0 -1px 0 0;
  padding: 0;
  vertical-align: top; 
}


 */

module.exports.tabstrip7 = tabstrip7
