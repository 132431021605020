// grid-data.js

const Model = require('../data-model')
const { getTr } = require('./grid-row')

const { popSaveOK, popSaveError } = require('../../pop-box')
const { qc } = require('../../cmp/qc')
const { _v } = require('../../_v')

const removeRec = (grid, rec) => {
  const meta = Model.$meta(rec)
  if (grid.state.current.reci === meta.reci) grid.state.current.reci = null

  Model.removeRec(grid.dataSet, rec)
  grid.refilter()
}

const deleteRow = (grid, rec) => {
  Model.markDeleted(rec)
  grid.react(rec, Model.deletedFieldName(rec))

  if (grid.live) saveRec(grid, rec)

  grid.refilter()
  grid.trigger('ow-grid-change', { target: grid.el }, rec, undefined, 'delete')
  // if (row && row.el) grid.trigger('ow-grid-change', [rec, row.el, 'delete'])
}

const undeleteRow = (grid, rec) => {
  const meta = Model.$meta(rec)

  const deletedFieldName = meta.schema.deletedFieldName || 'Deleted'

  if (!grid.showDeletedRows) return // this shouldn't happen

  delete meta.deleted
  delete rec[deletedFieldName]

  grid.react(grid.view, rec, deletedFieldName)

  grid.trigger('ow-grid-change', rec, null, 'undelete')
}

const saveRows = grid => {
  var recs = {} // grid.dataSet
  grid.readData(recs, true)

  return $ajax({
    type: 'PUT',
    url: grid.dataSet.baseURL.split('?')[0] + '/update',
    data: recs,
    success: r => {
      popSaveOK(r)
      grid.refresh(true)
    },
    error: popSaveError
  })
}

const idString = (idFields, r, alwaysReturnObj = false) => {
  let id = {}
  if (idFields.length === 1 && !alwaysReturnObj) {
    id = r && _v(r, idFields[0])
  } else {
    idFields.forEach(x => (id[x] = _v(r, x)))
  }
  return typeof id === 'object' ? (id = JSON.stringify(id)) : id
}

const saveRec = (grid, rec) => {
  const meta = Model.$meta(rec)
  let reci = meta.reci

  var req = {
    url: grid.dataSet.baseURL.split('?')[0],
    data: { data: [rec] },
    success(response) {
      if (response.success === false) return popSaveError(response)
      popSaveOK(response)
      let tr = getTr(grid, grid.dataSet[reci], false)

      tr && qc(tr).trigger('ow-data-saved', response)

      var deleting = false
      if (Model.$meta(rec).deleted) {
        removeRec(grid, rec) //reci)
        deleting = true
      }

      // todo: if we have values or new ID, apply it.
      // If it was deleted, remove from the list.
      Model.initModel(rec, reci.toString(), meta.schema, Model.$meta(rec).parent)

      if (!deleting) grid.react(grid.dataSet)
    },
    error: popSaveError
  }

  if (meta.new && meta.deleted) return

  if (meta.deleted) {
    req.type = 'DELETE'
    req.url = req.url + '/' + idString([grid.dataSet.idField], rec)
    $ajax(req)
    return
  }

  if (meta.new) {
    req.type = 'POST'
    $ajax(req)
    return
  }

  if (Object.keys(meta.changes).length) {
    req.type = 'PUT'
    req.url = req.url + '/update'
    // + (!isNew ? '/' + idString([meta.idField], rec) : '')
    $ajax(req)
    return
  }
}

const saveChanges = grid => {
  if (grid.batch) {
    if (grid.validate().resVal) saveRows(grid)
    return
  }

  var changedRecs = Object.keys(Model.$meta(grid.dataSet).changes)
    .map(x => parseInt(x))
    .map(reci => grid.dataSet[reci])

  changedRecs.forEach(r => grid.saveRec(r))
}

const cancelChanges = (grid, tr) => {
  if (tr) {
    const rec = tr.model
    Model.cancelChanges(rec)
    grid.react(rec)
    // rowReact(grid, reci)
    // grid.trigger('ow-grid-change', rec, tr, 'cancel')
  } else {
    Model.cancelChanges(grid.dataSet)
    grid.react(grid.dataSet)
  }
}

module.exports = { removeRec, deleteRow, undeleteRow, saveRows, saveChanges, cancelChanges }
