const { combo6 } = require('./combo6')
const ow = require('./ow')

const getCurrentStore = () => ow.currentStore

const storeCombo6 = (url, model, fieldName = 'StoreID') => {
  const props = {
    model,
    fieldName,
    name: 'StoreID',
    label: __('Store'),
    textField: 'StoreName',
    valueField: 'StoreID',
    valueTextDisplay: true,
    required: true
  }

  if (getCurrentStore().StoreType === 2) {
    props.noFilter = true
    props.url = url // '/data/report/lookup/store'
  } else {
    props.list = [ow.currentStore]
    props.disabled = true
    props.readOnly = true
  }

  return combo6(props)
}

module.exports = { storeCombo6, getCurrentStore }
