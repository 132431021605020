// data-source.js
const { _v } = require('../_v')
const { initModel, $meta } = require('./data-model')
const { setPagerCount } = require('./grid6/grid-pager')

/**
 *
 *
 * @param {Object} opts after loading, initModel() is called and meta.loadOpts = these opts.
 * @param {string} opts.view
 * @param {string} opts.url
 * @param {Object} opts.data used for
 * @param {boolean} opts.showProgress default true
 * @param {string} opts.name - if blank, set to view.model otherwise view.model.name
 * (can be 'x.y.z').  Also passed as name into initModel()
 * @param {Object} opts.paging - standard paging object, grid6 reads this out
 * @param {Object} opts.filter - grid6 reads this out
 * @param {Object} opts.sorts - grid6 reads this out
 * @param {function} afterLoad - called everytime initialized model is the argument.
 * @returns Promise with response
 */
const load = opts => {
  if (typeof opts === 'string') opts = { url: opts }

  const view = opts.view
  if (!view) throw 'load requires opts.view'

  // opts.schema = opts.schema || {}
  opts.name = opts.name || ''

  const reqOpts = { url: opts.url, data: opts.data || {} }
  if (opts.params) Object.assign(opts.data, opts.params)
  if (opts.method) reqOpts.method = opts.method

  if (opts.filter) reqOpts.data.filter = opts.filter
  if (opts.paging) {
    reqOpts.data.skip = (opts.paging.page - 1) * opts.paging.pageSize
    reqOpts.data.limit = opts.paging.pageSize
  }
  if (opts.sorts) reqOpts.data.sorts = opts.sorts

  if (opts.showProgress !== false) view.qTop.progress()

  return $ajax(reqOpts)
    .then(response => {
      // attach to the view.model

      let parentMeta

      let name = opts.name // not the nested fullName

      if (!opts.name) view.model = response
      else {
        let parentName = opts.name.split('.').slice(0, -1).join('.')
        name = opts.name.split('.').splice(-1).join('.')
        const parentModel = _v(view, 'model' + (parentName ? '.' + parentName : ''))
        _v(view, 'model.' + opts.name, response)
        if (parentModel) {
          parentMeta = $meta(parentModel)

          if (!opts.schema && parentMeta.schema) opts.schema = parentMeta.schema[name] || {}
        }
      }

      const meta = initModel(response, name, opts.schema, parentMeta)

      if (opts.paging && 'total' in response) setPagerCount(opts.paging, response.total)

      meta.loadOpts = opts

      if (opts.afterLoad) opts.afterLoad(response)

      return response
    })
    .finally(() => opts.showProgress !== false && view.qTop.progress(false))
}

/**
 *
 * @param {Object} model
 * @param {string?} baseUrl
 * @param {function} keyFn - takes a model and returns the key which can be a string or object.
 */
const saveModel = (model, baseUrl, keyFn) => {
  const meta = $meta(model)
  const isNew = meta.new

  let url = baseUrl
  let method = 'POST'
  if (!isNew) {
    method = 'PUT'
    let key = keyFn(model)
    key = typeof key === 'object' ? JSON.stringify(key) : key
    url = url + '/' + key
  }

  return $ajax({
    method,
    url,
    data: model
  }).then(res => {
    if ('errMsg' in res) throw res.errMsg
    return res
  })
}

const reloadModel = model => load($meta(model).loadOpts)

module.exports = {
  load,
  reloadModel,
  saveModel
}

// temporary: below are the load functions from the old ow5 data controllers

// taken from dc.js
// function loadModel(id) {
//   var o = this

//   if (typeof id === 'object') id = JSON.stringify(id)

//   var data = {}

//   var url = (typeof o.opts.url === 'function' ? o.opts.url() : o.opts.url) || o.opts.baseURL

//   url = url.split('?')
//   url[0] = url[0] + (typeof id !== 'undefined' ? '/' + encodeURIComponent(id) : '')
//   url = url.join('?')

//   $ajax({
//     type: 'GET',
//     url: url,
//     contentType: 'application/json',
//     dataType: 'json',
//     data,
//     success(response) {
//       if (response.success === false) return this.error(response)
//       o.hideBusyIcon()
//       o.populate(response)
//     },
//     error(err) {
//       o.hideBusyIcon()
//       var obj = {}
//       try {
//         obj = JSON.parse(err.responseText)
//       } finally {
//         popError('ERROR', 'load returned error ' + (obj.errMsg || err.responseText))
//       }
//     }
//   })
// }

// from collectionController6.load(noConfirmation)
// function loadCollection() {
//   // if (!noConfirmation && this.hasChanges()) {
//   //   return common.confirm(
//   //     __('Refresh'),
//   //     __('Are you sure you want to discard changes and reload'),
//   //     ok => ok && me.load(true)
//   //   )
//   // }

//   var data = this.baseData || {}

//   if (dc.hasFilters) {
//     data.filter = {}
//     dc.readServerFilterControls(data.filter)
//   }

//   if (dc.sorts) {
//     data.sort = dc.sorts.map(([field, dir]) => ({
//       field,
//       dir: dir ? 'asc' : 'desc'
//     }))
//   }

//   var url = (typeof dc.url === 'function' ? dc.url() : dc.url) || dc.baseURL

//   var base = url.split('?')[0]
//   var qry = url.split('?')[1]
//     ? url
//         .split('?')[1]
//         .split('&')
//         .map(q =>
//           q
//             .split('=')
//             .map(x => decodeURIComponent(x))
//             .join('=')
//         )
//     : []

//   const { paging } = this
//   if (paging) {
//     // If a new filter is being carried out, reset the pagination to page 1.
//     if (data.filter.filters) {
//       paging.page =
//         JSON.stringify(model.previousFilters) !== JSON.stringify(data.filter.filters)
//           ? 1
//           : paging.page
//     }

//     data.limit = paging.pageSize
//     data.skip = paging.pageSize * (paging.page - 1)
//   }

//   const method = this.opts.method || 'GET'

//   url = base + (qry.length ? '?' + qry.join('&') : '')

//   let done = () => {
//     view.hideBusyIcon()
//     done = () => {}
//   }

//   $ajax({
//     type: method,
//     url: url,
//     contentType: 'application/json',
//     dataType: 'json',
//     data: JSON.parse(JSON.stringify(data)), // for dates
//     success(response) {
//       done()
//       if (paging) {
//         setPagerCount(paging, response.total)
//         dc.previousFilters = data.filter.filters // Save these filters so the next call to this method can access them.
//       }
//       if (dc.prePopulate) response.data.forEach(dc.prePopulate)
//       dc.populate(response.data || [])
//       dc.saveCancelState()
//     },
//     error(err) {
//       done()
//       var obj = {}
//       try {
//         obj = JSON.parse(err.responseText)
//       } catch (error) {
//         // null
//       }
//       var errorMessage = (obj ? obj.errMsg : err.responseText) || err.responseText
//       ow.popError('load returned error ' + errorMessage)
//     }
//   })
// }
