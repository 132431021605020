/* 

todo: use Intl instead of kendo (with polyfills?) 
OR globalisejs

Intl (ECMA2020):
const number = 123456.789
new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number) // expected output: "123.456,79 €"
// the Japanese yen doesn't use a minor unit
new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(number) // expected output: "￥123,457"
// limit to three significant digits
new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number) // expected output: "1,23,000"

new Intl.NumberFormat('en-DE', {style: 'currency', currency: 'EUR'}).format(1234.45)
"€1,234.45"
new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(1234.45)
"1.234,45 €"
new Intl.NumberFormat('en-DE', {}).format(1234.45)
"1.234,45"
new Intl.NumberFormat('de-DE', {}).format(1234.45)
"1.234,45"

*/

const { numberFormats } = require('./number-formats')

const dates = {
  buddhistCalendarOffset: 543,
  calendar: undefined,
  am: 'AM',
  pm: 'PM',
  daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  shortDaysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

  // javascript weeks start 0=Sunday
  daysOfWeekJs: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDaysOfWeekJs: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  DateFormatSetting: 'dd/MM/yyyy',
  DateTimeFormatSetting: 'dd/MM/yyyy HH:mm',
  TimeFormatSetting: 'HH:mm',

  SchedulerTimeFormatSetting: 'HH',
  SchedulerDateFormatSetting: 'ddd d/M/yyyy',

  datePatterns: {
    d: 'd/MM/yyyy',
    D: "dddd, d 'de' MMMM 'de' yyyy",
    F: "dddd, d 'de' MMMM 'de' yyyy HH:mm:ss",
    g: 'd/MM/yyyy HH:mm',
    G: 'd/MM/yyyy HH:mm:ss',
    m: "d 'de' MMMM",
    M: "d 'de' MMMM",
    s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
    t: 'HH:mm',
    T: 'HH:mm:ss',
    u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
    y: "MMMM 'de' yyyy",
    Y: "MMMM 'de' yyyy"
  }
}

// todo
let __ = s => s //

if (typeof window !== 'undefined') {
  window.__ =
    window.__ ||
    ((key, isData = false) => {
      if (ow.dev && window.cat[key] === undefined && !isData) {
        ;(window.missingTxKeys = window.missingTxKeys ?? []).push(key)
        console.log('Missing Translation key found:', key)
        window.cat[key] = key // prevent it being added again.
      }
      return window.cat[key] ?? key
    })
  __ = s => window.__?.(s) ?? s
}

const w = () => typeof window !== 'undefined' && window

const culture = (locale = w()?.ow?.locale ?? 'en-US') => ({
  ...dates,
  numberFormat: w()?.kendo?.culture().numberFormat ?? numberFormats[locale].numberFormat
})

const spliceString = (r, i) => r.slice(0, i) + r.slice(i + 1)

const formatFloatOnType = el => {
  const decimal = culture().numberFormat['.']
  const currency = culture().numberFormat.currency.symbol
  const validChars = Array.prototype.reduce.call(
    '-0123456789' + decimal,
    (t, c) => ({ ...t, [c]: 1 }),
    {}
  )

  let selectionStart = el.selectionStart,
    selectionEnd = el.selectionEnd,
    hasDecimal = false,
    hasMinus = false

  let value = el.value

  console.log('>>>>>', value, 'selection:', value.slice(selectionStart, selectionEnd))
  console.log('start:', selectionStart, 'end:', selectionEnd)

  let r = value,
    i = 0
  console.log('removed', currency, r, 'selection:', r.slice(selectionStart, selectionEnd))

  value = Array.prototype.filter
    .call(value, x => {
      if (x === '(') x = '-'
      let allow = x in validChars

      if (allow && x === decimal) {
        if (hasDecimal) allow = false // only a single decimal point
        hasDecimal = true
      }
      if (allow && x === '-') {
        if (hasMinus) allow = false // only a single minus
        hasMinus = true
      }

      if (allow) {
        i++
        return true
      }

      if (selectionStart > i) selectionStart--
      if (selectionEnd > i) selectionEnd--
      r = spliceString(r, i)

      console.log('removed', x, r, 'selection:', r.slice(selectionStart, selectionEnd))
    })
    .join('')
    .replace('(', '-')

  if (value !== el.value) {
    el.value = value
    el.selectionStart = selectionStart
    el.selectionEnd = selectionEnd
  }

  console.log('>>>>>', value, 'selection:', value.slice(selectionStart, selectionEnd))

  return value
}

module.exports.culture = culture
module.exports.dates = dates
module.exports.__ = __
module.exports.formatFloatOnType = formatFloatOnType
