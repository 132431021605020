// dataset-view.js
// This module contains functions for client-side filtering, sorting, grouping, paging and aggregate calcs
// Primarily this is used for grid6 presentation

const { $cmp } = require('../cmp/$cmp')
const { _v } = require('../_v')
const { $meta } = require('./data-model')
const { applyFilter } = require('./filters')
const { recSorter } = require('./rec-sorter')

/**
 *
 * returns a dataView array object containing items that conform to the filters
 *
 * @param {Array[object]} dataSet
 * @param {Array[object]} Array of filters { field, op, value }
 */
const filterDataSet = (dataSet, filters) => {
  const result = dataSet.filter(item => {
    let keep = true
    filters.forEach(filter => {
      if (keep === false || applyFilter(item, filter, []) === false) keep = false
    })
    return keep
  })

  return result
}

const filterRec = (d, filters, columns) => {
  let j, f
  for (j = 0; j < (filters || []).length; j++) {
    f = filters[j]
    if (applyFilter(d, f, columns) === false) return false
  }
  return true
}

/**
 * Reads the currently set filters and assigns them to an array.
 * @param {string} dsName
 * @returns {array}
 */
const readClientFilterControls = (topEl, dsName) => {
  var filters = []
  // call the filter() on each filterControl that's bound to me.

  Array.from(topEl.querySelectorAll('.ow-filter-control')).forEach(fc => {
    const cmp = $cmp(fc)
    if (!cmp.clientFilter) return
    if (dsName === '' || cmp.dsName === dsName) cmp.readFilter(filters)
  })

  // if (filters.length) {
  //   if (!filter.filters) filter.filters = filters
  //   else filter.filters = filter.filters.concat(filters)
  // }

  // dataSet.filters = filters

  return filters
}

const setGroupKey = (groupBy, rec, group = {}) =>
  groupBy
    .map(f => {
      group[f] = _v(rec, f)
      return group[f]
    })
    .map(v => {
      if (v === undefined) v = ''
      if (v === null) v = 'null'
      return v.toString()
    })
    .filter(s => s)
    .join('&')

const createGroup = (groupBy, rec) => {
  const group = {
    open: true,
    recs: [],
    isGroup: true
  }
  group.key = setGroupKey(groupBy, rec, group)
  return group
}

const findGroup = (grid, rec) =>
  grid.dataView.find(group => group.isGroup && group.recs.indexOf(rec) > -1)

const removeGroupRows = dataView => {
  let rec, i

  for (i = dataView.length - 1; i >= 0; i--) {
    rec = dataView[i]
    if (rec.isGroup) dataView.splice(i, 1)
  }
}

const buildGroupRows = (
  dataView,
  groupBy,
  showGroupHeaders = true,
  showGroupFooters = true,
  openGroupMap
) => {
  if (!Array.isArray(groupBy)) groupBy = [groupBy]

  const groups = {}

  const dv = []
  dv.dataView = dataView
  dv.groups = groups

  const addGroupFooterRecord = group => {
    if (!showGroupFooters) return
    group = Object.assign({}, group)
    group.footer = true
    dv.push(group)
  }

  let lastGroup, group, rec, i

  for (i = 0; i < dataView.length; i++) {
    rec = dataView[i]

    group = createGroup(groupBy, rec)
    group.open = openGroupMap(group.key) ?? group.open

    // is it in the same group?
    if (lastGroup && group.key === lastGroup.key) {
      group = lastGroup
    } else {
      // it's different
      if (lastGroup) addGroupFooterRecord(lastGroup) // footer row

      if (!groups[group.key]) {
        groups[group.key] = group
      } else {
        // console.warn('Reusing the existing group ', group.key)
        group = groups[group.key]
      }

      if (showGroupHeaders) dv.push(group) // headerRow
    }
    group.recs.push(rec)
    if (rec.isGroup) {
      rec.nested = true
      rec.parentGroup = group
    }
    lastGroup = group

    dv.push(rec)
  }

  if (lastGroup) addGroupFooterRecord(lastGroup)

  return dv
}

const applyGroupVisibility = dataView => {
  // filteredRecs is the result of the filters
  //   before applyingGroup
  const allGroupRows = dataView.allGroupRows || dataView

  let groupIsOpen = true
  let result = allGroupRows.filter(r => {
    if (r.isGroup) {
      groupIsOpen = (r.open && (r.parentGroup ? r.parentGroup.open : true)) || false
      return !r.parentGroup || r.parentGroup.open // if it's a nested group, we need to filter based on parent group open state
    }
    return groupIsOpen
  })

  result.groups = dataView.groups
  result.dataView = dataView.dataView
  result.allGroupRows = allGroupRows

  return result
}

const applyClientFilterGroupSort = (
  dataSet,
  filters,
  columns,
  sorts,
  groupBy,
  includeDeletes,
  opts = {}
) => {
  // let filters = readClientFilterControls(dataSet)

  const { showGroupHeaders = true, showGroupFooters = true, openGroupMap = () => undefined } = opts

  let incl,
    dataView = [],
    i,
    r

  for (i = 0; i < dataSet.length; i++) {
    r = dataSet[i]
    const meta = $meta(r)
    incl = (includeDeletes || !meta.deleted) && filterRec(r, filters, columns)
    if (incl) dataView.push(r)
  }

  dataView.dataSet = dataSet

  if (sorts) dataView.sort(recSorter(sorts))

  if (groupBy) {
    if (!Array.isArray(groupBy)) groupBy = [groupBy]
    removeGroupRows(dataView)
    dataView.sort(recSorter(groupBy.map(f => [f, 0])))
    dataView = buildGroupRows(dataView, groupBy, showGroupHeaders, showGroupFooters, openGroupMap)
    if (opts.outerGroupBy) {
      if (!Array.isArray(opts.outerGroupBy)) opts.outerGroupBy = [opts.outerGroupBy]
      dataView = buildGroupRows(
        dataView,
        opts.outerGroupBy,
        showGroupHeaders,
        showGroupFooters,
        openGroupMap
      )
    }
    dataView = applyGroupVisibility(dataView)
  }

  dataView.sorts = sorts
  dataView.filters = filters

  return dataView
}

module.exports = {
  filterDataSet,
  readClientFilterControls,
  applyClientFilterGroupSort,
  applyGroupVisibility,
  buildGroupRows,
  removeGroupRows,
  createGroup,
  setGroupKey,
  findGroup,
  recSorter
}
