const { toggleBtns6 } = require('./toggle-btns6')
const { icon6 } = require('./icons')

/**
 * Creates a cmp component representing a boolean filter for grid headers.
 * @param {object} props - Properties for boolean filter component.
 * @returns {object} - cmp boolean filter component.
 */

const booleanFilter = props => {
  const labelTrue = icon6('check').addClass('text-item-icon')
  const labelFalse = icon6('times').addClass('text-item-icon')
  props.labels = [labelTrue, labelFalse]

  const me = toggleBtns6(props)

  // override the default readFilter converting value from the display to boolean
  me.readFilter = function (filters) {
    const field = me.fieldName
    me.isSet = false
    if (me.value !== undefined) {
      filters.push({
        field,
        operator: me.op ?? 'eq',
        value: me.value === labelTrue
      })
      me.isSet = true
    }
  }

  me.wrap = () => me
  return me
}

module.exports = { booleanFilter }
