const { ow0 } = require('../ow0/ow0')
let ow = Object.create(ow0)

module.exports.ow4 = ow
ow.version = 4

ow.controls = Object.create(ow.controls || {})
ow.controls.version = 4
ow.grids = Object.create(ow.grids || {})
ow.grids.version = 4

ow.dc4 = require('./dc4').dc4
