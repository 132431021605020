const { popInvalid } = require('../../pop-box')

const { addCss } = require('../stylesheet')
const { combo6 } = require('../combo6')
const { text6 } = require('../text6')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')
const { formatString } = require('../../ctl-format')

/**
 * Function to search for a value in an array and return it (or a fall back value).
 * Accepts three parameters.
 * If the first parameter is a non-number, or not positive, it is an invalid value, and function will return the third parameter.
 * Otherwise, search through the second parameter--if the first parameter is found, it is a valid value, and will be returned.
 * Otherwise return the third parameter.
 * @param {any} value - Can be of any type.
 * @param {array} testList - Must be an array of objects, each containing a property named 'Value.'
 * @param {number} validNumber - Must be a non-zero positive number.
 */
const listSearch = (value, testList, validNumber) => {
  if (typeof value !== 'number' || value <= 0) return validNumber
  function hasValue(obj, key, value) {
    return key in obj && obj[key] === value
  }
  const isValidValue = testList.some(function (selection) {
    return hasValue(selection, 'Value', value)
  })
  if (isValidValue) {
    return value
  } else {
    return validNumber
  }
}

const pagerButton = (grid, title, cmd, iconCode) =>
  qc('a.pager-nav', html(iconCode))
    .attr({
      'aria-label': title,
      title: title,
      class: 'btn-pager-' + cmd,
      tabindex: '-1'
    })
    .props({ grid, cmd })
    .on('click', e => {
      const { paging } = grid
      if (cmd === 'first') {
        if (paging.page > 1) {
          paging.page = 1
          grid.refresh()
        }
        return killEvent(e, false)
      }
      if (cmd === 'prev') {
        if (paging.page > 1) {
          paging.page--
          grid.refresh()
        }
        return killEvent(e, false)
      }
      if (cmd === 'next') {
        if (paging.pageCount > paging.page) {
          paging.page++
          grid.refresh()
        }
        return killEvent(e, false)
      }
      if (cmd === 'last') {
        if (paging.pageCount > paging.page) {
          paging.page = paging.pageCount
          grid.refresh()
        }
        return killEvent(e, false)
      }
    })

const pagerBar = grid => {
  const me = qc('div.pager-wrap').props({ grid })
  grid.pager = me

  const pageSizeCombo = combo6({
    list: [25, 50, 100, 200, 500, 1000, 2000].map(x => ({ Text: x.toString(), Value: x })),
    isSelect: true,
    listWidth: 1
  })
    .on('ow-change', function () {
      const { paging } = grid
      paging.pageSize = pageSizeCombo.val()

      //paging.pageSize must be a positive non-zero quantity, and a valid selection in the number-per-page combobox. If not, non-sensical values will be displayed in the pager (NaN, infinity, etc).
      const validPageSize = listSearch(paging.pageSize, pageSizeCombo.list, pageSizeCombo.value)
      if (validPageSize !== paging.pageSize) {
        popInvalid(
          'Invalid page size.',
          'Continuing with most recent valid selection for number-per-page.',
          5000
        )
        paging.pageSize = validPageSize
      }

      paging.page = Math.floor(paging.pageStart / paging.pageSize) + 1 // Ensure changing num per page continues to display roughly the same records the user is already viewing.
      grid.refresh()
    })
    .bindState(function () {
      this.val(grid.paging.pageSize)
    })
    .addClass('page-size')
    .wrap()
    .css({ width: '6em', display: 'inline-block', borderRadius: '0.31em', margin: '0 0.4em' })

  const pageTextbox = text6({
    width: '4em',
    type: 'int',
    label: __('Page')
  })
    .addClass('page-x-of')
    .on('change', () => {
      grid.paging.page = pageTextbox.val()
      grid.refresh()
    })
    .bindState(function () {
      this.attr(grid.paging.page)
    })

  me.kids([
    pagerButton(grid, __('Go to the first page'), 'first', '&#xf100'),
    pagerButton(grid, __('Go to the previous page'), 'prev', '&#xf104'),
    qc('span.pager-input', __('Page')),
    pageTextbox
      .bindState(function () {
        grid.paging.page = grid.paging.page || 1
        this.attr({ value: grid.paging.page })
      })
      .wrap(),
    qc('span.of-y-pages').bindState(function () {
      this.kids([__(' of ') + grid.paging.pageCount])
    }),
    pagerButton(grid, __('Go to the next page'), 'next', '&#xf105'),
    pagerButton(grid, __('Go to the last page'), 'last', '&#xf101'),
    qc('span.pager-sizes', [pageSizeCombo, __('per page')])
      .css(`font-family: 'Noto Sans', sans-serif;
    line-height: 2em;
    white-space: normal;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    color: #535b6a;
    margin: 0;
    box-sizing: content-box;
    padding: 0 1.4166em;
    display: inline-block;
    padding-top: 0.077em;
    height: 2.1em;`),
    qc('span.pager-info')
      .css('position: absolute; right: 6px; top: 8px;')
      .bindState(function () {
        this.kids(
          [
            formatString(grid.paging.pageStart, 'n0'),
            __('to'),
            formatString(grid.paging.pageEnd, 'n0'),
            __('of'),
            formatString(grid.paging.recordCount, 'n0'),
            __('items')
          ].join(' ')
        )
      })
  ])

  return me
}

addCss(
  'grid-pager-css',
  `
  #scope .pager-wrap > * {
    height: 100% ! important;
    vertical-align: middle;
    margin-left: 3px;
    margin-right: 3px;
    display: inline-block;
  }
  #scope .pager-wrap a.pager-nav {
    display: inline-block;
    border-radius: 1.0833em;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ceced2;
    font-family: 'FontAwesome';
    font-size: 2em;
    color: #515967;
    height: 1em;
    vertical-align: middle;
    width: 1em;
    box-sizing: content-box;
    margin-left: 2px;
    line-height: 1em;
  }
  #scope div.pager-wrap {  
  font-size: 0.9em;
  margin: 0;
  display: block;
  background-repeat: repeat;
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  border-style: solid;
  line-height: 2em;
  padding: .333em 0 .333em .25em;
  white-space: normal;
  border-width: 1px 0 0;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background-position: 50% 50%;
  background-color: #f3f3f4;
  color: #535b6a;
  box-shadow: none;
  border-color: #ceced2;
  border-color: #dedee0;
  border-top: 0;
}

#scope .pager-wrap .ow-link:hover {
  color: #263248;
  border-color: rgba(0, 0, 0, 0.75);
  background-color: #bbbbc6;
}

#scope .pager-wrap .text6,
#scope .pager-wrap .ow-link {
  border-color: #ceced2;
}

#scope .pager-wrap.k-state-selected {
  background: #2252ae;
  color: #fff;
  border-color: #515967;
}

#scope .pager-wrap > .pager-input {
  margin-left: 0;
  padding-left: 1.4166em;
}
#scope .pager-wrap > .of-y-pages {
  margin-right: 0;
  padding-right: 1.4166em;
}
`
)

const setPagerCount = (paging, count) => {
  paging.recordCount = count
  paging.pageCount = count ? Math.trunc(1 + count / paging.pageSize) : 0
  paging.pageStart = 1 + (paging.page - 1) * paging.pageSize
  paging.pageEnd = Math.min(paging.pageStart + paging.pageSize - 1, count)
  return paging
}

module.exports = {
  pagerBar,
  setPagerCount
}
