const { renderContent, renderAsync, renderTo } = require('./cmp-renderer')
const { contentToString } = require('./contentToString')

/**
 * Attaches methods to an array of elements, which can be used to render all elements to the page.
 *
 * @param {array} a - array of Cmp Objects
 */
const cmpCollection = a => {
  if (!Array.isArray(a)) a = [a]
  a.toString = renderScope => contentToString(a, renderScope)
  a.render = renderScope => renderContent(a, renderScope)
  a.renderAsync = () => renderAsync(a)
  a.renderTo = parentEl => renderTo(a, parentEl)
  return a
}

module.exports.cmpCollection = cmpCollection
