// popup.js for launching other views for selecting values in a control
const { react } = require('./data-model')
const { $cmp } = require('../cmp/$cmp')
const { _v } = require('../_v')
const { $hasClass } = require('../no-jquery')

const openPopUpFactory = el => {
  const combo = $cmp(el)
  const view = combo.view

  return function () {
    if ($hasClass(el.parentElement, 'ow-disabled') || $hasClass(el, 'ow-disabled')) return

    let popUp = typeof combo.popUp === 'function' ? combo.popUp(el) : combo.popUp
    popUp = Object.create(popUp)

    popUp.defaultCallback =
      popUp.defaultCallback ||
      function (cbTop, viewdata) {
        combo.popUpOpen = false
        view.qTop.toFront()
        if (viewdata.result) {
          combo.popUpResult = viewdata.result // if it needs more info.
          let v = viewdata.result
          if (popUp.fieldName) v = _v(viewdata.result, popUp.fieldName)
          if (combo.selectItem) combo.selectItem(viewdata.result)
          if (combo.model) {
            _v(combo.model, combo.fieldName, v)
            react(view, combo.model, combo.fieldName)
          }
          view.qTop.toFront()
        }
      }
    popUp.callback = popUp.callback || popUp.defaultCallback
    popUp.record = combo.val()
    popUp.result = null
    popUp.mode = popUp.mode || 'select'
    popUp.userRole = popUp.userRole || view.qTop.viewdata.userRole

    combo.popUpOpen = true
    ow0.windows.openView(popUp)
  }
}

module.exports = { openPopUpFactory }
