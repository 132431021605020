const { html } = require('../cmp/html')
const { qc } = require('../cmp/qc')
const { iconCodes } = require('../icon-codes')
const theme = require('../theme')
const { addCss } = require('./stylesheet')

const icon = (code, attrs = {}) => i(code, { attrs })

const i = code => qc('i.icon.icon6', html(code))

/**
 *
 * @param {object|string} props or iconName
 * @param {string} props.iconName eg. 'star' see iconCodes
 */
const icon6 = props => {
  if (typeof props === 'string') props = { iconName: props }

  const { iconName, ...props1 } = props
  props = props1

  return i(iconCodes[iconName] || html('Icon: ' + iconName + ' not found')).props(props)
}

addCss(
  'icons-css',
  `
#scope .icon6.text-item-icon,
#scope .text-item-icon {
  color: ${theme.iconBlue};
}
#scope i.icon6 {
  font-family: FontAwesome;
  font-size: 1.4em;
  padding: 0 0.3em;
  color: ${theme.iconBlue};
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
}
#scope .icon.red-icon, 
.icon6.red-icon  {
  color: ${theme.iconRed} !important
}
#scope .icon.blue-icon, 
#scope .icon6.blue-icon {
  color: ${theme.iconBlue} !important
}
#scope .icon.black-icon, 
#scope .icon6.black-icon {
  color: #666 !important
}`
)

module.exports = { icon, i, icon6 }
