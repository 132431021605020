const { addCss } = require('../add-css')
const { qc } = require('../cmp/qc')
const theme = require('../theme')
const { rs } = require('./rs7')

/**
 * Creates a cmp component representing a toggle group, containing an arbitrary number of buttons.
 * @param {object} props - Properties for toggle group component.
 * @property {string[]} props.labels - Array of strings, each representing the label for each toggle button. Specify a blank string to represent no label. Order array elements in order you want buttons to display (left to right).
 * @returns {object} - cmp toggle group component.
 */
const toggleBtns7 = opts => {
  let value // = opts.labels.map(() => false)

  if (opts.value) value = opts.value

  const me = qc(
    'span.ow-ctl-wrap.ow-toggle-btns7',
    opts.labels.map((label, i) => {
      const btn = qc('a.toggle-btn7', label)
        .attr({ href: '#' })
        .bindState(
          () => value,
          v => (i === v ? btn.addClass('on') : btn.removeClass('on'))
        )
        .on('click', () => me.val(i))

      return btn
    })
  ).props({
    val(v) {
      if (arguments.length)
        if (value !== v) {
          value = v
          me.renderAsync()
        }
      return value
    }
  })

  me.wrap = () => me
  me.rs = () => opts.rs ?? rs({ ...opts }, me)

  return me
}

const styles = () => `
.grid7 .filterrow7 span.ow-toggle-btns7.ow-ctl-wrap {
  border: none;
  margin:0;
  display: inline-block;
}
a.toggle-btn7 {
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.textboxBorderColor};
  line-height: inherit;
  display: inline-block;
  padding: 0 0.25rem;
  color: ${theme.textboxColor};
  border-radius: ${theme.borderRadius};
  min-width: 1rem;
  text-align: center;
}
a.toggle-btn7.on i.icon.icon6.text-item-icon {
  color: ${theme.iconBlue};
}
a.toggle-btn7:hover {
  color: ${theme.highlightText};
  /* border-color: ${theme.highlightText}; */
  background-color: ${theme.buttonColor};
  text-decoration: underline;
}
a.toggle-btn7:focus {
  border-color: silver !important;
}
a.toggle-btn7.on {
  font-weight: bold;
  border: solid 1px ${theme.iconBlue};
  border-color: ${theme.highlightText};
  background-color: #fff;
}
a.toggle-btn7.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
.resource_set .toggle-btn7-wrap {
  padding-left: 0;
}
.resource_set > span > a.toggle-btn7 {
  line-height: inherit;
  padding: 0;
}
`
addCss('css-ow-toggle-btns7', styles())

module.exports = { toggleBtns7 }
