// grid-header.js
const { check6 } = require('../check6')
const { openContextMenu } = require('../cmenu6')

const { gCols, totalColWidth } = require('./grid-cols')

const { icon6 } = require('../icons')
const { addCss } = require('../stylesheet')
const { colFilterCell } = require('./grid-filter')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')
const { $cmp } = require('../../cmp/$cmp')
const { $find } = require('../../no-jquery')
const { _v } = require('../../_v')
const theme = require('../../theme')

const gridHeader = grid => {
  const me = qc({
    tag: 'div.ow-grid-header', // .k-grid-header',
    grid,
    toggleFilterRow() {
      this.grid.toggleClass('ow-hide-filterrow')
      this.grid.fillout()
      this.grid.resizeGrid()
    }
  })
    .on('click', e => {
      const { grid } = me
      const { sorts } = grid

      let that = e.target.classList.contains('col-sort')
        ? e.target
        : $find(e.target, '.col-sort')[0]

      if (!that) return killEvent(e)

      var col = $cmp(that.closest('th,td')).col

      // const iconDir = ['fa-caret-up', 'fa-caret-down']

      that.classList.remove('fa-caret-up')
      that.classList.remove('fa-caret-down')

      sorts.reverse()

      if (sorts.length && sorts[sorts.length - 1][2] === col.coli) {
        const asc = !sorts[sorts.length - 1][1]
        if (!asc) {
          sorts.pop()
        } else {
          // set to desc
          sorts[sorts.length - 1][1] = 1
          that.classList.add('fa-caret-down')
        }
      } else {
        let f = col.sortOnField
        if (!f) {
          if (col.calc) f = col.calc
          else f = col.field
        }

        // default is asc = 0
        sorts.push([f, 0, col.coli])
        that.classList.add('fa-caret-up')
        if (sorts.length > 3) sorts.splice(-1)
      }

      sorts.reverse()

      if (grid.paging) grid.refresh()
      else grid.refilter()
    })
    .on('context-menu', e => {
      if (!grid.allowColumnMenu) return

      openContextMenu(e.target, {
        view: grid.view,
        point: {
          left: e.pageX, // - $offset(p).left,
          top: e.pageY // - $offset(p).top
        },
        setWidth: false,
        content() {
          let res = qc(
            'ul',
            gCols(grid.cols)
              .filter(r => r.field)
              .map(r => {
                const chk = check6({
                  value: !r.hidden,
                  disabled: r.isMandatory ? true : false,
                  fieldName: 'col-' + r.coli,
                  label: r.title,
                  labelRight: true
                }).on('click', () => hideShowColumn(grid, r.coli, chk.val()))

                return qc('li', chk.wrap())
              })
          )
          if (grid.allowSaveTemplate)
            res.push(
              qc(
                'li',
                qc('a.menu-item', __('Set Default Template'))
                  .attr({ 'data-command': 'defaulttemplate', href: '#' })
                  .on('click', () => grid.trigger('command-defaulttemplate'))
              )
            )
          return res
        }
      })
      e.preventDefault()
      return false
    })

  const gridCols = gCols(grid.cols)

  const setHeight = function (h) {
    this.css({ height: h + 'px' })
  }
  const setWidth = function (w) {
    this.css({ width: w + 'px' })
  }

  const calcWidth = () =>
    Math.max(
      12,
      grid.header.el
        ? grid.header.el.offsetWidth - grid.header.el.children
          ? grid.header.el.children[0].offsetWidth
          : 0
        : 12
    )

  me.topRightFilterIcon = qc(
    'span.ow-grid-top-right',
    icon6('filter').on('click', () => grid.header.toggleFilterRow())
  )
    .bindState(() => Math.max(22.6, grid.header.el.offsetHeight - 1), setHeight)
    .bindState(calcWidth, setWidth)

  const titleRow = qc(
    'tr.ow-titlerow',
    gridCols.map(col => drawColHeader(grid, col))
  )

  const filterRow = qc('tr.filterrow6', [
    grid.hasColFilters === false ? '' : gridCols.map(col => colFilterCell(grid, col))
  ])

  const gridHeaderWrap = qc(
    'div.ow-grid-header-wrap',
    qc('table', qc('thead', [titleRow, filterRow])).bindState(
      () => grid.totalColWidth,
      function (w) {
        this.css({ width: w.toString() + 'px' })
      }
    )
  )
    .attr({ 'data-role': 'resizable' })
    .on('scroll', () => grid.setHorizontalScroll(gridHeaderWrap.el.scrollLeft))

  me.kids([gridHeaderWrap, me.topRightFilterIcon])

  return me
}

const drawColHeader = (grid, col) => {
  if (col.gridCol === false) return ''

  let title = col.title || ''
  if (grid.sortable && col.sortable !== false)
    title = qc('a.col-sort', { 'data-coli': col.coli, tabindex: -1 }, title)

  const resizeHandle = qc('div.resize-col-handle')
    .attr({ draggable: 'false' })
    .on('mousedown', e => grid.resizeColHandleMouseDown(e, cell))

  // e => {
  //   let m_pos = e.pageX
  //   let origWidth = cell.col.width

  //   let winCon = grid.view.qTop

  //   let prevMousemove = winCon.onMousemove
  //   let prevMouseup = winCon.onMouseup
  //   let endDrag = () => {
  //     winCon.onMousemove = prevMousemove // return state
  //     winCon.onMouseup = prevMouseup // return state
  //     endDrag = () => {}
  //   }

  //   let adjust = setColumnWidth

  //   winCon.onMousemove = function (e) {
  //     if (!e.buttons) endDrag()
  //     var newWidth = origWidth + e.pageX - m_pos
  //     if (newWidth < 5) newWidth = 5
  //     adjust(grid, cell.el, newWidth)
  //     return killEvent(e, false)
  //   }

  //   winCon.onMouseup = () => {
  //     // warn('RESIZE: ' + origWidth + ' -> ' + e.pageX - m_pos)
  //     endDrag()
  //     return killEvent(e, false)
  //   }

  //   return killEvent(e, false)
  // })

  let cell = qc(
    'th.ow-header',
    qc('span.cell-liner', [
      qc('span', title),
      col.checkAllNone
        ? qc('a.check-all-none.on', qc('span.tri-bl'))
            .attr({ tabindex: '-1' })
            .on('click', e => {
              grid.view.qTop.progress()
              setTimeout(() => {
                const dv = grid.groupBy
                  ? grid.dataView.allGroupRows.filter(r => !r.isGroup)
                  : grid.dataView
                const allOn = dv.find(r => !r[col.field]) ? true : false

                let i, r
                for (i = 0; i < dv.length; i++) {
                  r = dv[i]
                  if (!r[col.field] !== !allOn) {
                    r[col.field] = allOn
                    grid.react(r, col.field)
                  }
                }

                grid.view.qTop.progress(false)
                grid.body.renderAsync()
              }, 1)
              return killEvent(e, false)
            })
        : '',
      resizeHandle
    ])
  )
    .attr({ scope: 'col', role: 'columnheader' })
    .props({ col, grid })
    .bindState('col.width', w => cell.css({ width: w.toString() + 'px' }))
    .bindState('col.hidden', hidden => cell.css({ display: hidden ? 'none' : undefined }))

  if (col.header && col.header.attrs) cell.attr(col.header.attrs)
  if (col.headerAlign || col.align) cell.css({ textAlign: col.headerAlign || col.align })

  // if ('type' in col) cell.addClass(col.type + '-col')
  const dataType = col.type || col.header.type || ''
  if (dataType) {
    if (
      dataType === 'number' ||
      dataType === 'int' ||
      dataType === 'integer' ||
      dataType === 'float' ||
      dataType === 'currency'
    )
      cell.addClass('number-col')
    else cell.addClass(dataType + '-col')
  }

  if ('coli' in col) cell.addClass('coli-' + col.coli).attr({ 'data-coli': col.coli })

  return cell
}

const readServerFilterControls = (view, dsName, filter) => {
  var filters = []
  // call the filter() on each filterControl that's bound to me.

  Array.from(view.qTop.el.querySelectorAll('.ow-filter-control')).forEach(fc => {
    const cmp = $cmp(fc)
    if (cmp.clientFilter) return
    if (dsName === '' || cmp.dsName === dsName) cmp.readFilter(filters)
  })

  if (filters.length) {
    if (!filter.filters) filter.filters = filters
    else filter.filters = filter.filters.concat(filters)
  }

  return filters
}

const coliByName = (cols, name) => {
  for (var i = 0; i < cols.length; i++) if (cols[i].field === name) return cols[i].coli
  return -1
}

const hideShowColumn = (grid, coli, show = false) => {
  if (typeof coli === 'string') coli = coliByName(grid.cols, coli)
  var col = grid.cols.find(c => c.coli === coli)
  col.hidden = !show
  grid.totalColWidth = totalColWidth(grid)
  grid.renderAsync()
  // update user settings
  _v(grid, 'userSettings.cols.' + coli + '.hidden', show ? 0 : 1)
}

addCss('grid-header-css', {
  '#scope .grid6 th.ow-header': {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'left'
  },
  '#scope .grid6 th.ow-filter-col': {
    position: 'relative',
    paddingLeft: '0',
    paddingRight: '0'
  },
  '#scope .grid6.ow-has-filterrow .ow-grid-header .ow-grid-top-right i': {
    display: 'inline-block',
    fontSize: '1em',
    color: theme.iconBlue,
    overflow: 'visible',
    position: 'absolute',
    right: '5px',
    top: '5px'
  },
  '#scope .grid6 .filterrow6 .ow-ctl-wrap': {
    marginRight: ' 1.6em',
    marginLeft: '0.12em',
    marginBottom: '0.12em',
    position: 'relative',
    overflow: 'visible',
    border: '#d7d7d7 1px solid',
    borderRadius: '0.17em',
    boxSizing: 'border-box'
  },
  '#scope .grid6 .filterrow6 .i-filter': {
    fontFamily: '"FontAwesome"',
    lineHeight: '1.2em',
    fontSize: '1em',
    cursor: 'pointer',
    color: '#aaa',
    position: 'absolute',
    right: '0',
    top: '0.3em',
    padding: '0.22em 0',
    paddingRight: '0.44em'
  },
  '#scope .grid6 .filterrow6 .i-filter.isset': {
    color: theme.iconBlue,
    fontWeight: '800'
  }
})

module.exports = {
  drawColHeader,
  gridHeader,
  readServerFilterControls
}
