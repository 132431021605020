const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { isDate } = require('../js-types')
const { addCss } = require('../add-css')
const { qCalendar } = require('../controls/q-calendar')
const { ctlFormatters, ctlParsers } = require('../ctl-format')
const dates = require('../dates')
const { sameDateValue } = require('./sameDateValue')
const { renderClock } = require('./time7')

const datetime7 = opts => {
  opts.edType = 'datetime' // for use in ow4
  const me = input7(opts)

  me.setTime = timeString => {
    const v = me.val() ?? new Date()
    let [hh = '00', mm = '00', ss = '00'] = timeString.split(':')
    v.setHours(parseInt(hh))
    v.setMinutes(parseInt(mm))
    v.setSeconds(parseInt(ss))
    me.val(v)
    me.trigger('ow-select')
    me.timeOpen = false
    me.renderClock()
  }

  let calendarOpen = false
  let dd
  me.closeCalendar = () => {
    calendarOpen = false
    if (dd) dd.el.remove()
  }

  const hasFocus = () => {
    const activeEl = document.activeElement

    return (
      activeEl === me.el ||
      activeEl?.parentElement === me.el?.parentElement ||
      activeEl === me.el?.parentElement
    )
  }

  const renderCalendar = async () => {
    const win = me.el.closest('.win-con')
    const ddParent = win?.parentElement ?? document.body

    if (dd) {
      if (calendarOpen) {
        me.closeTime()
        if (!dd.el?.parentElement) dd.renderTo(ddParent)
        return
      }
      return dd.el?.parentElement && dd.el.remove()
    }
    dd = qc('ul.calendar.dropdown')

    const cal = qCalendar(d => {
      let v = me.val()
      const timePart = v ? v.getTime() - new Date(v).removeTime().getTime() : 0
      me.val(new Date(d.getTime() + timePart))
      me.trigger('ow-select', d)
      me.closeCalendar()
      dd.renderAsync()
    }, me.val())

    dd.kids(cal)
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(() => {
        // set position again
        // vertical position
        // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
        const viewTop = no$(ddParent).offset().top
        const topFromBody = no$(me.wrap().el).offset().top + no$(me.wrap().el).offset().height
        const topFromView = topFromBody - viewTop

        const height = Math.max(no$(dd.el).offset().height, 210)
        const floatUp = topFromBody + height > document.body.scrollHeight

        dd.css({
          left: no$(me.wrap().el).offset().left - no$(ddParent).offset().left + 'px',
          top: floatUp
            ? topFromView - (no$(dd.el).offset().height + no$(me.wrap().el).offset().height) + 'px'
            : topFromView + 'px'
        })
      })

    if (calendarOpen) dd.renderTo(ddParent)
  }

  opts.ctlFormatter = ctlFormatters.datetime
  opts.ctlParser = ctlParsers.datetime

  me.timeOpen = false
  me.timedd = false
  me.closeTime = () => {
    me.timeOpen = false
    if (me.timedd) me.timedd.el?.remove()
  }

  const _renderClock = renderClock(me)
  me.renderClock = () => {
    if (calendarOpen && me.timeOpen) me.closeCalendar()
    return _renderClock()
  }

  me.props({
    val(v, model, populating) {
      const isOw4Read = v === undefined && model

      if (!isOw4Read && arguments.length > 0) {
        const s = !v ? '' : ctlFormatters.datetime(isDate(v) ? v : new Date(v), me.opts.format)

        me.value(s)

        let hasChanged = false
        if (me.model && me.opts?.fieldName && !sameDateValue(_v(me.model, me.opts?.fieldName), v))
          hasChanged = true
        if (s !== me.value() && me.el !== document.activeElement) me.value(s)
        me.model && me.opts?.fieldName && _v(me.model, me.opts?.fieldName, v)
        hasChanged && !populating && me.trigger('ow-change')
      }
      return ctlParsers.datetime(dates.resolveDateTime(me.value(), true), me.opts.format)
    },

    resolve() {
      if (me.el.value) {
        var v = dates.resolveDateTime(me.value(), true)
        if (me.value() !== v) me.value(v)
      }
    }
  })
    .props({ model: opts.model ?? {} })
    .addClass('datetime7')
    .on('keydown', e => {
      // tab
      if (e.which === 9) {
        // to resolve on tab
      }

      // shift + enter
      if (e.which === 13 && calendarOpen) {
        calendarOpen = false
        me.timeOpen = false
        me.renderClock()
        renderCalendar()
        return
      }
      // shift + enter
      if (e.which === 13 && e.shiftKey) {
        calendarOpen = !calendarOpen
        renderCalendar()
        return
      }

      // escape
      if (e.which === 27) {
        calendarOpen = false
        me.timeOpen = false
        renderCalendar()
        me.renderClock()
        return
      }

      // downarrow
      if (e.which === 40) {
        renderCalendar()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        renderCalendar()
        e.preventDefault()
        return false
      }
    })
    .on(
      'init',
      (e, el) =>
        (el.requestTabOut = function () {
          me.resolve()
          return true
        })
    )
    .on('input', () => renderCalendar())
    .on('keydown', () => {
      var el = me.el
      el.prevValue = el.value
    })
    .on('keyup', e => {
      var el = me.el
      // backspace
      if (e.which === 8) return

      // delete
      if (e.which === 46) {
        var charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
        if ('/-.'.indexOf(charDeleted) > -1) return
      }

      if (el.value !== el.prevValue) {
        var x = el.selectionEnd
        var caretAtEnd = x === el.value.length
        var v = dates.resolveDateTime(el.value, false, undefined, { position: el.selectionEnd })
        if (v.indexOf('|') > -1) {
          x = v.indexOf('|')
          v = v.split('|').join('')
        }
        if (el.value !== v) el.value = v
        el.prevValue = v
        if (caretAtEnd) return
        el.selectionStart = x
        el.selectionEnd = x
      }
    })
    // .bindState(() => {
    //   if (calendarOpen && !hasFocus()) {
    //     console.log('closing calendar not focused')
    //     calendarOpen = false
    //   }
    // })
    // .bindState(() => {
    //   if (me.timeOpen && !hasFocus()) {
    //     console.log('closing clock not focused')
    //     me.timeOpen = false
    //     me.renderClock()
    //   }
    // })
    .bindState(
      () => calendarOpen,
      v => v && (dd ?? renderCalendar())
    )
    .bindState(
      () => me.timeOpen,
      v => v && (me.timedd ?? me.renderClock())
    )
    .bindState(
      () => me.val(),
      () => calendarOpen && renderCalendar()
    )
    .bindState(
      () => me.val(),
      () => me.timeOpen && me.renderClock()
    )

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  me.wrap()
    .addClass('datetime7 text-icon-after text-2icon-after ow-datetime-wrap')
    .on('focusout', e => {
      if (e.target !== me.el) return
      setTimeout(() => {
        if (hasFocus()) return
        calendarOpen = false
        renderCalendar()
        me.timeOpen = false
        me.renderClock()
        me.renderAsync()
      }, 100)
    })
    .kids([
      me,
      icon('calendar')
        .addClass('calendar-icon')
        .bindState(
          () => me.disabled,
          function (v) {
            this.css({
              cursor: !v ? 'pointer' : undefined,
              color: v ? '#ddd' : undefined
            })
          }
        )
        .css({ textAlign: 'center' })
        .on('click', e => {
          if (me.disabled) return
          me.el.focus()
          calendarOpen = !calendarOpen
          if (me.timeOpen && calendarOpen) me.closeTime()
          renderCalendar()
          // me.renderAsync()
          return killEvent(e, true) // otherwise the setting focus will not work
        }),
      icon('clock')
        .addClass('clock-icon')
        .bindState(
          () => me.disabled,
          function (v) {
            this.css({
              cursor: !v ? 'pointer' : undefined,
              color: v ? '#ddd' : undefined
            })
          }
        )
        .css({ textAlign: 'center' })
        .on('click', e => {
          if (me.disabled) return
          me.el.focus()
          me.timeOpen = !me.timeOpen
          if (me.timeOpen && calendarOpen) me.closeCalendar()

          me.renderClock()
          // me.renderAsync()
          return killEvent(e, true) // otherwise the setting focus will not work
        })
    ])

  return me
}

const highlightText = '#A10A7D' // PDI plum

addCss(
  'datetime7',
  `
ul.calendar.dropdown {
  font-size: 0.75em;
  padding: 0.5rem;
  line-height: 1rem;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  min-width: 60px;
  border: solid 0.077em #bbb;
  overflow: hidden;
  z-index: 999;
  background-color: #fff;
  box-shadow: #ccc 0.14em 0.14em 0.3em 0em;
  box-sizing: border-box;
  list-style-type: none;
}
ul.calendar.dropdown > li.item {
  border-radius: 4px;
  padding: 0.5rem;
  border: 1px solid transparent;
}
ul.calendar.dropdown > li.item:hover {
  text-decoration: underline;
}
ul.calendar.dropdown > li.item.ow-selected {
  border: 1px ${highlightText} solid;
  color: ${highlightText};
}
`
)

module.exports = { datetime7 }
