const { html } = require('./cmp/html')
const { qc } = require('./cmp/qc')

const styles = () => `
.pop-box-wrapper * { box-sizing: border-box; }

.pop-box-wrapper{position:fixed;z-index:9999}
.pop-box-wrapper.top{top:0;bottom:auto}
.pop-box-wrapper.bottom{bottom:0;top:auto}
.pop-box-wrapper.left{left:0}
.pop-box-wrapper.right{right:0;left:auto}
.pop-box-wrapper.center{width:50%;height:50%;margin:auto;position:fixed;top:0;left:0;bottom:0;right:0}

.pop-box.tumblr .title{position:relative;font-size:15px;line-height:15px;height:28px;padding:5px 10px;border-bottom:1px solid rgba(0,0,0,.1);font-weight:700;z-index:1}
.pop-box.tumblr .content{ padding:5px }

.pop-box-wrapper * { box-sizing: border-box; }
.pop-box-wrapper { position: fixed; height: 0; }
.pop-box-wrapper.center {
  align-items: center;
  justify-content: center;
  margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  z-index: 99999;
}
.pop-box-wrapper.center{ width: 0%; height: 0%; display: block; margin: auto;  top: 0; left: 0; bottom: 0; right: 0; z-index: 3; }*/

.pop-box-wrapper.center > .pop-box-wrapper-inner {
  margin: auto;
  text-align: center;
  display: inline-block;
}

.pop-box.tumblr .title { position: relative; line-height: 1em; height: 2em; padding: 0.5em 1em; font-weight: 700; z-index: 1; color: #fff; }
.pop-box.tumblr .content { width: 100%; font-size: 0.9em; padding: 0.5em; color: #4c4c4c; }
.pop-box.tumblr .content .k-button { margin: 0em 0.25em 0.5em !important; }

.pop-box-overlay { position: fixed; width: 100%; height: 100%; top: 0; left: 0; background: rgba(0,0,0,0.2); display: block; z-index: 100; }
.pop-box-overlay .pop-box-wrapper { z-index: 2; }

.pop-box-wrapper i { 
  display: inline-block; 
  width: 1em; 
  text-align: center; 
  font-size: 2em; 
  margin: 0em 0.25em 0.25em; 
}
.pop-box-close { 
  font-family: "FontAwesome";
  width: 20px; 
  position: absolute; 
  display: block; 
  top: 0.25em; 
  right: 0.5em;
  color: #fff;  
  font-size: 1.2em; 
  z-index: 2; 
  height: 20px; 
  cursor: pointer; 
}

.pop-box-wrapper p {
  width: calc(100% - 3.75em);
  float: right;
  margin: 0 0.25em 0.5em;
}

.pop-box.tumblr.ok .title { background: rgba(97,177,60,1); }
.pop-box.tumblr.info .title { background: rgba(34,82,174,1); }
.pop-box.tumblr.warning .title { background: rgba(251,192,41,1); }
.pop-box.tumblr.error .title { background: rgba(204,32,49,1); }
.pop-box.tumblr.content .title { background: rgba(236,98,37,1); }

.pop-box .title { background: #307ebe; }
.pop-box.info .title { background: #307ebe; }
.pop-box.ok .title { background: rgba(97, 177, 60, 1); }
.pop-box.warning .title { background: rgba(251, 192, 41, 1); }
.pop-box.error .title { background: rgba(204, 32, 49, 1); }
.pop-box.content .title { background: rgba(236, 98, 37, 1); }
.pop-box.error .content { height: auto; }

.pop-box.tumblr {
  box-shadow: 0 1px 4px rgba(0,0,0,.3);
  z-index:1;
  width: 300px;
  padding: 2px;
  position: relative; 
  display: block; 
  margin: 0; 
  font-size: 1em; 
  text-align: left; 
  color: #fff; 
  border-radius: 3px; 
  cursor: pointer;
  margin-bottom: 0.5em;
  background-color:#fff;
  color: #4c4c4c;
  overflow: hidden;
}
.pop-box.tumblr.closing {
  max-height: 100px;
  margin: 0;
  border: 0;
  padding: 0;
  margin-bottom: 0;
}
.pop-box.tumblr.closing:not(.show) {
  max-height: 0 ! important;
  visibility: hidden;
  margin-bottom: 0;
  transition: max-height 2s ease-out;
}
`

let _wrapper, _wrapperInner

const wrapperInner = () => (_wrapperInner = _wrapperInner || qc('div.pop-box-wrapper-inner', []))

const wrapper = () => {
  _wrapper =
    _wrapper ||
    qc('div.pop-box-wrapper', [wrapperInner(), qc('style', html(styles()))]).css({
      position: 'fixed',
      height: '0',
      right: '1em',
      left: 'auto',
      top: '50px',
      bottom: 'auto'
    })
  _wrapper.css({ zIndex: '999999' })

  setTimeout(() => _wrapper.css({ zIndex: '999' }), 1000)
  _wrapper.renderTo(document.body)
  return _wrapper
}

const closeButton = () =>
  qc('span.pop-box-close', html('&#xf00d')).css({
    textAlign: 'right',
    marginTop: '0.2em'
  })

const titleBar = title => qc('div.title', title)

const msgPop = (title, content, iconName = 'info', delay) => {
  let me

  const close = () => {
    me.addClass('closing')
    me.css({ maxHeight: me.el.clientHeight + 10 + 'px' })
    me.removeClass('show')
    setTimeout(() => me.el.remove(), 2100)
  }

  me = qc('div.pop-box.tumblr.msg-box.show', [
    closeButton().on('click', close),
    titleBar(title),
    qc('div.content', [qc('i.fa.fa-' + iconName), content]).on('click', e => {
      if (e.target?.tagName === 'BUTTON') close()
    })
  ])

  wrapper()
  me.renderTo(_wrapperInner.el)

  if (delay) setTimeout(() => close(), delay)
  return me
}

const modalPop = (title, content, iconName = 'info', delay) => {
  const me = qc('div.pop-box-wrapper.screenlock', [
    qc('div.tint.overlay').css({
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: '#666',
      opacity: '0.8'
    }),
    qc('div.pop-box-wrapper-modal-inner', [
      qc('div.pop-box.tumblr.msg-box.info', [
        closeButton().on('click', () => me.el.remove()),
        titleBar(title),
        qc('div.content', [qc('i.fa.fa-' + iconName), content])
      ]).css({ margin: 'auto' })
    ]).css({
      textAlign: 'center',
      display: 'block',
      width: '100%',
      marginTop: '20%'
    }),
    qc('style', html(styles()))
  ]).css({
    position: 'fixed',
    right: '1px',
    left: '1px',
    top: '1px',
    bottom: '1px',
    height: '99%',
    zIndex: '99999'
  })

  me.renderTo(document.body)

  if (delay) setTimeout(() => me.el.remove(), delay)

  return me
}

const popTumblr = (notifyObj, dataObj) =>
  notifyObj.isOverlay
    ? modalPop(
        dataObj.code,
        dataObj.msg,
        notifyObj.iconName.replace('fa fa-', ''),
        notifyObj.delay
      ).addClass(notifyObj.theme || '')
    : msgPop(
        dataObj.code,
        dataObj.msg,
        notifyObj.iconName.replace('fa fa-', ''),
        notifyObj.delay
      ).addClass(notifyObj.theme || '')

const popInfo = (title, msg, closeBy) => {
  if (title && !msg) {
    msg = title
    title = 'Info'
  }
  var cfg = {}
  cfg.theme = 'info'
  cfg.iconName = 'fa fa-info'
  cfg.position = 'top right'
  cfg.isSticky = true

  closeBy = typeof closeBy === 'undefined' ? 3000 : closeBy
  if (closeBy !== 0) {
    cfg.delay = closeBy
    cfg.isSticky = false
  }
  cfg.isOverlay = false
  cfg.showCloseButton = true
  cfg.closeOnClick = false
  cfg.clearAll = false

  var data = {}
  data.code = title
  data.msg = msg
  return popTumblr(cfg, data)
}

const popWarning = function popWarning(title, msg, closeBy) {
  var cfg = {}
  cfg.theme = 'warning'
  cfg.iconName = 'fa fa-warning'
  cfg.position = 'top right'
  cfg.isSticky = true
  if (closeBy != undefined) {
    cfg.delay = closeBy
    cfg.isSticky = false
  }
  cfg.isOverlay = false
  cfg.showCloseButton = true
  cfg.closeOnClick = false
  cfg.clearAll = false

  var data = {}
  data.code = title
  data.msg = msg
  return popTumblr(cfg, data)
}

const popError = function popError(title, msg, closeBy) {
  if (title && !msg) {
    msg = parseError(title)
    title = null
  }
  if (title === '-123' || title === '-124') title = null
  if (title === null) title = 'Error'
  //closeBy = closeBy || 5000;
  var cfg = {}
  cfg.theme = 'error'
  cfg.iconName = 'fa fa-exclamation-triangle'
  cfg.position = 'top right'
  cfg.isSticky = true
  if (closeBy != undefined) {
    closeBy = 5000 //override user parameter
    cfg.delay = closeBy
    cfg.isSticky = false
  }
  cfg.isOverlay = false
  cfg.showCloseButton = true //cfg.isSticky;
  cfg.closeOnClick = false
  cfg.clearAll = false

  var data = {}
  data.code = title
  data.msg = msg
  return popTumblr(cfg, data)
}

const popSuccess = function popSuccess(title, msg, closeBy) {
  if (title && !msg) {
    msg = title
    title = 'Success'
  }
  closeBy = closeBy || 4000
  var cfg = {}
  cfg.theme = 'ok'
  cfg.iconName = 'fa fa-check'
  cfg.position = 'top right'
  cfg.isSticky = true
  if (closeBy != undefined) {
    cfg.delay = closeBy
    cfg.isSticky = false
  }
  cfg.isOverlay = false
  cfg.showCloseButton = true
  cfg.closeOnClick = false
  cfg.clearAll = false

  var data = {}
  data.code = title
  data.msg = msg
  return popTumblr(cfg, data)
}

const popModal = (title, msg) => {
  if (title && !msg) {
    msg = title
    title = 'Information'
  }
  var cfg = {}
  cfg.theme = 'content'
  cfg.iconName = 'fa fa-ge'
  cfg.position = 'center center'
  cfg.delay = 0
  cfg.isSticky = true

  cfg.isOverlay = true
  cfg.showCloseButton = true
  cfg.closeOnClick = false
  cfg.clearAll = true

  var data = {}
  data.code = title || ''
  data.msg = msg || ''
  return popTumblr(cfg, data)
}

const alert = function (msg, title, opts) {
  popInfo(title ? title : 'information', msg, opts)
}

const popInvalid = function popInvalid(title, msg, closeBy) {
  if (title && !msg) {
    msg = title
    title = 'Invalid'
  }
  var cfg = {}
  cfg.theme = 'error'
  cfg.iconName = 'fa fa-exclamation-triangle'
  cfg.position = 'top right'
  // cfg.position = "center center";
  closeBy = closeBy || 3000

  cfg.isSticky = true
  if (closeBy != undefined) {
    cfg.delay = closeBy
    cfg.isSticky = false
  }
  cfg.isOverlay = false
  cfg.showCloseButton = true
  cfg.closeOnClick = false
  cfg.clearAll = false

  var data = {}
  data.code = title
  data.msg = msg
  return popTumblr(cfg, data)
}

const parseError = err => {
  console.warn(err)
  return typeof err === 'string'
    ? err
    : err.errMsg
    ? err.errMsg
    : err.responseText
    ? err.responseJSON?.errMsg ||
      err.responseText.errMsg ||
      err.responseText.message ||
      err.responseText
    : 'Operation Failed'
}

const popSaveError = function (err) {
  if (err.status === 401) return window.location.reload()

  let msg = parseError(err)

  if (!msg) msg = err.response ? JSON.stringify(err.response) : JSON.stringify(err)

  if (typeof msg === 'object') {
    try {
      msg = Array.isArray(msg) ? html(msg.join('<br>')) : JSON.parse(msg)
    } catch (e) {
      popError('Save failed', err.responseText, 5000)
    }
  }

  popError('Save failed', msg, 5000)
}

const popDeleteError = err => {
  if (err.status === 401) return window.location.reload()
  popError('Delete failed:', err, 5000)
}

const popSaveOK = () => popSuccess('Saved successfully')
const popDeleteOK = () => popSuccess('Deleted successfully')

module.exports = {
  alert,
  wrapper,
  msgPop,
  modalPop,
  popDeleteError,
  popError,
  popInfo,
  popInvalid,
  popModal,
  popSaveError,
  popSuccess,
  popWarning,
  popDeleteOK,
  popSaveOK
}
