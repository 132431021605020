const { icon6 } = require('./icons')
const { openPopUpFactory } = require('./popup')
const { subMatches } = require('./combo-matches')

// Some jQuery to replace later
const { comboDropdown } = require('./combo-dropdown')
const { input6 } = require('./text6')
const { react } = require('./data-model')
const { popInvalid, popError } = require('../pop-box')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const { mergeProps } = require('../cmp/mergeProps')
const { qc } = require('../cmp/qc')

const evalOpt = (x, opt) => (typeof x[opt] === 'function' ? x[opt].call(x, x.model) : x[opt])

const urlHasChanged = combo => evalOpt(combo, 'url') !== combo.lastUrl

/**
 * Makes AJAX call and returns data.
 *
 * @async
 * @param {string} host - URL for AJAX request.
 * @param {object} data - Data to send in AJAX request.
 */
const fetchListUrl = async (host, data) => {
  return $ajax({ url: host, data }).then(r =>
    Array.isArray(r) || typeof r === 'string' ? r : r.data
  )
}

const getSelectedItemFromFixedList = (list, value, valueField) =>
  list.find(item => _v(item, valueField) === value)

const updateModel = combo => {
  // setting on the bound model
  if (combo.model) {
    if (combo.fieldName) _v(combo.model, combo.fieldName, combo.value)
    if (combo.objectFieldName)
      _v(combo.model, combo.objectFieldName, combo._selectedItem || undefined)

    if (combo.objectFieldName) react(combo.view, combo.model, combo.objectFieldName)
    if (combo.fieldName) react(combo.view, combo.model, combo.fieldName)
  }
  if (combo.el) combo.trigger('ow-change')
}

const comboProps = {
  /**
   * After combobox matches have been retrieved from server, resolveItemSelection sets state and calls methods related to combobox, e.g. udpate combobox content, close dropdown, etc.
   *
   * @param {boolean} [forceSelection] - Used to force a selection.
   */
  resolveItemSelection(forceSelection) {
    console.log(
      'resolveItemSelection listIndex:' + this.listIndex + (forceSelection ? ' with force' : ''),
      'combo'
    )
    // Is selectedItem still valid?
    if (this._selectedItem) {
      const v = _v(this._selectedItem, this.valueField)
      const itemInList = this.matchList.find(item => _v(item, this.valueField) === v)
      if (itemInList) {
        this.listIndex = Math.max(0, this.matchList.indexOf(itemInList))
        if (this.displayTemplate(this._selectedItem) === this.attrs.value) return
      }
    }

    let index = this.listIndex || 0

    // for static list with showAll
    if (this.list) {
      const list = evalOpt(this, 'list')
      index = this.showAll && this.matchList[index] ? list.indexOf(this.matchList[index]) : 0
      if (this.showAll) this.matchList = list
      this.listIndex = index
    }

    var hasFocus = this.hasFocus || this.el === document.activeElement
    if (!hasFocus && !this.allowUnmatchedText) forceSelection = true //

    if (this.dropdown) this.dropdown.updateContent()

    if (this.dropdown) {
      if (forceSelection) this.dropdown.close()
    } else {
      if (forceSelection && !this.matchedOn) {
        this.selectItem(null)
      } else if (this.matchList.length === 0) {
        if (forceSelection) {
          this.selectItem(null)
          popInvalid(this.msgs.NoMatches)
        }
      } else if (this.matchList.length === 1) {
        // if (forceSelection || (this.required && this.typedText))
        if (forceSelection) this.selectItem(this.matchList[0])
        this.listIndex = 0
        return
      } else {
        // if there's an exact match, use that, not the first
        const exactMatch = this.matchList.find(item => {
          var matchOnValue = this.valueField === this.textField || this.valueTextDisplay
          if (matchOnValue && _v(item, this.valueField) === matchOnValue) return true
          return this.displayTemplate(item) === this.typedText // this.el.value ??
        })

        if (exactMatch && (forceSelection || this.matchFirst)) {
          this.selectItem(exactMatch)
        } else if (forceSelection && this.matchedOn) {
          this.selectItem(null)
        } else if (this.matchFirst) {
          this.selectItem(this.matchList[0])
        } else {
          if (!this.dropdown && !forceSelection) this.openList()

          this.listIndex = index
          if (this.dropdown) this.dropdown.updateContent()
        }
        // if that fails...
        if (!this._selectedItem && this.value && forceSelection && !this.allowUnmatchedText) {
          this.value = this.blankValue
          this.typedText = null
        }
      }
    }
  },

  selectedItem() {
    return this._selectedItem
  },

  async resolveBeforeExit(clearIfInvalid) {
    const { el } = this
    console.log('Resolving selection for combo Exit ' + el.value + ' ' + this.fieldName)

    if (this._selectedItem) {
      if (this.typedText === null) return this.val()
      // if there's selected item but typedText, we need to clear
      this.selectItem(null)
      if (this.waitingOnSub) this.resolveOnResponse = true
    }

    if (!this._selectedItem && el.value !== '' && this.list) {
      await this.findMatches(true)
    }

    // if (!this._selectedItem && !this.list) {
    //   if (this.typedText && this.required) {
    //     log('combo setting to typedText, ' + this.typedText)
    //     this.value = this.typedText
    //     updateModel(this)
    //     await this.findMatches(true)
    //   }
    // }

    if (!this._selectedItem) {
      if (el.value !== '' && this.required) {
        await this.findMatches(true) //, function() { resolveItemSelection(true)})
      }
    }
    var v = this.val()

    if (clearIfInvalid && v === this.blankValue && this.typedText) {
      el.value = ''
      this.typedText = null
    }

    return v
  },

  validate(onInvalid) {
    let result = true
    let v = this.val()

    console.log('combo.validate ' + result, v)

    if (this.required && v === this.blankValue) result = false

    if (!result)
      onInvalid && onInvalid(this.name || this.label || '', this.msgs.IncorrectMatch, this.el)

    return result
  },

  val(v) {
    // this._selectedItem =
    //   this.model && this.objectFieldName
    //     ? _v(this.model, this.objectFieldName)
    //     : this._selectedItem || undefined

    if (this.value === undefined || this.value === null) this.value = this.blankValue

    if (typeof v !== 'undefined') {
      // using val(v) to update value should not update the model!  It should match item, trigger a change event
      // log('COMBO val ' + this.fieldName + ': ' + this.value + ' -> ' + v)

      this.value = v

      if (v === this.blankValue) {
        this.selectItem(null)
      } else if (!this._selectedItem && this.list) {
        this.selectItem(
          getSelectedItemFromFixedList(evalOpt(this, 'list'), v, this.valueField) || null
        )
      }

      return v
    }

    // v = this._selectedItem ? (this.allowUnmatchedText ? this.el.value : this.blankValue) : v
    if (!this._selectedItem && this.value === '' && !this.allowEmptyString)
      this.value = this.blankValue

    if (
      !this._selectedItem &&
      ((this.allowUnmatchedText && this.typedText) || this.allowEmptyString)
    ) {
      console.log('combo setting to typedText, ' + this.typedText)
      v = this.typedText
      this.value = v
    }
    return this.value
  },

  selectItem(item = null, closeAfter = true) {
    if (this.canSelectItem) if (this.canSelectItem(item) === false) return false

    this._selectedItem = item ? { ...item } : null
    this.value = this._selectedItem ? _v(this._selectedItem, this.valueField) : this.blankValue

    if (this._selectedItem) {
      // this.display = this.displayTemplate(this._selectedItem)
      this.typedText = null
    }
    if (this.dropdown && closeAfter) this.dropdown.close()

    updateModel(this)
  },

  openListWithDelay(delay) {
    if (this.dropdown) {
      this.dropdown.close()
      return
    }

    // if (this.fieldName && this.model) _v(this.model, this.fieldName, el.value) // prevents new blank row being canceled on row change.

    if (!this.matchTimeout) {
      this.matchTimeout = setTimeout(
        () => {
          this.matchTimeout = null
          if (!this.dropdown && (this.hasFocus || this.el === document.activeElement)) {
            this.openList()
            this.findMatches()
          }
        },
        delay === 0 ? 0 : delay || this.delay || 300
      )
    }
  },

  /**
   * Creates a dropdown element, opens it, and updates its contents.
   */
  openList() {
    let viewParent = this.view.qTop.el.parentElement

    // if there's already a dropdown showing, hide it
    const alreadyOpen = no$(viewParent).find('.ow-ctl-dropdown')[0]
    if (alreadyOpen) qc(alreadyOpen).close()
    this.dropdown = comboDropdown({
      combo: this,
      itemTemplate: (x, y, z) => this.itemTemplate(x, y, z),
      listWidth: this.listWidth === 1 ? this.el.parentElement.offsetWidth : this.listWidth || 300
    })
    this.dropdown.renderTo(viewParent)
    this.addClass('ow-dropdown-open')

    if (this.list || this.matchedOn !== this.el.value || urlHasChanged(this)) this.findMatches()
    else this.dropdown.updateContent()
  },

  /**
   * From typed text, fetches all matches, and displays results in dropdown list.
   *
   * @async
   * @param {boolean} [forceSelection] - Used to force a selection.
   * @param {function} [then] - Function to call after successful match is found.
   */
  async findMatches(forceSelection, then) {
    const { el } = this
    /**
     * Placeholder description for _sub function.
     *
     */
    var _sub = () => {
      var s = this.typedText || ''
      if (this.valueTextDisplay) {
        var splits = s.split(' - ')
        if (splits.length > 1) {
          s = splits[0]
        } else {
          // if ends with part of " - "
          s = s.trim()
          if (s.substr(-2) === ' -') s = s.substr(0, s.length - 2)
        }
      }
      return s
    }
    var sub = this.sub || _sub(this.typedText)

    if (this._selectedItem) {
      if (el.value === '' && this.val() !== this.blankValue) this.val(this.blankValue)
      else if (!this.list && this.listIndex !== this.matchList.indexOf(this._selectedItem)) {
        this.listIndex = -1
        this.matchList.find((x, i) => {
          if (_v(x, this.valueField) === _v(this._selectedItem, this.valueField)) {
            this.listIndex = i
            return true
          }
        })
        if (this.listIndex === -1) {
          this.matchList.unshift(this._selectedItem)
          this.listIndex = 0
        }
      }
    }

    const onSuccess = (response, matchedOn) => {
      this.listIndex = this.listIndex || 0
      if (this.typedText && matchedOn !== this.typedText) return

      // if the match
      if (matchedOn !== null) {
        if (this.matchedOn !== matchedOn) this.listIndex = 0
        this.matchedOn = matchedOn
        this.matchList = response
      }

      if (!this.list) this.resolveItemSelection(forceSelection)
      if (this.dropdown) this.dropdown.updateContent()

      if (then) then()
    }

    if (this.list) {
      var list = evalOpt(this, 'list', el)
      sub = sub.toLowerCase() // (el.value || '').toLowerCase()

      if (sub === '') {
        if (this._selectedItem && this.typedText === null) {
          this.listIndex = list.map(x => _v(x, this.valueField)).indexOf(this.val())
          if (this.listIndex === -1) console.warn('selected item not found')
        }
        onSuccess(list, this.typedText)
      } else {
        var matches = subMatches(list, sub, this.valueField, this.textField, this.itemTemplate)
        onSuccess(matches, this.typedText)
      }
    } else {
      if (!this.url) {
        this.url =
          '/data/' +
          this.view.qTop.viewdata.url.split('/').pop().toLowerCase() +
          '/lookup/' +
          this.fieldName.toLowerCase()
        console.log('// combo has no list or url so defaulting to ' + this.url)
      }

      var url = evalOpt(this, 'url', el)

      // decompose into host and query data with sub added
      let [host, query] = url.split('?')
      let data = { sub }
      query &&
        query
          .split('&')
          .filter(x => x)
          .map(x => x.split('='))
          .forEach(([k, v]) => k && (data[k] = v))

      query = Object.entries(data)
        .map(x => x.map(encodeURIComponent).join('='))
        .join('&')
      url = [host, query].filter(x => x).join('?')

      if (url === this.lastUrl) return onSuccess(this.matchList, this.typedText)
      if (url === this.waitingOnUrl) return

      this.waitingOnUrl = url

      // this tells the model to wait
      const tmpField = 'loading_' + this.fieldName
      const afterLoad = () => {
        if (_v(this.model, tmpField) === sub) {
          delete this.model[tmpField]
          react(this.view, this.model, tmpField)
        }
      }
      if (this.model && this.fieldName) {
        this.model[tmpField] = sub
        react(this.view, this.model, tmpField)
      }

      try {
        this.dropdown?.addClass('ow-loading')
        let r = await fetchListUrl(url) // , data)
        afterLoad()
        // If server response is a string, assume it is an authentication error because the user is not logged in. CAG 24 Feb 21:  Admittedly, this might not always be the case.
        if (typeof r === 'string') {
          popError(
            'Cannot fetch selections from server.',
            'Please make sure you are logged in (/login).',
            1000
          )
        }
        if (this.waitingOnUrl !== url)
          return console.log(
            'A newer request for ' +
              this.waitingOnUrl +
              'has been sent, ignoring this one for ' +
              sub
          )
        this.waitingOnUrl = null
        this.lastUrl = url
        return onSuccess(r, sub)
      } catch (err) {
        afterLoad()
        if (this.waitingOnUrl === url) this.waitingOnUrl = null
        this.lastUrl = url
        this.matchList = []
        this.dropdown?.updateContent()
        this.dropdown?.removeClass('ow-loading').removeClass('match-notfound')

        popError('The server returned an error looking up item matching text, Error: ' + err)
        throw 'The server returned an error looking up item matching text, Error: ' + err
      }
    }
  },

  readFilter(filters) {
    const { el } = this

    if (el.value === '' && (!this.op || this.op === 'contains')) return

    var v = this.val()
    var filter = {
      field: this.fieldName,
      operator: typeof v !== 'string' ? 'eq' : 'contains',
      value: v
    }

    if (v) filter.operator = 'eq'
    else if (typeof v !== 'string') filter.operator = 'eq'
    // cater for 0
    else if (el.value) {
      filter.operator = 'contains'
      filter.value = el.value || null
    }

    filter.operator = this.op ? this.op : this.op ? this.op : filter.operator // || $attr(this, 'data-filter-operator')

    if (this.textFilterField && filter.value) {
      filter.field = this.textFilterField
      filter.value = el.value || null
    }

    if (filter.value !== undefined && filter.value !== null && filter.value !== '')
      filters.push(filter)
  }
}

/**
 * Creates an instance of the Combo6 class.
 *
 * @param {object} props - Object containing key/value pairs of properties required to create instance of Combo6 class.
 * @param {string} props.fieldName
 * @param {string} props.dsName
 * @param {string | function} [props.url] - URL used to fetch data used to populate dropdown.
 * @param {array | function} props.list - a static list of data rather than a list fetched from a URL. In many (or all?) cases, the array will be of the form [{Text: <string>, Value: <string | number | boolean>}]
 * @param {boolean} props.showAll - if you want to show all of the dropdown static list without client-side filtering
 * @param {function} props.displayTemplate - returns display string for a selected item
 * @param {function} props.itemTemplate - returns HTML string a dropdown item
 * @param {boolean} props.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} props.required - validation (better to use dc validation)
 * @param {boolean} props.matchFirst - automatically select first match on tab out if nothing selected
 * @param {string} props.valueField - default 'Value'
 * @param {string} props.textField - default 'Text'
 * @param {string} props.objectFieldName
 * @param {object} props.model - the rec we're editing - model[fieldName]
 * @param {boolean} props.allowUnmatchedText - if set to true, the typed text will be returned even if there's no item selected
 * @param {boolean} props.allowEmptyString - if not true, then a value of "" will be converted to null
 * @param {Object} props.popUp - info for popping up another form to select a value.
 * @param {boolean} props.openOnEnterKey
 * @param {boolean} props.openOnType default true
 * @param {function} props.canSelectItem
 * @param {Number} props.delay - how many milliseconds on keyboard event waits for another key before calling url for matches
 * @param {function} props.onInit - is called after initializing the combo
 * @param {string} props.msgs.NoMatches
 * @param {string} props.msgs.IncorrectMatch
 * @param {number} props.listWidth - if 1 it will match width of input else it will be px
 * @param {boolean} props.isSelect - Flag indicating if combobox should function like a <select> element, in which the user is not allowed to type into the <input> element.
 */
const combo6 = props => {
  if (props.list && !Array.isArray(props.list))
    throw 'Incorrect value for props.list. Array is required.'

  // normalizeOpts
  props.valueField = props.valueField || 'Value'
  props.textField = props.textField || 'Text'
  props.msgs = props.msgs || {}
  props.msgs.IncorrectMatch = props.msgs.IncorrectMatch || __('Incorrect match')
  props.msgs.NoMatches = props.msgs.NoMatches || __('No matches')

  props.tag = props.tag || 'input.combo6'

  props.isCombo = true

  if (props.onSelectItem) {
    props.canSelectItem = props.onSelectItem
    delete props.onSelectItem
    console.warn('combo.onSelectItem is deprecated, please use canSelectItem')
  }

  props.blankValue = null
  props.typedText = null // set the user typing tracker - discerns typed text from set display values

  props.showAll = props.showAll !== false

  props = mergeProps(comboProps, props)
  props.matchFirst = props.matchFirst !== false
  const me = input6(props).addClass('combo6')

  me.on('change', e => {
    // suppress onchange event if poplist is showing or enter key triggered it
    if (me.dropdown || me.ignoreNextChange || me.popUpOpen) {
      me.ignoreNextChange = false
      return killEvent(e, false)
    }
    me.findMatches()
  })
    .on('blur', () => {
      me.hasFocus = true
      setTimeout(() => {
        delete me.hasFocus
        if (me.el === document.activeElement) return false
        me.trigger('ow-delayed-blur')
      }, 50)
    })
    .on('ow-delayed-blur', e => {
      const { el, dropdown } = me
      if (dropdown) {
        if (me.ignoreNextChange || me.popUpOpen) {
          me.ignoreNextChange = false
          return killEvent(e, false)
        } else dropdown.close()
      }
      if (el === document.activeElement) return killEvent(e, false)
      if (me.resolveBeforeExit) me.resolveBeforeExit(!me.allowUnmatchedText || !me.matchFirst)
      if (!me.allowUnmatchedText || me._selectedItem) me.typedText = null
    })
    .on('keyup', e => {
      const { el } = me

      if (me.hasClass('static-text') || me.disabled) return
      if (me._valueOnKeyDown === el.value) return

      const isTextKey = e.which === 8 || e.which === 32 || e.which >= 46
      if (isTextKey) {
        me.typedText = el.value
        me.value = me.val()
        updateModel(me)
      }
      if (isTextKey && me.openOnType !== false && !me.dropdown)
        if (el.value !== '') me.openListWithDelay() // don't open if we just deleted the value

      if (me.dropdown) {
        // up down arrows - pass through to the list
        if (e.which === 38 || e.which === 40) return false

        // escape
        if (e.which === 27) {
          me.dropdown.close()
          return false
        }

        if (e.which === 38 || e.which === 40 || e.which === 9) {
          return false // handler onKeydown
        } else {
          if (!me.matchTimeout) {
            me.matchTimeout = setTimeout(() => {
              me.matchTimeout = null
              if (me.dropdown) me.findMatches()
            }, 150)
          }
        }
      }
    })
    .on('keypress', e => {
      if (me.dropdown) {
        if (e.which === 38 || e.which === 40) return killEvent(e, false)
      }
    })
    .on('keydown', e => {
      const { el } = me
      me._valueOnKeyDown = el.value

      if (me.dropdown) {
        // shift+enter
        if (e.which === 13 && e.shiftKey) {
          me.dropdown.close()
          return killEvent(e, false)
        }

        // escape
        if (e.which === 27) {
          me.dropdown.close()
          return killEvent(e, false)
        }

        // tab or enter (no shift)
        else if (e.which === 9 || e.which === 13) {
          if (
            el.value !== '' ||
            me._selectedItem ||
            me.matchList[me.listIndex] ||
            (e.which === 13 && me.dropdown)
          ) {
            if (me._selectedItem !== me.matchList[me.listIndex])
              me.selectItem(me.matchList[me.listIndex])
            if (me.dropdown) me.dropdown.close()
            if (e.which === 13) return killEvent(e, false)
          }
        }

        // up down arrows
        else if (e.which === 38 || e.which === 40) {
          me.listIndex =
            (me.matchList.length + me.listIndex + (e.which === 40 ? 1 : -1)) % me.matchList.length

          me.dropdown.renderAsync()
          return killEvent(e, false)
        }
      }

      // delete or backspace
      if (e.which === 8 || e.which === 46) {
        setTimeout(() => {
          if (me._selectedItem && el.value === '') me.selectItem(null, false)
        }, 5)
      }

      // Enter
      else if (e.which === 13) {
        if (me.disabled) return
        if (e.shiftKey || me.openOnEnterKey !== false) {
          me.openList()
          return killEvent(e, false)
        }
        return
      }
      // F9
      else if (e.which === 120) {
        if (me.popUp && (typeof me.popUp === 'function' ? me.popUp(el) : true))
          openPopUpFactory(el)()
      }
    })

  const { isSelect } = me
  if (isSelect) me.readOnly = true

  me.wrap().addClass('combo6-wrap').addClass('text-icon-after')

  const iconCombo = icon6(props.popUp ? props.iconCode || 'magnifier' : 'caretDown').addClass(
    'text-item-icon ' + (props.popUp ? 'popup' : 'combo-icon')
  )

  /**
   * On a combobox, opens the dropdown and sets focus. Also opens any associated popups.
   * @returns {boolean|undefined}
   */
  const openCombo = () => {
    if (me.disabled) return

    if (me.popUp) {
      if (me.el) openPopUpFactory(me.el)()
      return false
    }

    me.el.focus()
    if (me.dropdown) me.dropdown.close()
    else me.openList()

    return false
  }

  if (me.isSelect) me.wrap().on('click', openCombo)
  else iconCombo.on('click', openCombo)

  me.wrap().kids([...me.wrap().kids(), iconCombo])

  if (!me.displayTemplate) {
    me.displayTemplate = item => {
      if (!item) return ''

      var t = _v(item, me.textField) || ''
      t = t && t.toString ? t.toString() : t
      if (me.valueTextDisplay) {
        var v = _v(item, me.valueField)
        v = v === null || v === undefined ? '' : v + ' - '
        t = v + t
      }

      return t
    }
  }

  if (!me.itemTemplate) me.itemTemplate = me.displayTemplate

  // initial value
  if (!('value' in me)) {
    if (me.model && me.fieldName) me.value = _v(me.model, me.fieldName)
    else me.value = me.blankValue
  }

  const list = me.list ? evalOpt(me, 'list') : []

  // initial selectedItem
  if (me.value !== me.blankValue && !me._selectedItem) {
    if (me.model && me.objectFieldName) me._selectedItem = _v(me.model, me.objectFieldName)
    else if (me.list) me._selectedItem = list.find(x => _v(x, me.valueField) === me.value)
  }

  me.matchedOn = '' // note, matchedOn is set to me.typedText
  me.matchList = me.list ? list : me._selectedItem ? [me._selectedItem] : []

  me.bindState(
    function () {
      return (
        (this.model && this.objectFieldName && _v(this.model, this.objectFieldName)) || undefined
      )
    },
    function (item) {
      if ((this.hasFocus || this.el === document.activeElement) && this.typedText) return
      if (this.value && item) me._selectedItem = item
    }
  ).bindState('_selectedItem', function (item) {
    if ((this.hasFocus || this.el === document.activeElement) && this.typedText) return
    // if (item) me.display = me.displayTemplate(item)
    // else if (this.allowUnmatchedText) return
    me.attr({ value: item ? me.displayTemplate(item) : this.typedText || '' })
  })

  return me
}

module.exports = { combo6 }
