const { qc } = require('../cmp/qc')
const { _v } = require('../_v')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { popError } = require('../pop-box')
const { $offset } = require('../no-jquery')
const { killEvent } = require('../killEvent')
const { makeDropdown7 } = require('./makeDropdown7')
const { dates } = require('../culture')

const filterSelected = (list, selectedItems, valueField) =>
  list.filter(x => !selectedItems.find(item => _v(item, valueField) === _v(x, valueField)))

/**
 *
 * @param {object} opts
 * @param {Array} opts.list
 * @param {string} opts.url
 * @param {boolean} opts.isFilterControl
 * @param {string} opts.textFilterField
 * @param {object} opts.filterMap
 * @param {string} opts.fieldName
 * @param {string} opts.dsName
 * @param {string} opts.valueField default 'Value'
 * @param {string} opts.textField default 'Text'
 * @param {object} opts.model - the rec we're editing - model[fieldName]
 * @param {function} opts.itemTemplate - returns HTML string a dropdown item
 * @param {boolean} opts.valueTextDisplay - use standard display 'VALUE - TEXT'
 * @param {boolean} opts.required - validation (better to use dc validation)
 * @param {boolean} opts.preserveOrder - if true the order will match the list rather than the order entered
 * @param {Array} opts.value - initial selected Items
 *
 * @returns {qc}
 */
const multiSelect7 = opts => {
  if (!opts.view) throw 'multiSelect7 requires opts.view'

  const viewParent = opts.view.qTop.el.parentElement

  let open = false

  opts.textField = opts.textField ?? 'Text'
  opts.valueField = opts.valueField ?? 'Value'

  let typedText = '',
    matchedOn

  let nominee, dd

  let list = opts.list ?? []

  const loadingLine = { [opts.textField ?? 'Text']: __('Loading...') }

  const findMatch = () =>
    typedText?.toLowerCase?.()
      ? list.find(
          item => (me.template(item)?.toLowerCase() ?? '').indexOf(typedText.toLowerCase()) + 1
        ) ?? list[0]
      : list[0]

  let loading = false
  const fetchList = async () => {
    const sub = typedText?.toLowerCase() ?? ''

    const loadData = () => {
      const selectedIDs = me.getArrayOfIDs()

      const data = selectedIDs.length
        ? {
            sub,
            filter: {
              filters: [
                {
                  field: opts.valueField ?? 'Value',
                  operator: 'notIn',
                  value: me.getArrayOfIDs()
                }
              ]
            }
          }
        : { sub }
      const matchingOn = common.$param(data)

      if (matchedOn !== matchingOn) {
        if (!loading) {
          $ajax({ url: opts.url, data })
            .then(res => {
              matchedOn = matchingOn
              list = res.data || res
              loading = false
              renderList()
            })
            .catch(() => popError('lookup failed to load data.'))

          loading = true
        }
        return [loadingLine]
      }
      return list
    }
    if (opts.list)
      return filterSelected(
        typedText && !opts.showAll
          ? opts.list.filter(item => (me.template(item)?.toLowerCase() ?? '').indexOf(sub) + 1)
          : opts.list,
        me.selectedItems,
        me.opts.valueField ?? 'Value'
      )

    return filterSelected(
      opts.url ? loadData() : [],
      me.selectedItems,
      me.opts.valueField ?? 'Value'
    )
  }

  const renderList = async () => {
    const ddParent = viewParent

    list = await fetchList()
    if (!list.includes(nominee)) nominee = findMatch()

    if (dd) {
      open
        ? dd.el?.parentElement
          ? dd.renderAsync()
          : dd.renderTo(ddParent)
        : dd.el?.parentElement && dd.el.remove()

      dd.reposition()
    } else dd = qc('ul.multi-select7.dropdown')

    list = await fetchList()

    const buildItemLi = item =>
      qc('li.item', me.template(item))
        .bindState(
          () => nominee,
          function () {
            item !== loadingLine && nominee === item
              ? this.addClass('ow-selected')
              : this.removeClass('ow-selected')
          }
        )
        .on('click', () => {
          if (item === loadingLine) return
          me.select(item)
          typedText = ''
          dd.renderAsync()
        })

    const buildList = () =>
      list.length ? list.map(buildItemLi) : qc('li', __('No matches')).css({ opacity: '0.5' })

    makeDropdown7(dd, ddParent, () => $offset(me.wrap().el)).bindState(() =>
      dd.css({
        width:
          (opts.listWidth === undefined ? $offset(me.wrap().el).width : opts.listWidth || 300) +
          'px'
      })
    )

    dd.kids(buildList())
      .attr({ tabindex: '-1' })
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(
        () => list,
        () => dd.kids(buildList())
      )

    if (open) {
      dd.renderTo(ddParent)
      dd.reposition()
    }
  }

  opts.placeholder = ''

  const me = input7(opts)
    .css({ width: '3rem', display: 'inline-block' })
    .addClass('multi-select7')
    .props({
      validate(onInvalid, messageArray) {
        if (opts.required && !me.val()?.length) {
          onInvalid?.(
            __('You must choose at least one item ' + (opts.name ?? opts.fieldName)),
            undefined,
            me.el
          )
          return false
        }
      },

      template(model, i) {
        if (opts.template) return opts.template(model, i)

        if (opts.valueTextDisplay)
          return (
            (_v(model, opts.valueField ?? 'Value') ?? '') +
            ' - ' +
            _v(model, opts.textField ?? 'Text')
          )

        return _v(model, opts.textField ?? 'Text') ?? 'item-' + i
      },

      getArrayOfIDs() {
        return me.selectedItems.map(x => _v(x, opts.valueField ?? 'Value'))
      },

      select(item) {
        if (!item || item === loadingLine) return

        me.selectedItems = [...me.selectedItems, item]

        if (opts.preserveOrder && opts.list) {
          const unsortedItems = me.selectedItems
          me.selectedItems = []
          opts.list.forEach(x => {
            if (unsortedItems.includes(x)) me.selectedItems.push(x)
          })
        }
        me.val(me.selectedItems)

        me.text('')
        me.trigger('ow-select', item)
        me.renderAsync()
      },

      val(v, model, populating) {
        if (arguments.length > 0 && v !== loadingLine) {
          v = v ?? []
          me.selectedItems = v
          const prev = _v(me.model, me.opts.fieldName ?? 'Value')
          let hasChanged = v !== prev
          if (hasChanged) _v(me.model, me.opts.fieldName ?? 'Value', v)
          if (hasChanged && !populating) me.trigger('ow-change')

          list = filterSelected(list, me.selectedItems, me.opts.valueField ?? 'Value')

          me.wrap().renderAsync()

          dd?.renderAsync()
        }
        return _v(me.model, me.opts.fieldName ?? 'Value')
      },

      populate(model) {
        const v = _v(model, me.opts.fieldName ?? 'Value')
        me.val(v, model, true)
        me.text('')
      },

      readField(model) {
        _v(model, me.opts.fieldName ?? 'Value', me.val())
      },

      readFilter(filters) {
        let v = me.val()

        const operator = me.op ?? 'in'

        if (v?.length) {
          filters.push({
            field: opts.fieldName,
            operator,
            value: me.getArrayOfIDs() // just the IDs
          })
        }
        return filters
      }
    })
    .on('init', (e, el) => {
      el.readFilter = (...args) => me.readFilter(...args)
      if (opts.ow5) {
        el.ctlTypeClass = 'multiSelect7'
        el.validate = (...args) => me.validate(...args)
        el.displayValidity = (...args) => me.displayValidity(...args)
      }
    })
    .props({ model: opts.model ?? {} })
    .on('keydown', e => {
      // enter
      if (nominee && e.which === 13 && open) {
        if (nominee === loadingLine) return
        me.select(nominee)
        typedText = ''
        renderList()
        return
      }

      // shift + enter
      if (e.which === 13 && (e.shiftKey || !open)) {
        open = !open
        renderList()
        return
      }

      // escape
      if (e.which === 27) {
        open = false
        renderList()
        return
      }

      // downarrow
      if (e.which === 40) {
        if (!open) return
        nominee = list[Math.min(list.length - 1, list.indexOf(nominee) + 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        if (!open) return
        nominee = list[Math.max(0, list.indexOf(nominee) - 1)]
        renderList()
        e.stopPropagation()
        e.preventDefault()
        return false
      }

      // backspace
      if (e.which === 8 && me.el.value === '' && me.selectedItems[0]) {
        me.selectedItems.pop()
        me.val([...me.selectedItems])
        typedText = ''
        renderList()
        return killEvent(e, true)
      }
    })
    .on('keyup', () => {
      if (typedText === me.el.value) return
      typedText = me.el.value

      const opening = open === false

      if (opening) open = true

      if (typedText !== matchedOn) {
        nominee = undefined
        return renderList()
      }

      if (opening) renderList()
    })
    .on('blur', () =>
      setTimeout(() => {
        if (me.el === document.activeElement) return
        open = false
        renderList()
        me.renderAsync()
      }, 100)
    )
    .bindState(() => {
      if (open && document.activeElement !== me.el) {
        console.log('closing combo not focused')
        open = false
        me.renderAsync()
      }
    })
    .bindState(
      () => open,
      () => open && (dd ?? renderList())
    )

  me.selectedItems = opts.value ?? []

  dataBind(me, opts.view)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(me.selectedItems)

  me.rs().addClass('multi-line')

  const wrap = me
    .wrap()
    .addClass('multi-select7-wrap fancy-scrollbar')
    .removeClass('input7 input')
    .css({
      whiteSpace: 'normal',
      padding: '1px',
      position: 'relative',
      maxHeight: '4.8rem',
      overflowY: 'auto',
      lineHeight: '24px',
      minHeight: '28px'
    })
    .kids([me])
    .on('click', e => {
      if (e.target === wrap.el || e.target === me.el) {
        open = !open
        renderList()
      }
      me.el.focus()
    })
    .bindState(
      () => me.selectedItems,
      items => {
        renderList()
        wrap.kids([displayItems(me, items), me])
      }
    )
  return me
}

const displayItems = (me, items, template, textField = me.opts?.textField ?? 'Text') =>
  items.map(
    item =>
      template?.(item) ??
      qc('span.multi-select-item.ow-pill', [
        qc('label', _v(item, textField))
          .attr({ title: _v(item, textField) })
          .css({
            display: 'inline-block',
            whiteSpace: 'nowrap',
            maxWidth: '4rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            lineHeight: '9px'
          }),
        icon('times')
          .css({ margin: '0 2px', cursor: 'pointer' })
          .on('click', () => {
            me.el.focus() // return focus to the input
            me.val(me.val().filter(x => x !== item))
          })
      ]).css({
        padding: '3px',
        border: '1px solid #ececec',
        borderRadius: '3px',
        whiteSpace: 'nowrap',
        margin: '1px'
      })
  )

function weekdaysIntToArray(v) {
  const list =
    this.opts?.list ??
    dates.shortDaysOfWeekJs
      .map((d, Value) => ({
        Text: d,
        Value,
        order: (Value - dates.firstDay + 7) % 7
      }))
      .sort((a, b) => a.order - b.order)

  const arrayVal = []
  ;[6, 5, 4, 3, 2, 1, 0].forEach(Value => {
    const i = Math.pow(2, Value)
    if (v >= i) {
      arrayVal.push(list.find(x => x.Value === Value))
      v = v - i
    }
  })
  return arrayVal.sort((a, b) => a.order - b.order)
}

const arrayToWeekdaysInt = v => v?.reduce((r, day) => r + Math.pow(2, day.Value), 0)

let _weekdaysArray
const weekdaysArray = () =>
  _weekdaysArray ??
  (_weekdaysArray = dates.shortDaysOfWeekJs
    .map((d, Value) => ({
      Text: d,
      Value,
      order: (Value - dates.firstDay + 7) % 7
    }))
    .sort((a, b) => a.order - b.order))

const weekdays7 = opts => {
  const list = weekdaysArray()

  const model = opts.model
  delete opts.model

  opts.list = list
  opts.preserveOrder = true

  const fieldName = opts.fieldName
  if (fieldName) {
    opts.fieldName = '_' + fieldName
  }

  const value = opts.value
  delete opts.value

  const me = multiSelect7(opts)
  me._val = me.val
  // me._populate = me.populate

  me.props({
    populate(model) {
      return me.val(_v(model, fieldName), model, true)
    },

    intToArray: weekdaysIntToArray,

    arrayToInt(v = []) {
      if (!Array.isArray(v)) throw 'Array expected'
      return v?.reduce((r, day) => r + Math.pow(2, day.Value), 0)
    },

    val(...args) {
      let v = args[0] ?? 0
      if (args.length && typeof v === 'number') args[0] = me.intToArray(v)

      v = me._val(...args)
      if (fieldName && me.model) _v(me.model, fieldName, me.valAsInt())
      return v
    },

    valAsInt(...args) {
      const v = me._val(...args)
      return typeof v === 'number' ? v : v ? me.arrayToInt(v) : v
    }
  })

  if (value !== undefined) me.val(value)
  if (model) {
    opts.model = model
    me.populate(model)
  }

  return me
}

module.exports = { multiSelect7, weekdays7, weekdaysIntToArray, arrayToWeekdaysInt, weekdaysArray }
