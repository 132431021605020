const { $cmp } = require('./$cmp')

const fireInit = function (el, scope) {
  scope = scope || window
  var uid = el.getAttribute('data-cmp')
  el.setAttribute('data-cmp', null)
  var initFn = scope.instances[uid]
  if (initFn) {
    delete scope.instances[uid]
    try {
      initFn(el)
    } catch (e) {
      console.error('cmp init caused error ' + e)
    }
  }
}

const setScrollLeft = (cmp, x) => {
  if (!cmp.el) return
  const el = cmp.el
  if (el.scrollLeft !== x)
    // el.scrollTo(x, el.scrollTop)
    el.scrollLeft = x
}

const setScrollTop = (cmp, y) => {
  if (!cmp.el) return
  const el = cmp.el
  if (el.scrollTop !== y)
    // el.scrollTo(el.scrollLeft, y)
    el.scrollTop = y
}

// for dev
if (typeof window !== 'undefined') window.$cmp = window.$cmp || $cmp

module.exports = { setScrollTop, setScrollLeft, fireInit }
