const { ow4 } = require('../ow4/ow4')

let ow5 = Object.create(ow4)
ow5.version = 5

ow5.controls = Object.create(ow5.controls || {})
ow5.controls.basicEditorInits = Object.create(ow5.controls.basicEditorInits || {})
ow5.controls.version = 5

ow5.grids = Object.create(ow5.grids || {})
ow5.grids.version = 5

Object.assign(ow5, require('./wireUpView5'))

module.exports.ow5 = ow5
