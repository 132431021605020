/**
 * ResourceSet - label | control
 * @param {Object} opts
 * @param {boolean} opts.noLabel - don't create a label element at all
 * @param {string} opts.label
 * @param {string} opts.type - becomes a class of the RS div
 * @param {Object} content - what to put in the presentation span
 *
 * Note: the cmp this returns has method addContent
 */

const { qc } = require('../cmp/qc')

const rs = (opts, content = []) => {
  opts.type = opts.type || ''

  let span = qc('span', { role: 'presentation' }, content)
  let label = qc('label', { class: 'resource_label' }, opts.noLabel ? '' : opts.label)

  return qc('div.resource_set.' + opts.type + (opts.noLabel ? '.no-label' : ''), [
    label,
    span
  ]).props({ addContent: x => span.kids([...span.kids(), x]), label, span })
}

module.exports = { rs }
