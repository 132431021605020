//IE not support on (1)fixed column, row (2)card resizing
const stylesheet = require('../stylesheet')
const Model = require('../data-model')

const { navigator } = require('./scheduler6-header')
const { schedulerBody } = require('./scheduler6-body')
const { _id } = require('../../_id')
const { qc } = require('../../cmp/qc')
const { mergeProps } = require('../../cmp/mergeProps')
const { _v } = require('../../_v')

// From ow.usersettings
// const toSchedulerJson = (scheduler, currentConfigurationSetting, currentColumnSetting) => {
//   var isFound
//   if (scheduler) {
//     if (scheduler.resources) {
//       if (scheduler.resources.length > 0) {
//         var schedulerSettingColumnSetting = {
//           iden: currentConfigurationSetting.id,
//           colPerPage: currentConfigurationSetting.colPerPage,
//           scheduledResourceOnly: currentConfigurationSetting.scheduledResourceOnly
//         }
//         schedulerSettingColumnSetting.excludedColumnSettings = []
//         var dataView = scheduler.resources[0].dataSource.view()
//         var dataSourceColumn = scheduler.resources[0].dataSource.data()
//         for (var j = 0; j < dataSourceColumn.length; j++) {
//           isFound = false
//           for (var i = 0; i < dataView.length; i++) {
//             if (dataView[i].value == dataSourceColumn[j].value) {
//               isFound = true
//               break
//             }
//           }
//           if (!isFound) {
//             schedulerSettingColumnSetting.excludedColumnSettings.push({
//               columnName: dataSourceColumn[j].value
//             })
//           }
//         }
//         //check in columnSetting but not in dataSourceColumn
//         if (currentColumnSetting) {
//           for (var x = 0; x < currentColumnSetting.length; x++) {
//             isFound = false
//             for (var y = 0; y < dataSourceColumn.length; y++) {
//               if (currentColumnSetting[x].columnName == dataSourceColumn[y].value) {
//                 isFound = true
//                 break
//               }
//             }
//             if (!isFound) {
//               //check from scheduler setting
//               if (schedulerSettingColumnSetting.excludedColumnSettings.length == 0) {
//                 schedulerSettingColumnSetting.excludedColumnSettings.push({
//                   columnName: currentColumnSetting[x].columnName
//                 })
//               } else {
//                 var foundFromResult = false
//                 for (
//                   var z = 0;
//                   z < schedulerSettingColumnSetting.excludedColumnSettings.length;
//                   z++
//                 ) {
//                   if (
//                     currentColumnSetting[x].columnName ==
//                     schedulerSettingColumnSetting.excludedColumnSettings[z].columnName
//                   ) {
//                     foundFromResult = true
//                     break
//                   }
//                 }
//                 if (!foundFromResult) {
//                   schedulerSettingColumnSetting.excludedColumnSettings.push({
//                     columnName: currentColumnSetting[x].columnName
//                   })
//                 }
//               }
//             }
//           }
//         }
//         return schedulerSettingColumnSetting
//       }
//     }
//   }
//   return undefined
// }

// const updateSchedulerSetting = (scheduler, schedulerSetting, currentConfigurationSetting) => {
//   var foundIndex = -1
//   var foundExcludedColumnSettings

//   var findSchedulerColumnSetting = function (setting, id) {
//     if (setting) {
//       for (var i = 0; i < setting.length; i++) {
//         if (setting[i].iden == id) {
//           return i
//         }
//       }
//     }
//     return -1
//   }
//   if (schedulerSetting) {
//     if (schedulerSetting.schedulers) {
//       foundIndex = findSchedulerColumnSetting(
//         schedulerSetting.schedulers,
//         currentConfigurationSetting.id
//       )
//     }
//     if (foundIndex != -1 && schedulerSetting.schedulers[foundIndex].scheduledResourceOnly) {
//       foundExcludedColumnSettings = schedulerSetting.schedulers[foundIndex].excludedColumnSettings
//     }
//   }

//   var schedulerJson = ow.userSetting.toSchedulerJson(
//     scheduler,
//     currentConfigurationSetting,
//     foundExcludedColumnSettings
//   )
//   if (schedulerJson) {
//     if (!schedulerSetting) {
//       schedulerSetting = {}
//     }
//     if (!schedulerSetting.schedulers) {
//       schedulerSetting.schedulers = []
//     }
//     // if (currentConfigurationSetting.scheduledResourceOnly != undefined){
//     //     schedulerSetting.scheduledResourceOnly = currentConfigurationSetting.scheduledResourceOnly;
//     // }
//     if (currentConfigurationSetting.isUpdateLast) {
//       schedulerSetting.defaultSelectedResType = currentConfigurationSetting.defaultSelectedResType
//     }
//     if (schedulerJson) {
//     }
//     if (foundIndex != -1) {
//       schedulerSetting.schedulers[foundIndex] = schedulerJson
//     } else {
//       schedulerSetting.schedulers.push(schedulerJson)
//     }
//   }
//   return schedulerSetting
// }

const schedulerProps = {
  val(cards) {
    const sche = this
    if (typeof cards !== 'undefined') {
      if (cards === sche.dataSet) return // if we are repopulating with same data

      if (sche.dataSet) Model.cancelChanges(sche.dataSet) // resets the change control
      sche.dataSet = cards

      sche.renderAsync()
    }
    return sche.dataSet
  }
}

/**
 * container
 * --Navigate panel
 * --Scheduler table
 * @param {*} props
 * @param {object} props.view
 * @returns
 */
const scheduler6 = props => {
  if (!props.id) props.id = 'scheduler-' + _id()
  props.tag = 'div#' + props.id + '.scheduler6.fit.focusable.'
  const me = qc(mergeProps(schedulerProps, props)).attr({ tabindex: '0' }).css({ outline: '0' })

  Model.modelBind(props.view, me, me.model)

  me.state = { populating: 0, current: { selectedDate: new Date(new Date().setHours(0, 0, 0, 0)) } }
  me.sorts = []

  const model = me.model
  const dataSet = _v(model, me.fieldName) || []
  me.val(dataSet)

  me.header = navigator(me)
  me.body = schedulerBody(me)
  me.kids([me.header, me.body])

  if (me.el) me.trigger('ow-scheduler-databound')

  me.bindState('state.current.selectedDate', function (selectedDate) {
    me.body.loadData(selectedDate)
    me.body.loadHeader(selectedDate)
  })

  return me
}

const sCss = [
  `
  #scope div.scheduler6 {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;      
  }
  #scope .ow-scheduler-panel {
    padding: 10px;
  } 
  #scope .ow-scheduler-context {
    overflow: auto;
    height: 100%;
  }   
  #scope table.scheduler6 {
    margin:0;
    border:none;
    background-color:#FFF;
    border-collapse:separate;
    border-spacing:0;
    border-left:1px solid #ccc;
  }
  /*#scope table.scheduler6 tbody tr {
    line-height: 20px;
  }*/
  #scope table.scheduler6 th {
    background:#777;
    border-right:1px solid #999;
    color:#FFF;
    padding:3px;
    position:sticky;
    top:0;
    /* ensure header row sits atop everything else when scrolling down */
    z-index:7001;
    /*min-width: 200px;*/
  }
  #scope table.scheduler6 td {
    background:#fff;
    border-right:1px solid #ccc;
    border-bottom:1px solid #ccc;
    padding: 0px;
  } 
  #scope table.scheduler6 td>span {
    position: relative; 
    display: flex; /* fix for FF margin shifted*/     
  }
  #scope table.scheduler6 td>span .card {
    padding: 0px;
    /*margin-left: 2px;*/
    background: aqua;
    position: absolute;
    margin-top: -10px; /* half line-height */
    resize: vertical; /* IE not compatible */
    overflow: hidden; /*set to visisble will not work for resize*/
  }  
  /* ensure first header cell sits atop everything else when scrolling right */
  #scope table.scheduler6 th:first-child {
    position:sticky; /* IE not compatible */
    left:0;
    z-index:7002;
  }
  /* make first column sticky when scrolling right */
  #scope table.scheduler6 td:first-child {
    position:sticky; /* IE not compatible */
    left:0;
    border-right-color:#aaa;
    z-index: 7001;
  }
  #scope table.scheduler6 tr td.hovered {
    background: #bfdbee;
    border: dashed 1px #000;
  }
  #scope .hold {
    opacity: .5   
  }  
  `
].join('')

stylesheet.addCss('scheduler6-css', sCss)

module.exports = { scheduler6 }
