// wireUpView7.js
const cmp = require('../cmp/cmp')

const { collectionController7 } = require('./collectionController7')
const { shortcutKeyHandler, buttonOpts } = require('./button7')
const { dc7 } = require('./dc7')
const { grid7 } = require('./grid7')
const { killEvent } = require('../killEvent')
const { tabstrip7 } = require('./tabstrip7')
const styles7 = require('./styles7')
const { hasChanges } = require('./data-model7')

/**
 * provides a cleaner scoped interface for views
 * adds styles and sets up various
 * @param {object} view
 * @returns
 */
const wireUpView7 = (view = { ow: ow0 }) => {
  view.ow = view.ow ?? ow0

  const result = {
    view,
    common,
    ...common.ow7,
    ...cmp,
    cmp,

    combo7(opts) {
      opts.view = view
      return common.ow7.combo7(opts)
    },

    multiSelect7(opts) {
      opts.view = view
      return common.ow7.multiSelect7(opts)
    },

    weekdays7(opts) {
      opts.view = view
      return common.ow7.weekdays7(opts)
    },

    button(opts) {
      return result.button7({
        ...buttonOpts(typeof opts === 'string' ? opts : opts.type ?? opts.name),
        ...(typeof opts === 'string' ? {} : opts),
        view
      })
    },

    footerBar7(opts, dsName, view = result.view) {
      return common.ow7.footerBar7(opts, dsName, view)
    },

    headerBar7(opts, dsName, view = result.view) {
      return common.ow7.headerBar7(opts, dsName, view)
    },

    tabstrip7,

    tabstrip: tabstrip7,

    $ajax(...args) {
      const opts = typeof args[0] === 'string' ? args[1] ?? { url: args[0] } : args[0]
      opts.view = view
      opts.LRTask = true
      return $ajax(opts)
    },

    grid7(opts) {
      opts.view = view
      return grid7(opts)
    },

    collectionController7(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return collectionController7(opts)
    },

    dc7(opts) {
      opts.LRTask = opts.LRTask ?? true
      opts.view = view
      return dc7(opts)
    },

    styles(scope) {
      return styles7(scope ?? view.qTop.el?.id ? '#' + view.qTop.el.id : undefined)
    }
  }

  view.afterInit = () => {
    const { qTop, viewWrap } = view

    if (view.viewdata) {
      view.viewdata.formCon = () => {} // prevent old editorCon and gridCon

      view.viewdata.requestClose =
        view.viewdata.requestClose ??
        function () {
          if (qTop.dc) view.model = qTop.dc.opts?.rec ?? qTop.dc.recs // view or grid

          if (!view.model) return true
          if (!hasChanges(view.model)) return true

          ow.confirm(
            view.viewdata.name.split('-')[0],
            __('Are you sure you want to discard changes and close form?')
          ).then(r => r && qTop.closeForm(true))
          return false
        }
    }

    viewWrap.on('keydown', shortcutKeyHandler(view))

    // prevent filter change propagating out from the view
    // especially from filters
    viewWrap
      .addClass('css7')
      .on('click keyup change ow-change', () => common.wait(50).then(() => viewWrap.renderAsync()))
      .on('change ow-change', e => killEvent(e))

    result.styles().renderTo(viewWrap.el)
    // combo7.styles().renderTo(qTop.el.parentElement)
  }
  return result
}

module.exports.wireUpView7 = wireUpView7
