const { html } = require('../cmp/html')
const theme = require('../theme')

// styles7.js
const styles7 = (scope = '.css7') =>
  html(`<style>
${scope} * { float: none ! important }
${scope} style { display: none ! important; }

${scope} .sticky-rows > * {
position: absolute;
left: 0;
right: 0;
}

${scope} .sticky-rows > .footer { bottom: 0; }

${scope} .sticky-cols > * {
position: absolute;
top: 0;
bottom: 0;
}

${scope} div.form-header {
overflow: hidden;
box-sizing: border-box;
display: block;
width: inherit;
}
/* 33% x 3 +  2 gaps of 0.5% = 100% */
${scope} .form_column { vertical-align: top; }

${scope} div.form-header > * {
vertical-align: top;
margin-left: 0.5%; 
width: 33%;
overflow: hidden;
height: 100%;
display: inline-block;
}

${scope} div.form-header.c1 > * { width: 100%; }
${scope} div.form-header.c2 > * { width: 49.5%; }
${scope} div.form-header.c3 > * { width: 33%; }
${scope} div.form-header.c4 > * { width: 24.624; }

${scope} div.form-header > *:first-child { margin-left: 0; }

${scope} .buttonset_left,
${scope} .buttonset_right { width: 50%; display: inline-block;  }
${scope} .buttonset_right { text-align: right; }

${scope} div.k-window-content { font-size: 12px; }

${scope} div.ow-buttonstrip {
  margin: 0;
  padding: 0;
  line-height: 1rem;
  height: 1.5rem;
  padding-top: 0.5rem;
}

${scope} div.ow-buttonstrip > label { line-height: 1rem; }

${scope} div.ow-buttonstrip > .ow-textbox { margin-top: 0; }

${scope} .ow-buttonstrip button {
  margin: 0;
  margin-right: 0.5rem;
  padding: 0 6px;
  line-height: 1.25rem;
  height: 1.5rem;
}

${scope} .ow-buttonstrip button i { display: inline-block; }

${scope} i.icon-blue,
${scope} i.fa,
${scope} i.icon { color: ${theme.iconBlue} }

${scope} i.icon-red,
${scope} .icon-red i.fa,
${scope} .icon-red i.icon { color: ${theme.iconRed} }

${scope} i.icon-green,
${scope} .icon-green i.fa,
${scope} .icon-green i.icon { color: ${theme.iconGreen} }

${scope} [disabled] i.fa,
${scope} [disabled] i.icon,
${scope} .ow-disabled i.fa,
${scope} .ow-disabled i.icon { color: #a6a6ad  }

${scope} .sticky-middle {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  top: 2.5rem;
  overflow-y: auto;
}

${scope} span.ow-ctl-wrap { display: inline-block; }
${scope} .input7.ow-ctl-wrap.ow-disabled { background: ${theme.grayBackgroundColor}; }
${scope} .input7.ow-ctl-wrap.ow-disabled input { background: transparent; }
${scope} i.text-item-icon { color: ${theme.iconBlue}; }

${scope} .check7[disabled] { pointer-events: none; }
${scope} .check7[disabled]::before { color: #e5e5e5; }

${scope} .check7:focus i {
  border-color: #1a87cd;
  -webkit-box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
  box-shadow: 0 0 0.154em 0 rgba(27, 66, 139, 1);
}

${scope} .multi-select7-wrap {
  padding: 1px;
  position: relative;
  max-height: 4.8rem;
  overflow-y: auto;
}
${scope} .multi-select7-wrap > input { width: 3rem; }
${scope} .ow-pill { 
  padding: 3px;
  border: 1px solid #ececec;
  border-radius: 3px;
  white-space: nowrap;
  margin: 1px;
}
${scope} .ow-pill > label { 
  display: inline-block;
  white-space: nowrap;
  max-width: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 9px;
}
${scope} .ow-pill i.icon { 
  margin: 0 2px;
  cursor: pointer;
}

.css7 ::-webkit-scrollbar { width: 8px; height: 8px; background: transparent; }
.css7 .grid7 .ow-grid-content::-webkit-scrollbar { width: 12px; }
.css7 ::-webkit-scrollbar-track { background: transparent; }
.css7 .grid7 .ow-grid-content::-webkit-scrollbar-track { background: #eaeaea; }
.css7 ::-webkit-scrollbar-thumb { background: #d2d2d7; border-radius: 5px; }
.css7 ::-webkit-scrollbar-thumb:hover { background: #d2d2d7; }
</style>`)

module.exports = styles7
