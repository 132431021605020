// structure.js

const { qc } = require('../cmp/qc')
const { killEvent } = require('../killEvent')
const { button6 } = require('./button6')

/**
 *
 * @param {object} props cmp props
 * @param {boolean} props.show - we should probably get rid of this!
 * @param {function} props.fireFilters - required, called when refilter triggers
 * @param {Object} content
 * @returns Cmp
 */
const filterPanel = (props, content) => {
  let r

  const button = button6({
    label: 'filter',
    name: 'Filter'
  })
    .addClass('filter-button')
    .css({ marginLeft: '10px' })
    .on('click', () => r.fireFilters())

  r = qc('div.filter-panel', [...content, button])
    .props({
      button,
      ...props
    })
    .on('keydown', e => {
      // F2 or enter
      if ((e.which === 113 || e.which === 13) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        r.fireFilters()
        return killEvent(e, false)
      }
    })
    .bindState('show', v => r.css({ display: v ? undefined : 'none' }))

  return r
}

const columnSet = (cls, children) => qc('div.form-header' + (cls ? '.' + cls : ''), children)

const formColumn = (colCount, children) =>
  qc('div.form_column', children).addClass(
    {
      2: 'form_column_half',
      4: 'form_column_quarter',
      c2: 'form_column_half',
      c4: 'form_column_quarter'
    }[colCount] || 'form_column_third'
  )

module.exports = { formColumn, columnSet, filterPanel }
