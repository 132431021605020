const { _v } = require('../_v')
const { initModel, $meta } = require('./data-model7')
const { recSorter } = require('./recSorter')

module.exports.groupKey = (groupByArray, rec, group = {}) =>
  groupByArray
    .map(f => {
      const v = _v(rec, f)
      _v(group, f, v)
      return (group[f] = v)
    })
    .map(v => (v === null ? 'null' : (v ?? '') + ''))
    .filter(s => s)
    .join('&')

const makeFooter = group => {
  const r = { ...group, footer: true }
  initModel(r, '', { _group: { ignore: true } })
  return r
}

module.exports.groupRows = currentFilter => {
  let { recs, groups = {}, groupByArray, groupByArray2, ags = {} } = currentFilter
  let sKey, group, rec, i, meta

  const oldGroups = groups
  let newGroups = {}

  for (i = 0; i < recs.length; i++) {
    rec = recs[i]

    sKey = module.exports.groupKey(groupByArray, rec, (group = {}))

    if (newGroups[sKey]) {
      group = newGroups[sKey]
    } else if (oldGroups[sKey]) {
      group = oldGroups[sKey]
      group._group.recs = []
      group._group.ags = { count: 0 } // reset ags
    } else {
      group._group = { key: sKey, ags: { count: 0 }, recs: [] }
      initModel(group, '', { _group: { ignore: true } }).reci = -1
    }
    newGroups[sKey] = group

    group._group.recs.push(rec)
    meta = $meta(rec)
    if (meta !== $meta(rec)) console.error('no meta')
    meta.group = group
    meta.groupKey = sKey

    // ags are registered in column definitions,
    // eg. groupFooter: 'sum'
    group._group.ags.count = group._group.ags.count + (rec._group?.ags.count ?? 1)
    let count = group._group.ags.count
    Object.keys(ags).forEach(f => {
      const agType = typeof ags[f] === 'string' ? ags[f].split(',') : ags[f]

      group._group.ags[f] = group._group.ags[f] ?? {}

      const v = _v(rec, f) ?? 0

      if (agType.includes('sum'))
        group._group.ags[f].sum = (group._group.ags[f].sum || 0) + (rec._group?.ags[f].sum ?? v)

      if (agType.includes('avg'))
        group._group.ags[f].avg =
          ((count - 1) * (group._group.ags[f].avg || 0) + (rec._group?.ags[f].sum ?? v)) / count

      if (agType.includes('max'))
        group._group.ags[f].max = Math.max(
          rec._group?.ags[f].max ?? v,
          group._group.ags[f].max ?? -999999999999999
        )

      if (agType.includes('min'))
        group._group.ags[f].min = Math.min(
          rec._group?.ags[f].min ?? v,
          group._group.ags[f].min ?? 999999999999999
        )
    })
  }

  let data = []
  Object.values(newGroups)
    .sort(recSorter(groupByArray))
    .forEach(group => {
      if (groupByArray2) {
        const currentFilter2 = module.exports.groupRows({
          recs: group._group.recs,
          groupByArray: [...groupByArray, ...groupByArray2],
          ags,
          oldGroups
        })

        group._group.recs = currentFilter2.groupsAndRecs.map(r => {
          if (r._group) {
            $meta(r).group = group
            r.parentGroup = group
          }
          return r
        })
        newGroups = { ...newGroups, ...currentFilter2.groups }
      }

      data = [...data, group, ...group._group.recs, makeFooter(group)]
    })

  currentFilter.groups = newGroups
  currentFilter.groupsAndRecs = [...data]
  currentFilter.recs = data

  return currentFilter
}
