const { qc } = require('../cmp/qc')
const { killEvent } = require('../killEvent')
const theme = require('../theme')
const { addCss } = require('./stylesheet')

/**
 * Create a cmp component representing a single toggle button.
 * @param {object} props
 * @property {string} props.label - String representing label for toggle button. Specify a blank string to represent no label.
 * @returns {object} - cmp toggle button component.
 */
const toggleBtn6 = props => {
  props.value = props.value || false

  const me = qc('a.toggle-btn6', props.label)
    .props({
      applyState(prevState = {}) {
        this.attr(
          this.disabled ? { disabled: true, href: undefined } : { disabled: undefined, href: '#' }
        )

        this.disabled ? this.wrap().addClass('ow-disabled') : this.wrap().removeClass('ow-disabled')

        this.value ? this.addClass('on') : this.removeClass('on')

        return prevState
      },
      val(v) {
        if (arguments.length)
          if (this.value !== v) {
            this.value = v
            this.value ? this.addClass('on') : this.removeClass('on')
          }

        return this.value
      }
    })
    .props(props)
    .on('click', e => {
      if (me.disabled) return killEvent(e, false)
      me.val(!me.value)
      setTimeout(() => me.trigger('ow-change'), 10)
    })
    .on('keypress', e => {
      // space
      if (e.which === 32) {
        me.el.click()
        return e && killEvent(e)
      }
    })

  me.wrap = () => me

  return me
}

/**
 * Creates a cmp component representing a toggle group, containing an arbitrary number of buttons.
 * @param {object} props - Properties for toggle group component.
 * @property {string[]} props.labels - Array of strings, each representing the label for each toggle button. Specify a blank string to represent no label. Order array elements in order you want buttons to display (left to right).
 * @returns {object} - cmp toggle group component.
 */
const toggleBtns6 = props => {
  const me = qc(
    'span.ow-ctl-wrap.ow-toggle-btns6',
    props.labels.map(label => {
      const btn = toggleBtn6({ label })
        .on('click', () => {
          me.value = btn.val() ? label : undefined
          me.renderAsync().then(() => me.trigger('ow-change'))
        })
        .bindState(
          () => me.value === label,
          function (v) {
            v ? this.addClass('on') : this.removeClass('on')
          }
        )
      return btn
    })
  ).props(props)

  return me
}

const sCss = `
#scope .grid6 .filterrow6 span.ow-toggle-btns6.ow-ctl-wrap {
  border: none;
  margin:0;
  display: inline-block;
}
#scope a.toggle-btn6 {
  border-style: solid;
  border-width: 1px;
  border-color: #ceced2;
  line-height: 1.65em;
  display: inline-block;
  padding: 0.154em 0;
  color: #515967;
  border-color: #ceced2;
  border-radius: 0.31em;
  min-width: 1.75em;
}
#scope a.toggle-btn6.on i.icon.icon6.text-item-icon {
  color: ${theme.iconBlue};
}
#scope a.toggle-btn6:hover {
  color: #263248;
  border-color: #bebec3;
  background-color: ${theme.buttonColor};
}
#scope a.toggle-btn6:focus {
  border-color: silver !important;
}
#scope a.toggle-btn6.on {
  font-weight: bold;
  border: solid 1px ${theme.iconBlue};
  background-color: #fff;
}
#scope a.toggle-btn6.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
#scope .resource_set .toggle-btn6-wrap {
  padding-left: 0;
}
#scope .resource_set > span > a.toggle-btn6 {
  line-height: 2.25em;
  padding: 0;
}
`
addCss('css-ow-toggle-btns6', sCss)

module.exports = {
  toggleBtns6
}
