const Model = require('../data-model')
const { openContextMenu } = require('../cmenu6')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')
const { html } = require('../../cmp/html')
const { _v } = require('../../_v')

const cardGap = 5

const calcSlotFromHeight = (rowHeight = 20, maxSlot, rowid, cardHeight) => {
  let snapToPx = rowHeight * Math.round(cardHeight / rowHeight)
  //validate
  if (rowid) {
    const maxHeight = maxSlot * rowHeight
    let newHeight = rowid * rowHeight + snapToPx
    if (newHeight > maxHeight) snapToPx = maxHeight - rowid * rowHeight
  }

  return snapToPx / rowHeight
}

const resizeCard = (card, scheduler, cardDiv, model) => {
  let { siblingId, siblingCount } = card
  const rowHeight = scheduler.rowHeight

  const unitOfWidth = (scheduler.cols.colWidth - (siblingId + 1) * cardGap) / siblingCount

  cardDiv.css({
    height: rowHeight * (model.slot || 1) + 'px',
    width: unitOfWidth + 'px',
    left: siblingId * unitOfWidth + (siblingId + 1) * cardGap + 'px',
    marginTop: (rowHeight / 2) * -1 + 'px'
  })
}

const card = props => {
  let { model, scheduler, resizable } = props
  const { interval } = scheduler
  // let { slot = 1 } = model
  const { maxSlot = (60 * 24) / interval, rowid } = resizable

  const me = qc('span.card-wrap')
    .props({
      interval,
      rowid,
      maxSlot,
      ...props
    })
    .attr({ draggable: 'true' })
    .on('click', () => console.warn('card-click'))
    .on('dblclick', () => console.warn('card-click'))
    .on('dragstart', e => {
      scheduler.draggingCard = me
      me.kids()[0].addClass('hold')
      e.dataTransfer.setDragImage(cardDiv.el, 0, 0)
    })
    .on('dragend', () => {
      //warn('card-dragend')
      me.kids()[0].removeClass('hold')
    })
    .on('model-change', function () {
      cardDiv.kids([qc('span', val())])
    })
  me.state = {}
  Model.modelBind(scheduler.view, me, model)

  let val = () =>
    scheduler.template ? scheduler.template.call(me, model) : html(_v(model, me.fieldName) || '')

  const cardDiv = qc('div.card', qc('span', val()))
    .on('context-menu', e => {
      openContextMenu(e.target, {
        view: scheduler.view,
        point: {
          left: e.pageX, // - $offset(p).left,
          top: e.pageY // - $offset(p).top
        },
        setWidth: false,
        content() {
          let res = qc('ul', scheduler.eventMenu(model) || [])
          res.model = model
          return res
        }
      })
      return killEvent(e)
    })
    .on('mouseup', function () {
      model.slot = calcSlotFromHeight(scheduler.rowHeight, maxSlot, rowid, cardDiv.el.offsetHeight)
      Model.react(scheduler.view, model, 'slot')
    })
    .bindState(
      () => scheduler.rowHeight, // in case of view resize
      () => resizeCard(me, scheduler, cardDiv, model)
    )
    .bindState(
      () => me.siblingId,
      () => resizeCard(me, scheduler, cardDiv, model)
    )
    .bindState(
      () => me.siblingCount,
      () => resizeCard(me, scheduler, cardDiv, model)
    )
    .bindState(
      () => model.slot,
      () => resizeCard(me, scheduler, cardDiv, model)
    )
  me.cardDiv = cardDiv
  me.kids([cardDiv])

  return me
}

module.exports = {
  card
}
