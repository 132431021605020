const { check7 } = require('./check7')
const { $meta } = require('./data-model7')

// const groupSelector = (qGrid, group, fieldName = 'Selected') => {
//   const myRecs = () =>
//     qGrid.dc.currentFilter.groupsAndRecs.filter(
//       r =>
//         r.parentGroup === group ||
//         $meta(r)?.group === group ||
//         $meta(r)?.group?.parentGroup === group
//     )

//   const chk = qc('i.icon', html(iconCodes.check))
//     .css({
//       display: 'inline',
//       cursor: 'pointer',
//       padding: '2px',
//       borderRadius: '4px',
//       color: theme.textColor,
//       backgroundColor: theme.backgroundColor,
//       border: '1px solid #c5c5c5'
//     })
//     .css({ cursor: 'pointer' })
//     .on('click', () => {
//       group[fieldName] = !group[fieldName]
//       if (!group[fieldName]) delete group[fieldName]
//       myRecs().forEach(r => (group[fieldName] ? (r[fieldName] = true) : delete r[fieldName]))
//       qGrid.renderAsync()
//     })
//     .bindState(
//       () => {
//         const recs = myRecs()
//         return (group[fieldName] = recs.length && !recs.find(x => !x._group && !x[fieldName]))
//       },
//       (v, me) => me.css({ color: v ? theme.iconBlue : 'transparent' })
//     )

//   return chk
// }

module.exports.groupSelector = (qGrid, group, fieldName = 'Selected') => {
  const chk = check7({ model: group, fieldName })
    .css({ cursor: 'pointer' })
    .on('ow-change', () => {
      if (!group[fieldName]) delete group[fieldName]
      group._group.recs.forEach(r =>
        group[fieldName] ? (r[fieldName] = true) : delete r[fieldName]
      )
      qGrid.renderAsync()
    })
    .bindState(() => {
      return (group[fieldName] = group._group.recs.length
        ? !group._group.recs.find(x => !x._group && !x[fieldName])
        : false)
    })

  return chk
}
