const { __ } = window
const { qc } = require('../cmp/qc')
const { dropdown } = require('./dropdown')

/**
 *
 *
 * @param {object} props
 * @param {int} props.combo - the cmp controlling this
 * @param {int} props.listWidth
 *
 */
const comboDropdown = props => {
  return dropdown({
    ...{
      buildList(loading) {
        const { combo, itemTemplate } = this

        let list = []
        let i = 0
        for (; i < combo.matchList.length && i < 100; i++) list.push(combo.matchList[i])

        return qc(
          'div.combo-list-popup',
          qc(
            'ul',
            !list.length
              ? qc('li', loading ? __('Loading...') : __('No Matches Found'))
              : list.map(itemTemplate).map((x, i) =>
                  qc('li', x)
                    .on('mousedown', function () {
                      combo.el.focus()
                      const item = list[i]
                      setTimeout(() => {
                        combo.selectItem(item, true)
                        combo.el.focus()
                      }, 10)
                    })
                    .bindState(
                      () => i === combo.listIndex,
                      function (v) {
                        if (v) this.addClass('ow-selected')
                        else this.removeClass('ow-selected')
                      }
                    )
                )
          ).css({ 'list-style-type': 'none' })
        )
      },

      /**
       * Adds list content to an existing dropdown element.
       */
      updateContent() {
        const { combo } = this
        combo.listIndex = combo.listIndex || 0
        // el.innerHTML = ''
        this.kids(this.buildList())
        this.renderAsync()
        // this.setPosition()
      }
    },
    ...props
  })
}

module.exports = {
  comboDropdown
}
