const { addCss } = require('./stylesheet')
const { $find } = require('../no-jquery')
const { $cmp } = require('../cmp/$cmp')
const { qc } = require('../cmp/qc')

const calcSubWindowPosition = (viewParent, dropdownEl, inputRectOrPoint) => {
  let vpRect = viewParent.getBoundingClientRect()
  let ddRect =
    dropdownEl && dropdownEl.parentElement
      ? dropdownEl.getBoundingClientRect()
      : { height: 80, width: 50 }

  inputRectOrPoint.height = inputRectOrPoint.height || 0
  inputRectOrPoint.width = inputRectOrPoint.width || 0

  let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  if (typeof visualViewport !== 'undefined') {
    vw = visualViewport.width
    vh = visualViewport.height
  }

  const ddRight = inputRectOrPoint.left + ddRect.width
  let tooFarRight = ddRight > vw // document.body.clientWidth
  // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
  const left =
    inputRectOrPoint.left - vpRect.left + (tooFarRight ? -ddRect.width + inputRectOrPoint.width : 0)

  const ddBottom = inputRectOrPoint.top + inputRectOrPoint.height + ddRect.height

  let bottomTooLow = ddBottom > vh

  // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
  const top =
    inputRectOrPoint.top - vpRect.top + (bottomTooLow ? -ddRect.height : inputRectOrPoint.height)

  return { left, top }
}

const closeDropdowns = el =>
  Array.from($find(el, '.ow-dropdown-open')).forEach(x => {
    const dd = $cmp(x).dropdown
    dd && dd.close()
  })

const dropdownProps = {
  setPosition() {
    const viewParent = this.combo.view?.qTop?.el.parentElement ?? document.body

    let inputRect = this.combo.el.parentElement.getBoundingClientRect()
    let pos = calcSubWindowPosition(viewParent, this.el, inputRect)

    this.css({ left: pos.left + 'px', top: pos.top + 'px' })
  },

  close() {
    const { combo } = this
    const { el } = combo
    if (el) {
      combo.removeClass('ow-dropdown-open')
    }
    if (combo.dropdown) {
      combo.dropdown.el.remove()
      delete combo.dropdown
    }
  },

  updateContent() {
    // combo.listIndex = combo.listIndex || 0
    this.kids(['No Content Found'])
    this.setPosition()
  }
}

const dropdown = props => {
  props.tag = props.tag || 'div.ow-ctl-dropdown'
  const me = qc({ ...dropdownProps, ...props })

  me.on('focusin', () => me.combo.el.focus()) // set the focus back to the input.
    .on('click', e => {
      if (me.el === e.target) me.combo.el.focus() // set the focus back to the input.
    })
  // .on('mousedown', (e) => {
  //   if (!$is(e.target, 'li')) return
  //   const { combo } = me
  //   combo.el.focus()
  //   combo.listIndex = $index(e.target)
  //   combo.selectItem(combo.matchList[combo.listIndex])
  //   if (combo.dropdown) me.close()
  //   setTimeout(() => combo.el.focus(), 10)
  // })

  let p = props.combo.view?.qTop?.el?.parentElement ?? document.body
  if (props.listWidth) {
    let w = props.listWidth
    if (w === 1) w = me.combo.el.parentElement.offsetWidth
    me.css({ width: w + 'px' })
  }

  const getInputRect = () => me.combo.el.parentElement.getBoundingClientRect()

  me.bindState(
    () => getInputRect().top,
    () => me.setPosition()
  )
    .bindState(
      () => getInputRect().right,
      () => me.setPosition()
    )
    // .bindState(
    //   () => me.el && me.el.getBoundingClientRect().height,
    //   () => me.setPosition()
    // )
    // .bindState(
    //   () => me.el && me.el.getBoundingClientRect().width,
    //   () => me.setPosition()
    // )
    .on('init', () => setTimeout(() => me.setPosition(), 50))

  // // if there's already a dropdown showing, hide it
  const alreadyOpen = p.querySelector('.ow-ctl-dropdown')
  if (alreadyOpen) $cmp(alreadyOpen).close()

  return me
}

const css = {
  '#scope div.ow-ctl-dropdown': {
    position: 'absolute',
    minWidth: '60px',
    padding: '0.14em',
    border: 'solid 0.077em #bbb',
    overflow: 'hidden',
    zIndex: 999,
    backgroundColor: '#fff',
    boxShadow: '#ccc 0.14em 0.14em 0.3em 0em',
    boxSizing: 'border-box',
    maxHeight: '270.72px',
    overflowY: 'auto',
    minHeight: '2em'
    // width: '300px'
  }
}

addCss('css-dropdown', css)

module.exports = { calcSubWindowPosition, closeDropdowns, dropdown }
