const ow7 = (module.exports.ow7 = {})

ow7.styles7 = require('./styles7')
Object.assign(ow7, require('./cmenu7'))
Object.assign(ow7, require('./filters'))

Object.assign(ow7, require('./databind7'))
Object.assign(ow7, require('./data-model7'))
Object.assign(ow7, require('./dc7'))
Object.assign(ow7, require('./collectionController7'))

Object.assign(ow7, require('./gridPager7'))
Object.assign(ow7, require('./rs7'))
Object.assign(ow7, require('./check7'))
Object.assign(ow7, require('./text7'))
Object.assign(ow7, require('./combo7'))
Object.assign(ow7, require('./float7'))
Object.assign(ow7, require('./int7'))
Object.assign(ow7, require('./date7'))
Object.assign(ow7, require('./datetime7'))
Object.assign(ow7, require('./time7'))
Object.assign(ow7, require('./button7'))
Object.assign(ow7, require('./fileUpload7'))
Object.assign(ow7, require('./toggleBtns7'))
Object.assign(ow7, require('./multiSelect7'))
Object.assign(ow7, require('./grid7'))

Object.assign(ow7, require('./footerBar7'))
Object.assign(ow7, require('./tabstrip7'))
Object.assign(ow7, require('./headerBar7'))

Object.assign(ow7, require('./wireUpOverview7'))
Object.assign(ow7, require('./wireUpView7'))
