/**
 * returns 24 char unique ID,
 * if no crypto module then 11 chars represent time and the rest are random
 */
const _id = () =>
  typeof crypto !== 'undefined' && crypto.randomUUID
    ? crypto.randomUUID().split('-').join('')
    : hex(new Date().getTime()) + '             '.replace(/./g, () => hex(Math.random() * 16))

const hex = v => Math.floor(v).toString(16)

module.exports._id = _id
