// Also used on client-side in ow.ejs

console.log('/lib/dates.js MODIFYING Date.prototype!!!')

function removeISOTZ(isoDateString) {
  return isoDateString.split('T').join(' ').split('Z').join('')
}

/**
 * timezone of date - remember this depends on time of year of the date (daylight saving)
 */
Date.prototype.tz = function () {
  return this.getTimezoneOffset() * 60000 // in milliseconds
}

/**
 * timezone of date in hours (note, sometimes it's 7.5 etc)
 */
Date.prototype.tzh = function () {
  return this.tz() / 3600000
}

/**
 * Outputs ISO string but removes the timezone offset for timezone independence
 */
Date.prototype.toISOStringNoTZ = function () {
  var d = this
  return (
    [d.getFullYear(), ('0' + (d.getMonth() + 1)).substr(-2), ('0' + d.getDate()).substr(-2)].join(
      '-'
    ) +
    'T' +
    [
      ('0' + d.getHours()).substr(-2),
      ('0' + d.getMinutes()).substr(-2),
      ('0' + d.getSeconds()).substr(-2) + (1000 + d.getMilliseconds()).toString().replace('1', '.')
    ].join(':')
  )

  // return new Date(this.getTime() - this.tz()).toISOString();
  //    return new Date( this.toISOString().split("T").join(" ").split("Z").join("") ).toISOString();
}

Date.prototype._toJSON = Date.prototype.toJSON // allow access to original

Date.prototype.toJSON = function () {
  if (this.useTZ) {
    return this.toISOString()
  }
  return removeISOTZ(this.toISOStringNoTZ())
}

Date.today =
  Date.today ||
  function () {
    return new Date(new Date().toISOStringNoTZ().split('T')[0] + ' 00:00:00.000')
  }

// for IE, we need to override this so it accepts the noTZ string "2017-05-11 14:25:08.000"
if (isNaN(Date.parse('2017-05-11 14:25:08.000'))) {
  console.log('Replacing Date.parse, old version now Date._parse')
  Date._parse = Date.parse
  Date.parse = function (v) {
    if (v && typeof v === 'string' && v.split(' ').length === 2) {
      v = v.split(' ').join('T') + 'Z'
      var d = new Date(Date.parse(v))
      return d.valueOf() + d.tz()
    }
    return this._parse.apply(this, arguments)
  }

  var bind = Function.bind
  var unbind = bind.bind(bind)

  function instantiate(constructor, args) {
    return new (unbind(constructor, null).apply(null, args))()
  }

  console.log('Replacing Date constructor. Now you can call new Date("2018-01-10 16:45:20.000");')
  Date = (function (Date) {
    var _Date = Date
    MyDate.prototype = Date.prototype

    // Our static methods
    Object.keys(Date).forEach(p => (MyDate[p] = Date[p]))

    // System static methods
    MyDate.parse = _Date.parse
    MyDate.UTC = _Date.UTC
    MyDate.now = _Date.now

    return MyDate

    function MyDate(v) {
      if (arguments.length === 1 && typeof v === 'string' && v.split(' ').length === 2) {
        v = v.split(' ').join('T') + 'Z'
        var d = new Date(Date.parse(v))
        v = d.valueOf() + d.tz()
        return instantiate(Date, [v])
      }
      return instantiate(Date, arguments)
    }
  })(Date)

  // var _Date = Date;
  // var _DateP = Date.prototype;
  // Date = function(v) {
  //     this.__proto__ = _DateP;
  //     if (v && (typeof v === 'string') && v.split(' ').length === 2) {
  //         _Date.apply(this, Date.parse(v));
  //     } else {
  //         _Date.apply(this, arguments);
  //     }
  // }
  // Date.prototype = _DateP;
}

Date.prototype.toHHMM = function () {
  return this.toISOStringNoTZ().split('T')[1].substr(0, 5)
}
Date.prototype.parseHHMM = function (s) {
  try {
    // warning : some unexpected behaviours might occur if the date context is not provided in daylight savings countries.
    return new Date(
      this.tz() + (parseInt(s.split(':')[0]) * 60 + parseInt(s.split(':')[1])) * 60 * 1000
    )
  } catch (e) {
    throw 'Invalid HHMM format ' + s + ' : ' + e
  }
}
Date.prototype.removeTime = function () {
  return new Date(this.toISOStringNoTZ().split('T')[0] + ' 00:00:00')
}
