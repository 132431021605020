const { dataBind } = require('./databind7')
const { ctlFormatters, ctlParsers } = require('../ctl-format')
const { input7 } = require('./text7')
const { filterFunctions } = require('./filters')

const isNumeric = f => !isNaN(parseInt(f)) && isFinite(f)

const int7 = opts => {
  const me = input7(opts)
    .addClass('int7')
    .on('input', () => {
      var validChars = Array.prototype.reduce.call(
        '-0123456789',
        (t, c) => {
          t[c] = 1
          return t
        },
        {}
      )
      let value = Array.prototype.filter.call(me.el.value, x => x in validChars).join('')

      if (value !== me.el.value) {
        let pos = me.el.selectionStart
        let len = me.el.value.length - value.length
        me.el.value = value
        me.el.selectionStart = pos - len
        me.el.selectionEnd = pos - len
      }
      me.val(value === '' ? undefined : parseInt(value))
    })
    .on('blur', () => {
      me.val(me.el.value ? parseInt(me.el.value) : undefined)
    })
    .on('keyup', e => {
      if (e.which === 13 && !e.shiftKey) me.val(me.el.value ? parseInt(me.el.value) : undefined)
    })

  me.opts = opts

  var label = opts.label
  const placeholder = opts.placeholder || label || ''
  me.attr({ placeholder })

  const formatter = ctlFormatters.int
  const parser = ctlParsers.int

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  const props = {
    val(v, model, populating) {
      if (arguments.length) {
        var s
        var fmt = opts.format ?? 'n0'
        s = (v ?? null) === null ? '' : formatter(v, fmt)

        let hasChanged = false
        if (me.model && me.opts?.fieldName && ow._v(me.model, me.opts?.fieldName) !== v)
          hasChanged = true

        if (s !== me.value() && me.el !== document.activeElement) me.value(s)

        me.model && me.opts?.fieldName && ow._v(me.model, me.opts?.fieldName, v)

        hasChanged && !populating && me.trigger('ow-change')
      }

      v = me.attr('value')
      v = parser(v, fmt) ?? undefined

      return v
    },

    validate(onInvalid, messageArray) {
      var el = me.el
      var name = opts.label || opts.name || opts.fieldName

      messageArray = typeof messageArray === 'undefined' ? [] : messageArray
      var v = el.val()
      var hasValue = !(
        typeof v === 'undefined' ||
        v === null ||
        v === '' ||
        (typeof v === 'string' && v.trim() === '')
      )
      if (
        (opts.required || (opts.required !== false && opts.schema && opts.schema.required)) &&
        !hasValue
      ) {
        if (onInvalid) {
          onInvalid(name, 'must have a value', el, messageArray)
          return false
        }
      }
      if (typeof v === 'number' && isNaN(v) && opts.edType === 'lookup') {
        if (onInvalid) {
          onInvalid(name, 'must select from the list.', el, messageArray)
          return false
        }
      }
      opts.minLength = opts.minLength || opts.min
      opts.maxLength = opts.maxLength || opts.max

      if (typeof v === 'string' && opts.minLength && opts.minLength < (v || '').length) {
        if (onInvalid)
          onInvalid(name, 'must be have min length of ' + opts.minLength, el, messageArray)
        return false
      }
      if (typeof v === 'string' && opts.maxLength && opts.maxLength < (v || '').length) {
        if (onInvalid)
          onInvalid(name, 'must be have max length of ' + opts.maxLength, el, messageArray)
        return false
      }

      if (opts.validation && typeof opts.validation === 'object') {
        if (opts.validation.ne !== undefined && v === opts.validation.ne) {
          if (onInvalid) onInvalid(name, 'cannot be ' + opts.validation.ne, el, messageArray)
          return false
        }
      }

      // Validation for fields with from value and to value
      var fromField, toField

      if (opts.isToField && opts.fromID) {
        var $from = no$(el.closest('.win-con, body')).find(
          '#' + (opts.edType === 'int' || opts.edType === 'int' ? 'txt' : 'dp') + opts.fromID
        )

        if ($from.length > 0) {
          toField = typeof el.val() === 'number' ? el.val().toString() : el.val()
          fromField =
            typeof $from[0].val() === 'number' ? $from[0].val().toString() : $from[0].val()

          if (isNumeric(fromField) && isNumeric(toField)) {
            fromField = fromField * 1
            toField = toField * 1
          }
          if (fromField > toField) {
            if (onInvalid) {
              onInvalid(
                opts.fromID + ' field',
                __('From field must be always lower than to field'),
                el,
                messageArray
              )
              return false
            }
          }
        }
      }

      return true
    },

    readFilter(filters) {
      const { opts } = this
      let v = this.val()

      // this.isSet = true // indicates to the outside that there's a filter set on this field/col

      this.op || (this.op = this.opts.op ?? 'eq')

      const operator = this.op

      var filter = {
        field: opts.fieldName,
        operator,
        value: v
      }

      if (opts.filterMap && filter.value) {
        const s = filter.value.toLowerCase()
        const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

        filter.value = Object.keys(opts.filterMap)
          .filter(matchFilter)
          .map(k => opts.filterMap[k])

        if (filter.value.length === 0) {
          ow.popInvalid(__('Invalid value for ' + filter.field))
          this.value('')
          return
        }
        filter.operator = 'in'
      }

      if (filter.value !== undefined || common.ow7.allowFilterUndefined[filter.operator])
        filters.push(filter)
    },

    defOp: 'eq'
  }

  me.props(props)

  me.on('init', (e, el) => {
    el.opts = opts
  })

  return me
}

module.exports = { int7 }
