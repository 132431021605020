const { time6, datetime6, date6, grid6, check6, combo6, text6, input6, react, modelBind, button6 } =
  require('./ow6').ow6

module.exports.wireUpView6 = (view = {}) => {
  view.controls = {
    // addCss,

    button6(opts) {
      opts.view = view
      return button6(opts)
    },

    grid6(opts) {
      opts.view = view
      return grid6(opts)
    },

    check6(opts) {
      opts.view = view
      return check6(opts)
    },

    time6(opts) {
      opts.view = view
      return time6(opts)
    },

    text6(opts) {
      opts.view = view
      return text6(opts)
    },

    datetime6(opts) {
      opts.view = view
      return datetime6(opts)
    },

    date6(opts) {
      opts.view = view
      return date6(opts)
    },

    input6(opts) {
      opts.view = view
      return input6(opts)
    },

    combo6(opts) {
      opts.view = view
      return combo6(opts)
    }
  }

  view.react = (...args) => react(view, ...args)
  view.modelBind = (...args) => modelBind(view, ...args)

  view.ow = ow0

  return view
}
