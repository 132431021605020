module.exports = `
#scope a, 
#scope i.fa, 
i.icon6 {
  cursor: pointer;
}

#scope .combo-list-popup ul li {
  cursor: pointer;
  min-height: 1.2em;
}
#scope .combo-list-popup ul li.ow-selected {
  background-color: #4d90fe;
  color: #fff;
}
#scope .combo-list-popup ul li:hover {
  background-color: #d7d9dd;
}
#scope .combo-list-popup ul li {
  padding: 0.3em;
}
#scope .text-icon-before input,
#scope .text-icon-after input,
#scope .text-icon-before i,
#scope .text-icon-after i,
#scope .text-icon-after div.progress {
  display: inline-block;
}
#scope .text-icon-before i,
#scope .text-icon-after i {
  margin-left: -1.5em;
  padding: 0 0.14em;
  font-size: 1.1em;
}
#scope .text-icon-after div.progress {
  margin-left: -2.4em;
  padding: 0 0.14em;
  font-size: 1.1em;
  width: 1.9em;
  font-weight: bold;
}
#scope .text-icon-after div.progress.hide {
  visibility: hidden;
}

#scope .resource_set .ow-ctl-wrap,
#scope .ow-ctl-wrap.w2 {
  width: 12.4em;
}
#scope .ow-ctl-wrap.w4 {
  width: 25.1em;
  max-width: 100%;
}
#scope #scope .ow-ctl-wrap.w3 {
  width: 18.52em;
  max-width: 100%;
}
#scope .ow-ctl-wrap.w1 {
  width: 6.2em;
}
#scope .ow-ctl-wrap.short {
  display: inline-block;
  max-width: 49%;
}
#scope .ow-buttonstrip {
  margin-bottom: 0;
}
#scope .ow-buttonstrip > span.ow-ctl-wrap {
  display: inline-block;
  line-height: 1em;
  height: 2.2em;
}
#scope .ow-ctl-wrap.short.ow-date-wrap {
  display: inline-block;
  width: 9.2em;
  margin-right: 0.14em;
}
/* This is for purchase order forms */
#scope .textarea.w4 {
  width: 24.8em !important;
  height: 6.9em;
}
#scope .static-text {
  background-color: transparent !important;
}
#scope .ow-ctl-wrap > textarea,
#scope .ow-ctl-wrap > input {
  display: block;
  border: none;
  color: #555;
  box-sizing: border-box;
  outline: none;
}
#scope .ow-ctl-wrap textarea {
  height: 100%;
  width: 100%;
  padding: 0.32em;
}
#scope .ow-ctl-wrap textarea:focus {
  outline: none;
}
#scope .ow-ctl-wrap.ow-textarea-wrap {
  height: 6em;
  overflow: hidden;
}
#scope .ow-ctl-wrap {
  padding-top: 0.15em;
  padding-left: 0.15em;
}
#scope .resource_set .ow-ctl-wrap {
  height: 2.2em;
}
#scope .resource_set .ow-ctl-wrap.ow-textarea-wrap {
  height: unset;
}
#scope .ow-ctl-wrap.text-icon-before > input {
  display: inline-block;
  width: 100%;
  padding-left: 2em;
}
#scope .ow-ctl-wrap.text-icon-after > input,
#scope .ow-ctl-wrap.ow-fileupload-wrap.text-icon-after label {
  display: inline-block;
  width: 100%;
  padding-right: 2em;
}
#scope .ow-ctl-wrap.ow-fileupload-wrap.text-icon-after label {
  text-overflow: ellipsis;
}
#scope .ow-ctl-wrap.ow-fileupload-wrap.text-icon-after label,
#scope .ow-ctl-wrap.ow-fileupload-wrap.text-icon-after i,
#scope .ow-ctl-wrap.ow-fileupload-wrap.text-icon-after div.progress-bar {
  overflow: hidden;
}
#scope .ow-ctl-wrap.text-icon-before i,
#scope .ow-ctl-wrap.text-icon-after i {
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 0.8em;
  margin-left: -1.5em;
  line-height: 1.5em;
  padding: 0 0.14em;
  font-size: 1.1em;
}
#scope .ow-ctl-wrap.text-icon-before i {
  margin-left: 0em;
  margin-right: -1.5em;
}
#scope .ow-ctl-wrap > input {
  padding: 0;
  width: 100%;
}
#scope .ow-ctl-wrap.text6 {
  border-color: #dedee0;
  background-color: #fff;
}
#scope .text6 {
  border: solid 1px;
  box-sizing: border-box;
}
#scope .k-state-disabled .text6,
#scope .ow-disabled .ow-ctl-wrap.text6,
#scope .ow-disabled .ow-ctl-wrap input.text6 {
  background-color: #f3f3f4;
}
#scope .resource_set.static .text6 {
  background-color: #f3f3f4;
}
#scope td .text6,
#scope th .text6 {
  border: none;
  box-sizing: border-box;
}
#scope .text6 input {
  text-indent: 0.33em;
  height: 1.7em;
  box-sizing: border-box;
}
#scope .ow-ctl-wrap.text6 {
  border-radius: 0.31em;
}
#scope .ow-fileupload-wrap input[data-role='upload'] {
  opacity: 0;
  cursor: pointer;
}
#scope .ow-fileupload-wrap .input-file-trigger {
  display: block;
  color: #555;
  transition: all 0.4s;
  cursor: pointer;
  text-indent: 0.33em;
  height: 1.7em;
  box-sizing: border-box;
}
/* Adjust size for dropdowns outside .win-con */
#scope .ow-ctl-dropdown {
  font-size: 0.9em;
}
#scope .buttonset_left .button6 {
  margin-right: 0.5em;
}
#scope .buttonset_right .button6 {
  margin-left: 0.5em;
}
`
