const { gridCell } = require('./grid-cell')
const { $is } = require('../../no-jquery')

const { $meta, hasChanges, isNewBlank, modelBind } = require('../data-model')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')
const { $cmp } = require('../../cmp/$cmp')

const suppressPageUpPageDown = e => !(e.which === 33 || e.which === 34) && killEvent(e, false)

const gridRow = (grid, rec, fi) => {
  const meta = $meta(rec)
  const reci = meta.reci // also grid.dataSet.indexOf(rec)

  const me = qc(
    'tr.row' + (reci !== undefined ? '.reci-' + reci : ''),
    grid.cols.filter(col => col.gridCol !== false).map(col => gridCell(grid, col, rec, fi))
  )
    .props({ grid, rec, fi })
    .on('keypress', e => suppressPageUpPageDown(e))
    // gridRow.on('keyup', (e) => { if (e.which === 27) grid.cancelChanges(qc(this)) }) // removed as per issue: 11505
    .on('keyup', e => {
      if (e.which === 33) grid.pageUp()
      else if (e.which === 34) grid.pageDown()
    })
    .on('keydown', e => {
      // suppressPageUpPageDown
      if (e.which === 33 || e.which === 34) return killEvent(e)

      // tabKeySetTabIndexAddRowOnFocus (depends on row isNewBlank) tab
      if (e.which === 9) {
        const tabindex = isNewBlank(rec) ? '-1' : '0'
        grid.body.addRowOnFocusInput.attr({ tabindex })
      }

      // F2 - saves
      if (e.which === 113 && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        if (grid.editable === false) {
          me.trigger('command-' + (grid.viewdata.mode === 'select') ? 'select' : 'edit')
          return killEvent(e)
        }

        if (grid.live) {
          // first apply current control editing
          setTimeout(() => grid.saveRow(me.el), 50)
          return killEvent(e)
        }
        // else allow to bubble up
      }

      // up and down arrow // 'tr td input, tr td a.check6, tr td span'
      if (
        $is(e.target, 'td input, td a.check6, td span, tr, td') &&
        (e.which === 38 || e.which === 40)
      ) {
        // todo: manage nicer scrolling if the new row is slightly off-screen (shift scroll only 1 line)

        const isUp = e.which === 38
        let fi = grid.dataView.indexOf(grid.dataSet[grid.state.current.reci])
        let foundRow, moreToCome
        do {
          fi = fi + (isUp ? -1 : 1)
          let r = grid.dataView[fi]
          if (r && !r.isGroup) foundRow = true
          moreToCome = isUp ? fi > 0 : fi < grid.dataView.length - 1
        } while (moreToCome && !foundRow)

        if (!foundRow && !moreToCome && !isUp) {
          if (!isNewBlank(grid.dataSet[grid.state.current.reci])) {
            grid.addRow()
            return killEvent(e, false)
          }
        }

        if (foundRow) {
          // grid.el.focus()
          let tr = getRowCmp(grid, grid.dataView[fi], false)
          if (tr) {
            const coli = grid.state.current.coli || 0
            let td = tr.kids().find(x => x.coli === coli)
            if (td.el) grid.focusCell(td.el)
          }
          return killEvent(e, false)
        }

        return killEvent(e, false)
      }
    })

  if (!rec.isGroup) {
    me.model = meta.parent.model
    me.fieldName = meta.name
    modelBind(grid.view, me, grid.dataSet)
    me.bindState(
      () => hasChanges(rec),
      value => (value ? me.addClass('ow-dirty') : me.removeClass('ow-dirty'))
    )
      .bindState(
        () => meta.deleted,
        value => (value ? me.addClass('ow-deleted-row') : me.removeClass('ow-deleted-row'))
      )
      .bindState(
        () => grid.state.current.reci === reci,
        v => (v ? me.addClass('ow-current-row') : me.removeClass('ow-current-row'))
      )
      .bindState('fi', function (fi) {
        const alt = fi % 2
        if (alt) this.addClass('ow-alt')
        else this.removeClass('ow-alt')
      })
  }

  if (rec.isGroup) me.addClass(rec.footer ? 'ow-group-footer' : 'ow-group-header')

  me.on('dblclick', () => grid.rowEdit(rec))

  return me
}

const getDataForRow = tr => $cmp(tr).rec

const getRowCmp = (grid, rec, createIfNull = true) => {
  const index = grid.dataView.indexOf(rec)
  if (index === -1 || index === undefined) return

  let tr =
    grid.body.tBody.kids().find(tr => {
      return tr.rec === rec
    }) || null

  if (!tr && createIfNull) tr = gridRow(grid, rec, index)

  return tr
}

const getTr = (grid, rec, drawIfNull = false) => {
  let rowCmp = getRowCmp(grid, rec, drawIfNull)
  if (!rowCmp) return null
  return rowCmp.el ? rowCmp.el : drawIfNull ? rowCmp.render() : null
}

module.exports = { getDataForRow, gridRow, getTr, getRowCmp }
