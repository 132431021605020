const { addCss } = require('../add-css')
const { qc } = require('../cmp/qc')
const { dataBind } = require('../controls/databind')
const { rs } = require('../controls/rs')
const { ctlFormatters, ctlParsers } = require('../ctl-format')

const isNumeric = f => !isNaN(parseFloat(f)) && isFinite(f)

const qFloat4 = opts => {
  const me = qc('input.basic-ed.float4')
    .attr({ type: 'text' })
    .on('keyup', function (e) {
      if (e.which > 46 || e.which === 8) {
        var decimal = common.culture().numberFormat['.']
        var validChars = Array.prototype.reduce.call(
          '-0123456789' + decimal,
          (t, c) => {
            t[c] = 1
            return t
          },
          {}
        )
        var value = Array.prototype.filter.call(me.el.value, x => x in validChars).join('')
        // only a single decimal point
        value = value.split(decimal)
        if (value.length > 1) value[1] = decimal + value[1]
        value = value.join('')

        if (value !== me.el.value) me.el.value = value

        me.trigger('ow-change')
      }
    })
    .on('blur', function () {
      me.val(me.val())
    })
  // .on('keyup', function (e) {
  //   if (e.which === 13 && !e.shiftKey) me.resolve()
  // })

  me.opts = opts

  var label = opts.label
  const placeholder = opts.placeholder || label || ''
  me.attr('placeholder', placeholder)

  const formatter = opts.currency ? ctlFormatters.currency : ctlFormatters.float
  const parser = opts.currency ? ctlParsers.currency : ctlParsers.float

  dataBind(me)

  const props = {
    val(v) {
      if (typeof v !== 'undefined') {
        var s
        var fmt = opts.format
        s = formatter(v, fmt)
        me.el.value = s
      }

      v = me.el.value

      v = (!v && v !== 0) || v === '' ? '' : (v = parser(v, fmt))

      return v
    },

    validate(onInvalid, messageArray) {
      var el = me.el
      var name = opts.label || opts.name || opts.fieldName

      messageArray = typeof messageArray === 'undefined' ? [] : messageArray
      var v = el.val()
      var hasValue = !(
        typeof v === 'undefined' ||
        v === null ||
        v === '' ||
        (typeof v === 'string' && v.trim() === '')
      )
      if (
        (opts.required || (opts.required !== false && opts.schema && opts.schema.required)) &&
        !hasValue
      ) {
        if (onInvalid) {
          onInvalid(name, 'must have a value', el, messageArray)
          return false
        }
      }
      if (typeof v === 'number' && isNaN(v) && opts.edType === 'lookup') {
        if (onInvalid) {
          onInvalid(name, 'must select from the list.', el, messageArray)
          return false
        }
      }
      opts.minLength = opts.minLength || opts.min
      opts.maxLength = opts.maxLength || opts.max

      if (typeof v === 'string' && opts.minLength && opts.minLength < (v || '').length) {
        if (onInvalid)
          onInvalid(name, 'must be have min length of ' + opts.minLength, el, messageArray)
        return false
      }
      if (typeof v === 'string' && opts.maxLength && opts.maxLength < (v || '').length) {
        if (onInvalid)
          onInvalid(name, 'must be have max length of ' + opts.maxLength, el, messageArray)
        return false
      }

      if (opts.validation && typeof opts.validation === 'object') {
        if (opts.validation.ne !== undefined && v === opts.validation.ne) {
          if (onInvalid) onInvalid(name, 'cannot be ' + opts.validation.ne, el, messageArray)
          return false
        }
      }

      // Validation for fields with from value and to value
      var fromField, toField

      if (opts.isToField && opts.fromID) {
        var $top = el.myWin()
        var $from = $top.find(
          '#' + (opts.edType === 'float' || opts.edType === 'int' ? 'txt' : 'dp') + opts.fromID
        )

        if ($from.length > 0) {
          toField = typeof el.val() === 'number' ? el.val().toString() : el.val()
          fromField =
            typeof $from[0].val() === 'number' ? $from[0].val().toString() : $from[0].val()

          if (isNumeric(fromField) && isNumeric(toField)) {
            fromField = fromField * 1
            toField = toField * 1
          }
          if (fromField > toField) {
            if (onInvalid) {
              onInvalid(
                opts.fromID + ' field',
                __('From field must be always lower than to field'),
                el,
                messageArray
              )
              return false
            }
          }
        }
      }

      return true
    },

    displayValidity(bValid, msg) {
      if (bValid) {
        me.removeClass('k-input-errbg')
        me.el.invalidMsg = ''
      } else {
        me.el.invalidMsg = msg
        me.el.title = msg
        me.addClass('k-input-errbg')
      }
    },

    odisable(v = true) {
      if (v !== false) v = true
      me.attr('disabled', v)
      v ? me.rs().addClass('k-state-disabled') : me.rs().removeClass('k-state-disabled')
    }
  }

  me.props(props)

  me.on('init', (e, el) => {
    el.opts = opts
    Object.assign(el, props)
  })

  me.wrap = () => {
    const w = qc('span.wrap.k-textbox.ow-textbox', me)
    me.wrap = () => w
    return w
  }

  me.rs = () => {
    const _rs = rs(opts, me.wrap())
    _rs.input = me
    me.rs = () => _rs
    return _rs
  }

  return me
}

addCss(
  'q-float4-css',
  `
.ow-textbox { border-color: #dedee0; border-radius: 3px; }
.ow-textbox input { border: none; }
`
)

module.exports = { qFloat4 }
