/**
 * Given an object representing a record in a database table, and a field name, return that field name's value.
 * Note: If the field name references a method in the record object, this function will return the return value of that method.
 * @param {object} rec - Object representing a record of data fetched from the database.
 * @param {string} fieldName - String containing the name of a field in the record.  If the record is from a table join, then this string will be of the form 'joinTableName.fieldName'.
 */
const readRecordValue = (rec, fieldName) => {
  var r = rec
  fieldName
    .split('.')
    .forEach(rProp => r && (r = typeof r[rProp] === 'function' ? r[rProp].call(r) : r[rProp]))
  return r
}

/**
 * Given an object representing a record in a database table, a field name, and a value, do one of two things.
 * If value is defined: set the value of rec.fieldName to that value.
 * If value is undefined: remove the property fieldname from the rec object.
 * @param {object} rec - Object representing a record of data fetched from the database.
 * @param {string} fieldName - String containing the name of a field in the record.  If the record is from a table join, then this string will be of the form 'joinTableName.fieldName'.
 * @param {(string|number|boolean|Array|Object)} [value] - If value is undefined, the field indicated by fieldName is deleted entirely from the rec object.
 * @returns {(string|number|boolean|Array|Object)} Returns the value param, or an object (if value param is undefined).
 */
const setRecordValue = (rec, fieldName, value) => {
  var r = rec

  fieldName.split('.').forEach(function (rProp, depth) {
    if (typeof r[rProp] === 'undefined' && typeof value === 'undefined') return
    if (rProp === '__proto__' || rProp === 'prototype') return // extra protection against xss prototype pollution

    if (depth === fieldName.split('.').length - 1) {
      if (typeof r[rProp] === 'function') {
        r = r[rProp].call(r, value)
      } else {
        if (typeof value === 'undefined') {
          delete r[rProp]
        } else {
          r[rProp] = value
          r = value
        }
        return
      }
    } else {
      r[rProp] = r[rProp] || {}
      r = r[rProp]
    }
  })
  return r
}

/**
 * Gets or sets the value on a nested object.
 * @param {object} record
 * @param {string} nestedFieldName
 * @param {(string|number|boolean|Array|Object)} value - if undefined is passed in, the nested field is deleted, eg. _v({a: {b: 1, c: 2}}, 'a.c', undefined) to give {a: {b: 1}}.
 */
const _v = function (record, nestedFieldName, value) {
  // We could use lodash get and set ...?

  if (record === undefined) {
    console.warn('Unexpected condition: record undefined, check your code')
    return undefined
  }
  if (nestedFieldName === undefined) {
    console.warn('Unexpected condition: nestedFieldName undefined, check your code')
    return undefined
  }

  return arguments.length === 3
    ? setRecordValue(record, nestedFieldName, value)
    : readRecordValue(record, nestedFieldName)
}

module.exports._v = _v
