const { qc } = require('../cmp/qc')
const { generateButtonType } = require('./button7')

const footerBar7 = (opts, dsName, view) => {
  if (Array.isArray(opts) && opts.length === 0) opts = null
  if (!opts) opts = { right: ['close'] }

  const leftButtons = generateButtonType(opts.left || [], 'footer', dsName, view)
  const rightButtons = generateButtonType(opts.right || [], 'footer', dsName, view)

  if (dsName) {
    leftButtons.forEach(btn => btn.opts && (btn.opts.targetRef = btn.opts.targetRef ?? dsName))
    rightButtons.forEach(btn => btn.opts && (btn.opts.targetRef = btn.opts.targetRef ?? dsName))
  }

  const r = qc('div', [
    qc('div.buttonset_left', leftButtons),
    qc('div.buttonset_right', rightButtons)
  ])

  return r
}

module.exports.footerBar7 = footerBar7
