//https://codepen.io/MatthewLeFevre/pen/RzLmao?editors=1111

const { addCss } = require('../../add-css')
const Model = require('../data-model')

const { toolbar } = require('./richtexteditor6-toolbar')
const { html } = require('../../cmp/html')
const { _id } = require('../../_id')
const { mergeProps } = require('../../cmp/mergeProps')
const { qc } = require('../../cmp/qc')

const richtexteditorProps = {
  val(v) {
    const { body } = this
    if (typeof v !== 'undefined') body.kids(html(v))
    v = body.el?.innerHTML || v

    return v
  }
}

/**
 * For lightweight richtextbox
 * @param {*} props
 * @param {object} props.view
 * @returns
 */
const richtexteditor6 = props => {
  if (!props.id) props.id = 'richtexteditor-' + _id()

  props.tag = 'div#' + props.id + '.richtexteditor6.focusable.'

  const me = qc(mergeProps(richtexteditorProps, props))
    .attr({ tabindex: '0' })
    .css({ outline: '0' })

  Model.modelBind(props.view, me, me.model)

  me.state = { populating: 0 }

  // const dataSet = _v(me.model, me.fieldName) || []

  const richtexteditorBody = () => {
    const me = qc('div.richtexteditor-content')
      .attr({ contenteditable: true })
      .on('click', () => {
        me.el.focus() //Not sure why need focus twice to get it editable
        me.el.focus()
      })
      .on('input', () => me.trigger('ow-change'))

    return me
  }

  me.toolbar = toolbar(me)
  me.body = richtexteditorBody(me)

  me.kids([...me.kids(), me.toolbar, me.body])

  if (me.el) me.trigger('ow-richtexteditor-databound')

  return me
}

const scope = ''
const sCss = `
  ${scope} .richtexteditor6 {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;    
  }
  ${scope} .tool-list {
    list-style: none;
    padding: 0;
    margin: 2px;
  }  
  ${scope} .tool-list .button6 {
    margin: 2px;
    padding: 5px;
  }   
  ${scope} .richtexteditor-content {
    padding: 10px 20px;
    background: #fefefe;
    margin: 10px 2px;
  }
  `

addCss('richtexteditor6-css', sCss)

module.exports = { richtexteditor6 }
