const { qc } = require('../cmp/cmp')

const qCheck = me => {
  me.on('click', () => {
    const prev = me.val()
    me.val(!prev)
  }).on('keypress', e => {
    if (e.which === 32) {
      me.el.click()
      e.preventDefault()
      return false
    }
  })
  me.qCheck = true

  return me
}

const basicEdCheck = function (el, opts) {
  el.opts = el.opts || opts
  const me = qc(el)

  el.val = function (v) {
    if (v !== undefined && !me.attr('disabled')) {
      const prevVal = me.hasClass('on')
      if (prevVal !== v) {
        me.attr({ checked: v ? 'checked' : undefined })
        v ? me.addClass('on') : me.removeClass('on')
        me.trigger('ow-change', v)
      }
    }
    return me.hasClass('on')
  }
  me.val = el.val

  // owControl
  el.odisable = function (v) {
    if (v !== false) v = true
    me.disabled = v
    me.attr({ disabled: v ? 'disabled' : undefined })
    v ? me.addClass('ow-disabled') : me.removeClass('ow-disabled')
    v
      ? el.closest('.resource_set')?.classList.add('k-state-disabled')
      : el.closest('.resource_set')?.classList.remove('k-state-disabled')
  }

  el.displayValidity = function (bValid, msg) {
    if (bValid) {
      el.closest('.resource_set')?.classList.remove('k-input-errbg')
      el.parentNode.invalidMsg = ''
      el.parentNode.title = ''
    } else {
      el.parentNode.invalidMsg = msg
      el.parentNode.title = msg
      el.closest('.resource_set')?.classList.add('k-input-errbg')
    }
  }

  if (!me.qCheck) {
    if (me.isQc) {
      // temporary condition for build ow4 views.
      me.on('keypress', e => {
        if (e.which === 32) {
          me.el.click()
          e.preventDefault()
          return false
        }
      })
      me.qCheck = true
    } else qCheck(me)
  }

  return el
}

const ctlCheck = {
  init() {
    const el = this
    const me = qc(el)
    const opts = (el.opts = el.opts || {})

    el.classList.add('ow-check')

    if (!me.hasClass('ow-ctl-no-wrap')) qc(el.parentElement).addClass('ow-check-wrap')

    // initial value
    let f = (el.opts.fieldName = el.opts.fieldName || $(el).attr('data-field'))
    let v = 'value' in opts ? opts.value : f && opts.model ? ow0._v(opts.model, f) : false

    if (v) me.addClass('on')

    if (!me.qCheck) {
      ow.wrapControl(el)
      me.addClass('check5') // this prevents the bubble up version of .ow5 .ow-check on click

      qCheck(me)
    }
  },
  val(...args) {
    const el = this
    const me = qc(el)
    const [v, model, populating] = args
    if (args.length) {
      var changed = v != me.hasClass('on')
      v ? me.addClass('on') : me.removeClass('on')
      if (changed && !populating) me.trigger('ow-change')
    }
    return me.hasClass('on')
  },
  readFilter(filters) {
    const el = this
    if (el.val()) {
      var filter = {
        field: el.opts.fieldName ?? $(el).attr('data-field'),
        operator: el.opts.op ?? 'eq',
        value: el.opts.filterValue ?? el.val()
      }
      filters.push(filter)
    }
  },
  odisable(...args) {
    const el = this
    el.odisable(...args)
  }
}

module.exports = { qCheck, ctlCheck, basicEdCheck }
