// theme.js
const px1 = '0.75px' //'0.03125rem' // 1px - avoid funny rendering

module.exports = {
  px1,
  borderWidth: px1,
  buttonColor: '#b6bdca',
  iconBlue: '#307ebe',
  iconGreen: 'rgb(97 177 60)',
  iconRed: 'rgba(204, 32, 49, 1)',
  borderRadius: '4px', // '0.31em',
  textboxBorderColor: '#dedee0', // '#ceced2'
  textboxColor: '#515967',
  textColor: '#000',
  disabledIcon: '#a6a6ad',
  selectedRowBackgroundColor: 'rgba(57, 161, 232, 0.5)',
  rowAltColor: '#fcfcfc',
  grayBackgroundColor: '#f3f3f4',
  highlightText: '#307ebe', // PDI: '#A10A7D',
  listItemPadding: '0.25rem' // PDI: '0.5rem'
}
