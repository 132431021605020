// makeDropdown7.js

const { addCss } = require('../add-css')
const theme = require('../theme')

const defaultMaxHeight = 270

module.exports.makeDropdown7 = (qcDd, ddParent, getOffsets) => {
  const reposition = function () {
    const rect = getOffsets()

    // set position again
    // vertical position
    // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
    const topFromBody = rect.top + rect.height

    const spaceBelow = document.body.scrollHeight - topFromBody
    const spaceAbove = rect.top - document.body.scrollTop

    const firstRender = !this.el?.offsetHeight
    const h = firstRender ? defaultMaxHeight : Math.min(this.el.offsetHeight, defaultMaxHeight)

    const floatUp = firstRender
      ? spaceAbove > spaceBelow && defaultMaxHeight > spaceBelow
      : spaceAbove > spaceBelow && h > spaceBelow

    const css = {
      top: floatUp ? undefined : rect.top + rect.height - no$(ddParent).offset().top + 'px',
      bottom: floatUp
        ? no$(ddParent).offset().top + ddParent.offsetHeight - rect.top + 'px'
        : undefined,
      left: rect.left - no$(ddParent).offset().left + 'px',
      maxHeight: Math.min(floatUp ? spaceAbove : spaceBelow, defaultMaxHeight) + 'px'
    }

    this.css(css)
  }

  return qcDd.addClass('dropdown dropdown7').props({ reposition }).bindState(reposition)
}

const scope = ''

addCss(
  'dropdown7-css',
  `
.pdi ${scope} ul.dropdown7 {
  padding: 0.5rem;
  border-radius: ${theme.borderRadius};
}

${scope} ul.dropdown7 {
  padding: 3px;
  border-radius: 3px;
  line-height: 1rem;
  cursor: pointer;
  position: absolute;
  min-width: 60px;
  border: solid 0.077em #bbb;
  overflow: hidden;
  z-index: 999;
  background-color: #fff;
  box-shadow: #ccc 0.14em 0.14em 0.3em 0em;
  box-sizing: border-box;
  overflow-y: auto;
  list-style-type: none;
}

.pdi ${scope} ul.dropdown7 > li.item {
  border-radius: ${theme.borderRadius};
}
${scope} ul.dropdown7 > li.item {
  min-height: 1em;
  padding: ${theme.listItemPadding};
  border: 1px solid transparent;
}

${scope} ul.dropdown7 > li.item:hover {
  text-decoration: none;
  background-color: ${theme.grayBackgroundColor};
}
.pdi ${scope} ul.dropdown7 > li.item:hover {
  text-decoration: underline;
}

.pdi ${scope} ul.dropdown7 > li.item.ow-selected {
  background-color: transparent;
  color: ${theme.highlightText};
}
${scope} ul.dropdown7 > li.item.ow-selected {
  border: 1px ${theme.highlightText} solid;
  background-color: ${theme.highlightText};
  color: #fff;
}
</style>
`
)
