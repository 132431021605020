// cmenu.js
const { calcSubWindowPosition } = require('./dropdown')
const { addCss } = require('./stylesheet')
const { qc } = require('../cmp/qc')
const { $find } = require('../no-jquery')

/**
 * Function responsible for opening context menu (e.g. menu that opens when filter icon in grid header is clicked).
 * @param {object} el - Object representing HTML element.
 * @param {object} ddOpts
 */
const openContextMenu = function (el, ddOpts = {}) {
  ddOpts.keyHandler = ddOpts.keyHandler || (() => {})
  ddOpts.content = ddOpts.content || (() => '<ul><li>This is a generic contextMenu</li></ul>')

  const viewParent = ddOpts.view.qTop.el.parentElement

  let prev = $find(viewParent, '.ow-cmenu')[0]
  if (prev) prev.remove()

  let pos = calcSubWindowPosition(viewParent, el, ddOpts.point)

  let mnu = qc('div.ow-cmenu', ddOpts.content(el))
    .attr({ tabindex: 0 }) // necessary to detect relatedTarget
    .css({
      position: 'absolute',
      left: pos.left + 'px',
      top: pos.top + 'px',
      'min-width': '12em',
      padding: '0.33em',
      border: 'solid 0.077em #bbb',
      overflow: 'hidden',
      'z-index': 999,
      'background-color': '#fff',
      'box-shadow': '#ccc 0.14em 0.14em 0.3em 0em',
      'box-sizing': 'border-box',
      'max-height': '270.72px',
      'overflow-y': 'auto'
    })

  el.mnu = mnu
  mnu.renderTo(viewParent)
  mnu.el.focus()

  mnu.close = () => mnu.el.remove()

  mnu.trigger('ow-cmenu-open') // todo: check

  return mnu
}

module.exports = {
  openContextMenu
}

addCss(
  'cmenu-css',
  `
#scope .ow-cmenu li:hover {
  background: #ccc;
}
#scope .ow-cmenu li.selected {
  color: #fff;
  background-color: #4d90fe
}
#scope .ow-cmenu a.menu-item {
  line-height: 2em;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}
#scope .ow-cmenu a.check6 {
  white-space: nowrap;
}`
)
