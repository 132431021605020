const { qc } = require('../cmp/qc')
const { icon } = require('../icon-codes')
const { dataBind } = require('./databind7')
const { input7 } = require('./text7')
const { _v } = require('../_v')
const { killEvent } = require('../killEvent')
const dates = require('../dates')
const { makeDropdown7 } = require('./makeDropdown7')

const renderClock = me => {
  if (!me) throw 'renderClock requires editor argument'
  return async () => {
    const win = me.el.closest('.win-con')
    const ddParent = win?.parentElement ?? document.body

    if (me.timedd)
      return me.timeOpen
        ? !me.timedd.el?.parentElement && me.timedd.renderTo(ddParent)
        : me.timedd.el?.parentElement && me.timedd.el.remove()
    me.timedd = qc('ul.dropdown')

    makeDropdown7(
      me.timedd,
      ddParent,
      () => no$(me.wrap().el).offset(),
      200 // minHeight
    )

    const hours = []
    for (let h = 0; h < 24; h++) {
      const hh = (h < 10 ? '0' : '') + h

      const quart = (mm, showHH = false) =>
        qc('a.time-cell', (showHH ? hh + ':' : '') + mm + (me.opts.allowSec ? ':00' : ''))
          .attr({ href: '#' })
          .css({
            boxSizing: 'border-box',
            display: 'inline-block',
            margin: '0',
            lineHeight: '1.5rem',
            width: '100%' // showHH ? '40%' : '20%'
          })
          .on('click', () => {
            me.closeTime()
            if (me.setTime) me.setTime(hh + ':' + mm + (me.opts.allowSec ? ':00' : ''))
            else {
              me.val(hh + ':' + mm + (me.opts.allowSec ? ':00' : ''))
              me.trigger('ow-select')
            }
          })

      // hours.push(qc('div', [quart('00', true), quart('15'), quart('30'), quart('45')]))

      hours.push(qc('li.item', [quart('00', true)]))
      hours.push(qc('li.item', [quart('15', true)]))
      hours.push(qc('li.item', [quart('30', true)]))
      hours.push(qc('li.item', [quart('45', true)]))
    }

    me.timedd
      .kids(hours)
      .css({ maxHeight: '210px' })
      .on('focusin click', () => me.el.focus()) // set the focus back to the input.
      .bindState(() => {
        // set position again
        // vertical position
        // If there is enough room below the drop-down menu to display all elements, ensure the menu drops down.  If not, ensure the menu drops up.
        const viewTop = no$(ddParent).offset().top
        const topFromBody = no$(me.wrap().el).offset().top + no$(me.wrap().el).offset().height
        const topFromView = topFromBody - viewTop

        const height = Math.max(no$(me.timedd.el).offset().height, 210)
        const floatUp = topFromBody + height > document.body.scrollHeight

        me.timedd.css({
          left: no$(me.wrap().el).offset().left - no$(ddParent).offset().left + 'px',
          width:
            (me.opts.listWidth === undefined
              ? no$(me.wrap().el).offset().width
              : me.opts.listWidth || 300) + 'px',
          top: floatUp
            ? topFromView -
              (no$(me.timedd.el).offset().height + no$(me.wrap().el).offset().height) +
              'px'
            : topFromView + 'px'
        })
      })

    if (me.timeOpen) me.timedd.renderTo(ddParent)
  }
}

const time7 = opts => {
  opts.dataType = 'string'
  opts.edType = 'time' // for use in ow4

  const { allowSec } = opts

  const me = input7(opts)
  me.timeOpen = false
  me.timedd
  me.renderClock = renderClock(me)
  me.closeTime = () => {
    me.timeOpen = false
    if (me.timedd) me.timedd.el?.remove()
  }

  const hasFocus = () => {
    const activeEl = document.activeElement

    return (
      activeEl === me.el ||
      activeEl?.parentElement === me.el?.parentElement ||
      activeEl === me.el?.parentElement
    )
  }

  me.props({
    val(v, model, populating) {
      const isOw4Read = v === undefined && model

      if (!isOw4Read && arguments.length > 0) {
        const s = v ?? ''
        me.text(s)

        let hasChanged = false
        if (me.model && me.opts?.fieldName && _v(me.model, me.opts?.fieldName) !== v)
          hasChanged = true
        if (s !== me.text() && me.el !== document.activeElement) me.text(s)
        me.model && me.opts?.fieldName && _v(me.model, me.opts?.fieldName, v)
        hasChanged && !populating && me.trigger('ow-change')
      }
      return dates.resolveTime(me.text(), true, { allowSec })
    },

    resolve() {
      if (me.text()) {
        const s = dates.resolveTime(me.text(), true, { allowSec })
        if (me.text() !== s) me.text(s)
      }
    }
  })
    .props({ model: opts.model ?? {} })
    .addClass('time7')
    .on('keydown', e => {
      // tab
      if (e.which === 9) {
        // to resolve on tab
      }

      // shift + enter
      if (e.which === 13 && me.timeOpen) {
        me.timeOpen = false
        me.renderClock()
        return
      }
      // shift + enter
      if (e.which === 13 && e.shiftKey) {
        me.timeOpen = !me.timeOpen
        me.renderClock()
        return
      }

      // escape
      if (e.which === 27) {
        me.timeOpen = false
        me.renderClock()
        return
      }

      // downarrow
      if (e.which === 40) {
        me.renderClock()
        e.preventDefault()
        return false
      }

      // uparrow
      if (e.which === 38) {
        me.renderClock()
        e.preventDefault()
        return false
      }
    })
    .on(
      'init',
      (e, el) =>
        (el.requestTabOut = function () {
          me.resolve()
          return true
        })
    )
    .on('input', () => me.renderClock())
    .on('keydown', () => {
      var el = me.el
      el.prevValue = el.value
    })
    .on('keyup', e => {
      const el = me.el
      // backspace
      if (e.which === 8) return

      // delete
      if (e.which === 46) {
        const charDeleted = (el.prevValue || el.value)[el.selectionEnd - 1]
        if ('/-.'.indexOf(charDeleted) > -1) return
      }

      if (el.value !== el.prevValue) {
        let x = el.selectionEnd
        const caretAtEnd = x === el.value.length
        let v = dates.resolveTime(el.value, false, { allowSec }, { position: el.selectionEnd })
        if (v.indexOf('|') > -1) {
          x = v.indexOf('|')
          v = v.split('|').join('')
        }
        if (el.value !== v) el.value = v
        el.prevValue = v
        if (caretAtEnd) return
        el.selectionStart = x
        el.selectionEnd = x
      }
    })
    .bindState(() => {
      if (me.timeOpen && document.activeElement !== me.el) {
        console.log('closing clock not focused')
        me.closeTime()
        me.renderAsync()
      }
    })
    // .bindState(
    //   () => me.timeOpen,
    //   () => me.timeOpen && (me.timedd ?? me.renderClock())
    // )
    .bindState(
      () => me.val(),
      () => me.timeOpen && me.renderClock()
    )

  dataBind(me)
  if (opts.model) me.populate(opts.model)
  else if (opts.value !== undefined) me.val(opts.value)

  me.wrap()
    .addClass('time7 text-icon-after ow-time-wrap')
    .on('focusout', () =>
      setTimeout(() => {
        if (hasFocus()) return
        me.closeTime()
        me.renderClock()
        me.renderAsync()
      }, 100)
    )
    .kids([
      me,
      icon('clock')
        .addClass('clock-icon')
        .bindState(
          () => me.disabled,
          function (v) {
            this.css({
              cursor: !v ? 'pointer' : undefined,
              color: v ? '#ddd' : undefined
            })
          }
        )
        .css({ textAlign: 'center' })
        .on('click', e => {
          if (me.disabled) return
          me.timeOpen = !me.timeOpen
          me.el.focus()
          me.renderClock()
          return killEvent(e)
        })
    ])

  return me
}

// const highlightText = '#A10A7D' // PDI plum

// addCss(
//   'time7-css',
//   `
// ul.clock.dropdown {
//   font-size: 0.75em;
//   padding: 0.5rem;
//   line-height: 1rem;
//   cursor: pointer;
//   border-radius: 4px;
//   position: absolute;
//   min-width: 60px;
//   border: solid 0.077em #bbb;
//   overflow: hidden;
//   z-index: 999;
//   background-color: #fff;
//   box-shadow: #ccc 0.14em 0.14em 0.3em 0em;
//   box-sizing: border-box;
//   list-style-type: none;
// }
// ul.clock.dropdown > li.item {
//   border-radius: 4px;
//   padding: 0.5rem;
//   border: 1px solid transparent;
// }
// ul.clock.dropdown > li.item:hover {
//   text-decoration: underline;
// }
// ul.clock.dropdown > li.item.ow-selected {
//   border: 1px ${highlightText} solid;
//   color: ${highlightText};
// }
// `
// )

module.exports = { time7, renderClock }
