const ow0 = {
  version: 0,
  views: { comment: 'Same object is used by all libs' },
  controls: { version: 0 },
  windows: {},
  grids: {}
}

Object.assign(ow0, require('./core'))

module.exports.ow0 = ow0
