const applyAttrsAndClasses = ctl => {
  const { opts } = ctl
  if (!opts) {
    console.error('Ctl has no opts', ctl)
    return ctl
  }

  if (opts.class && ow0.dev) console.warn('opts.class is deprecated : ' + opts.class)

  let classes = (opts.classes || '') + ' ' + (opts.class || '')
  // delete opts.classes
  // delete opts.class
  if (opts.attrs?.class) {
    classes += ' ' + opts.attrs?.class
    // delete opts.attrs.class
  }
  ctl.addClass(classes)

  let { attrs = {} } = opts
  ctl.attr(attrs)

  if (opts.id) ctl.attr({ id: opts.id }) // can we get rid of this
  // delete opts.attrs

  if (opts.init) console.warn('opts.init is deprecated please do it another way!')

  return ctl
}

module.exports = { applyAttrsAndClasses }
