const { icon6 } = require('../icons')

const { text6 } = require('../text6')
const { booleanFilter } = require('../filter-boolean')
const { filterOperators, defOp, resetFilter } = require('../filters')
const { datetime6 } = require('../datetime6')
const { date6 } = require('../date6')
const { time6 } = require('../time6')
const { openContextMenu } = require('../cmenu6')
const { killEvent } = require('../../killEvent')
const { qc } = require('../../cmp/qc')

const colFilterCell = (grid, col) => {
  if (col.gridCol === false) return ''

  let filterControl = ''
  let filterContent = []

  let type = col.filterType || col.type || col.dataType || ''

  let dsName = grid.dsName || grid.id

  const cell = qc('th.ow-filter-col.coli-' + col.coli)
    .props({ coli: col.coli })
    .attr({ 'data-coli': col.coli })
    .on('keyup', e => {
      // f2 on grid selects/edits the current row
      if ((e.which === 113 || e.which === 13) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        return killEvent(e, false)
      }
    })
    .on('keypress', e => {
      // f2 on grid selects/edits the current row
      if ((e.which === 113 || e.which === 13) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        return killEvent(e, false)
      }
    })
    .on('keydown', e => {
      // f2 on grid selects/edits the current row
      if ((e.which === 113 || e.which === 13) && !e.altKey && !e.shiftKey && !e.ctrlKey) {
        if (filterControl.clientFilter) grid.refilter()
        else grid.refresh()
        // grid.filterChanged(filterControl)
        return killEvent(e, false)
      }
    })
    .on('change', () => {
      if (filterControl.clientFilter) grid.refilter()
      else grid.refresh()
    })
    .on('ow-change', () => {
      if (filterControl.clientFilter) grid.refilter()
      // else grid.refresh()
    })

  const filterControlProps = {
    attrs: { class: 'ow-filter-control ' + col.filterType || type },
    grid,
    view: grid.view,
    fieldName: col.field,
    dsName,
    clientFilter: col.clientFilter || false,
    type: col.filterType || type,
    col,
    op: defOp(col.filterType)
  }

  const filterOperatorsIcon = icon6('filter')
    .addClass('text-item-icon')
    .addClass('i-filter')
    .bindState(
      () => filterControl.isSet,
      function (isSet) {
        if (isSet) this.addClass('isset')
        else this.removeClass('isset')
      }
    )
    .on('click', e => {
      const me = filterOperatorsIcon
      const { col, control, grid } = me
      let filterControlEl = control.el
      const ftypes = filterOperators[col.filterType || col.type || 'string'] || {
        eq: 'is equal to' + (col.filterType || col.type || 'string')
      }

      openContextMenu(e.target, {
        view: grid.view,
        point: {
          left: e.pageX,
          top: e.pageY
        },
        setWidth: false,
        content() {
          const ctxtMenu = Object.keys(ftypes).map(op =>
            qc(
              'li',
              qc('a.menu-item', { href: '#', 'data-op': op }, __(ftypes[op])).on(
                'click',
                function () {
                  control.op = op
                  me.el.mnu.close()
                  filterControlEl.focus()
                }
              )
            ).bindState(function () {
              //If current column has filter set for this specific operator, designate its <li> element as selected.
              if (op === control.op) this.addClass('selected')
              else this.removeClass('selected')
            })
          )
          const clrFltr = qc(
            'li',
            qc('a.menu-item', __('Clear filter'))
              .attr({ href: '#' })
              .on('click', e => {
                resetFilter(control)
                if (control.isSet) no$(e.target).removeClass('isset')
                me.el.mnu.close()
                filterControlEl.focus()
                if (control.clientFilter) return grid.refilter()
                return grid.refresh()
              })
          )
          ctxtMenu.push(clrFltr)
          return qc('ul', ctxtMenu)
        }
      })
    })

  if (col.filterType === 'boolean') {
    filterControl = booleanFilter(filterControlProps)
    filterContent = [filterControl.wrap()]
    filterControl.on('click', e => {
      !filterControl.clientFilter && filterControl.trigger('change', e)
    })
    cell.css({ textAlign: 'center' })
  } else if (col.filterType === 'datetime') {
    if (col.field) {
      filterControl = datetime6(filterControlProps)

      filterOperatorsIcon.grid = grid
      filterOperatorsIcon.col = col
      filterOperatorsIcon.control = filterControl

      filterContent = [filterControl.wrap(), filterOperatorsIcon]
    }
  } else if (col.filterType === 'date') {
    if (col.field) {
      filterControl = date6(filterControlProps)

      filterOperatorsIcon.grid = grid
      filterOperatorsIcon.col = col
      filterOperatorsIcon.control = filterControl

      filterContent = [filterControl.wrap(), filterOperatorsIcon]
    }
  } else if (col.filterType === 'time') {
    if (col.field) {
      filterControl = time6(filterControlProps)
      filterOperatorsIcon.grid = grid
      filterOperatorsIcon.col = col
      filterOperatorsIcon.control = filterControl
      filterContent = [filterControl.wrap(), filterOperatorsIcon]
    }
  } else {
    if (col.field) {
      filterControl = text6(filterControlProps)
      filterOperatorsIcon.grid = grid
      filterOperatorsIcon.col = col
      filterOperatorsIcon.control = filterControl

      if (col.filterMap) filterControl.filterMap = col.filterMap

      filterContent = [filterControl.wrap(), filterOperatorsIcon]
    }
  }

  if (filterControl)
    filterControl.on('ow-change', () => {
      if (filterControl.clientFilter) setTimeout(() => grid.refilter(), 10)
    })

  cell.kids([qc('span.cell-liner', filterContent).css({ width: '100%' })])

  return cell
}

module.exports = { colFilterCell }
