const { addCss } = require('./stylesheet')
const { closeDropdowns } = require('./dropdown')
const { qc } = require('../cmp/qc')

const modalPopup = props => {
  let { title, content } = props

  if (!props.view) throw new Error('Invalid modalPopup options.  You must set view property')

  const viewWinEl = props.view.qTop.el
  viewWinEl.classList.add('modal-open')

  const titleBar = qc('div.modal-title', qc('span', title))
  const middle = qc('div.modal-middle', content)

  const footer = qc(
    'div.modal-footer',
    qc('div.buttonset_right', [
      qc('button.button6.modal-ok', __('OK')),
      qc('button.button6.modal-cancel', __('Cancel'))
    ])
  ).on('click', e => {
    const isModalCancel = e.target.classList.contains('modal-cancel')
    const isModalOk = e.target.classList.contains('modal-ok')
    if (isModalCancel) footer.modalPopup.cancel()
    if (isModalOk) footer.modalPopup.close()
    if (isModalCancel || isModalOk) viewWinEl.classList.remove('modal-open')
  })

  const me = qc('div.modal-popup', [titleBar, middle, footer]).props({
    ...{
      open(w) {
        this.renderTo(w.el)
      },

      cancel() {
        const canClose = !this.onCancel || this.onCancel() !== false
        if (!canClose) return
        closeDropdowns(this.el)
        this.el.remove()
      },

      close() {
        const canClose = !this.onClose || this.onClose() !== false
        if (!canClose) return
        closeDropdowns(this.el)
        this.el.remove()
      }
    },
    ...props
  })
  footer.modalPopup = me
  me.open(me.view.qTop)
  return me
}

addCss(
  'modal-popup-css',
  {
    '#scope div.modal-popup': {
      top: '10%',
      left: '40%',
      width: '300px',
      height: '240px',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f3f6f8',
      borderColor: 'rgba(0, 0, 0, 0.3)',
      boxShadow: '0 0.15em 0.77em rgba(0, 0, 0, 0.5)',
      borderRadius: '0.33em',
      overflow: 'hidden'
    },
    '#scope div.modal-title': {
      color: '#101114',
      height: '2em',
      padding: '0.4em 0 0.4em 0.75em',
      borderBottom: '0.15em solid lightgray',
      boxSizing: 'content-box'
    },
    '#scope div.modal-middle': {
      margin: '0.44em',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto'
    },
    '#scope .modal-title > span': {
      height: '100%',
      lineHeight: '2em',
      padding: '0.44em',
      fontWeight: 'bold'
    },
    '#scope .modal-footer': {
      padding: '0.44em',
      textAlign: 'right'
    },
    '#scope .modal-open': {
      pointerEvents: 'none'
    },
    '#scope .modal-open .modal-popup': {
      pointerEvents: 'auto'
    }
  }
  // , this.el
)

module.exports = { modalPopup }
