const { html } = require('../../cmp/html')
const { mergeProps } = require('../../cmp/mergeProps')
const { qc } = require('../../cmp/qc')
const { _v } = require('../../_v')
const { modelBind, $meta } = require('../data-model')
const { formatString } = require('../../ctl-format')

function readValue(col, model) {
  var v = typeof col.calc === 'function' ? col.calc(model) : col.field ? _v(model, col.field) : ''
  if (col.type === 'date' || col.type === 'datetime')
    if (typeof v === 'string' && v !== '') v = ow.parseDate(v, col.format)

  return typeof v === 'undefined' || v === null ? '' : v
}

const gridCell = (grid, col, rec, fi) => {
  if (col.field === 'i') return qc('td', fi) // For testing
  if (col.gridCol === false) return ''
  if (grid.editable === false) col.readOnly = true

  const ro =
    col.readOnly === true || (typeof col.readOnly === 'function' && col.readOnly(rec) === true)

  if (col.classes) console.warn('col.classes no longer supported')

  col.grid = grid
  const view = grid.view

  let attrs = mergeProps({}, { attrs: col.attrs | {} }).attrs

  let dataType = col.type || ''

  let content = ''

  const isCmp = x => x && typeof x === 'object' && (Array.isArray(x) || x.tag)

  if (rec.isGroup) {
    if (!rec.footer && col.groupHeader.template) {
      dataType = col.groupHeader.type
      attrs = mergeProps({ attrs }, { attrs: col.groupHeader.attrs || {} }).attrs
      content = col.groupHeader.template(rec.recs, grid, rec)
    }
    if (rec.footer && col.groupFooter.template) {
      dataType = col.groupFooter.type
      attrs = mergeProps({ attrs }, { attrs: col.groupFooter.attrs || {} }).attrs
      content = col.groupFooter.template(rec.recs, grid, rec)
    }
    // if (!(content === '')) attrs.class += ' nongroupcell'

    content = qc('span.cell-liner', isCmp(content) ? content : qc('span.static-value', content))
  } else {
    const dataValue = () => {
      var defaultValue = readValue(col, rec)
      if (col.format) defaultValue = formatString(defaultValue, col.format)

      col.grid = grid
      var val = col.template ? col.template(rec, defaultValue, grid) : defaultValue

      if (typeof val === 'undefined' || val === null) val = ''
      else if (typeof val === 'boolean') val = val ? __('True') : __('False')
      return val
    }

    const dv = dataValue()

    content = qc(
      'span.cell-liner' + (ro ? '.read-only-cell' : ''),
      isCmp(dv)
        ? dv
        : qc('span.static-value', dv).bindState(
            () => readValue(col, rec),
            function () {
              this.kids([dataValue()])
            }
          )
    )
  }

  attrs['data-coli'] = col.coli
  let cell = qc('td.gridcell', content || html('&nbsp;'))
    .attr(attrs)
    .props({
      grid,
      col,
      coli: col.coli,
      rec,
      model: rec
    })
    .on('focusin', () => cell.grid.current(cell.el))
    .on('click', e => {
      grid.current(cell.el)
      if (e.target !== cell.el) grid.focusCell(cell.el)
    })
    .bindState('col.width', w => cell.css({ width: w.toString() + 'px' }))
    .bindState(
      () => grid.cols[col.coli].hidden,
      function (v) {
        this.css({ display: v ? 'none' : undefined })
      }
    )
    .bindState(
      () => grid.state.current.reci === $meta(rec).reci && grid.state.current.coli === col.coli,
      v => (v ? cell.addClass('ow-current-cell') : cell.removeClass('ow-current-cell'))
    )

  // hooking up col.onEdit
  modelBind(view, cell, cell.model)
  if (col.field) cell.fieldName = col.field
  let prev
  cell.on('model-change', (e, model, fieldName, vPrev) => {
    if (model === cell.model && fieldName === col.field) {
      if (prev !== _v(model, fieldName)) {
        prev = _v(model, fieldName)
        col.onEdit?.({ [col.field]: vPrev }, cell.model, cell)
      }
    }
  })

  if (grid.virtualScroll !== false && grid.rowHeight)
    cell.css({
      // height: grid.rowHeight + 'px',
      // fontSize: (grid.rowHeight - 3) / (0.9 * 24) + 'em',
      // lineHeight: '1.6em'
    })

  if (dataType) {
    if (
      dataType === 'number' ||
      dataType === 'int' ||
      dataType === 'integer' ||
      dataType === 'float' ||
      dataType === 'currency'
    )
      cell.addClass('number-col')
    else cell.addClass(dataType + '-col')
  }
  if (grid.editable && ro) cell.addClass('non-editable-cell').addClass('no-tab')
  if ('coli' in col) cell.addClass('coli-' + col.coli)

  cell = col.preRender?.(cell) ?? cell

  return cell
}

module.exports = { gridCell }
