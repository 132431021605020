const { _v } = require('../_v')

/**
 *
 * @param {[fieldName, asc][]} sorts - array of [fieldname, asc]
 *
 * @returns sort function for using in dataSetArray.sort(fn)
 */
module.exports.recSorter = sorts => {
  sorts = typeof sorts === 'string' ? sorts.split(',') : sorts
  sorts = sorts.map(x => (typeof x === 'string' ? [x, 1] : x))

  return (a, b) => {
    if (sorts.length === 0) return 0

    let f, i, av, bv, result

    for (i = sorts.length - 1; i >= 0; i--) {
      f = sorts[i][0]

      if (typeof f === 'function') {
        av = f(a) || 0
        bv = f(b) || 0
      } else {
        av = _v(a, f) || 0
        bv = _v(b, f) || 0
      }

      if (typeof av === 'string') av = av.toLowerCase()
      if (typeof bv === 'string') bv = bv.toLowerCase()

      if (av !== bv) {
        result = sorts[i][1] ? av > bv : bv > av
        return result ? 1 : -1
      }
    }
    return 0
  }
}
