const { qc } = require('../../cmp/qc')
const { button6 } = require('../button6')

const toolbar = richtextbox => {
  const toolButtons = [
    { name: 'justifyLeft', cmd: 'justifyLeft', icon: 'align-left' },
    { name: 'justifyRight', cmd: 'justifyRight', icon: 'align-right' },
    { name: 'justifyCenter', cmd: 'justifyCenter', icon: 'align-justify' },
    { name: 'bold', cmd: 'bold', icon: 'bold' },
    { name: 'italic', cmd: 'italic', icon: 'italic' },
    { name: 'underline', cmd: 'underline', icon: 'underline' },
    { name: 'insertOrderedList', cmd: 'insertOrderedList', icon: 'list-ol' },
    { name: 'insertUnorderedList', cmd: 'insertUnorderedList', icon: 'list-ul' }
  ]
  const toolButton = tool =>
    button6({
      name: tool.name,
      label: __('LetItBlank'),
      iconName: tool.icon
    }).on('click', () => {
      document.execCommand(tool.cmd, false, null)
      //eg document.execCommand('formatBlock', false, 'h2') to support h1-h6
      richtextbox.body.el.focus() //not sure why require focus twice to make it editable
      richtextbox.body.el.focus()
    })

  const me = qc('ul.tool-list', richtextbox)

  me.kids(toolButtons.map(toolButton))

  return me
}

module.exports = {
  toolbar
}
