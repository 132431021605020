// cmenu.js
const { addCss } = require('../add-css')
const { qc } = require('../cmp/qc')
const { $find, no$ } = require('../no-jquery')
const theme = require('../theme')
const { makeDropdown7 } = require('./makeDropdown7')

/**
 * Function responsible for opening context menu (e.g. menu that opens when filter icon in grid header is clicked).
 * @param {object} el - Object representing HTML element.
 * @param {object} ddOpts
 */
const cmenu7 = (el, ddOpts = {}) => {
  ddOpts.keyHandler = ddOpts.keyHandler || (() => {})
  ddOpts.content =
    ddOpts.content ||
    (() =>
      qc(
        'ul',
        ddOpts.menuItems?.map(item =>
          !item.text
            ? qc('li', qc('hr').css({ borderTop: 'none' })).addClass(item.cssClass ?? '')
            : qc(
                'li',
                qc(
                  'a.menu-item',
                  item.spriteCssClass
                    ? [qc('i.icon').addClass(item.spriteCssClass), item.text]
                    : item.text
                )
              ).on('click', (...args) => {
                item.click(...args)
                el?.mnu.close()
              })
        ) ?? qc('li', __('This is a generic contextMenu'))
      ))

  const viewParent = el.closest('.win-con')?.parentElement ?? document.body

  let prev = $find(viewParent, '.cmenu7')[0]
  if (prev) prev.remove()

  let { top, left } = ddOpts.point
  top = top - no$(el).offset().top
  left = left - no$(el).offset().left

  const mnu = makeDropdown7(
    ddOpts.content(el).addClass('cmenu7').addClass('ow-cmenu'), // ow-cmenu for closing when click outside
    viewParent,
    () => {
      let t = top + no$(el).offset().top
      let l = left + no$(el).offset().left

      return {
        top: t,
        left: l,
        bottom: t,
        right: l,
        height: 0,
        width: 0
      }
    },
    (ddOpts.content.length ?? 1) * 24 + 16
  )
    .attr({ tabindex: '0' }) // necessary to detect relatedTarget
    .css({ backgroundColor: '#f7f7f7' })
  el.mnu = mnu
  mnu.renderTo(viewParent)
  mnu.el.focus()

  mnu.close = () => mnu.el.remove()

  mnu.trigger('ow-cmenu-open')

  return mnu
}

module.exports.cmenu7 = cmenu7

addCss(
  'cmenu-css',
  `
.cmenu7 li:hover {
  background: #ccc;
}
.cmenu7 li.selected {
  color: #fff;
  background-color: #4d90fe
}
.cmenu7 a.menu-item {
  line-height: 2em;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.cmenu7 a.menu-item .fa {
  text-indent: 0;
  padding: 0 6px;
  color: ${theme.iconBlue};
}
.cmenu7 a.check7 {
  white-space: nowrap;
}`
)
