const { _v } = require('../_v')
const { qc } = require('../cmp/qc')
const { formatString, isNumeric, ctlParsers, ctlFormatters } = require('../ctl-format')
const { culture } = require('../culture')
const { killEvent } = require('../killEvent')
const { icon } = require('../icon-codes')
const { disable } = require('../odisable')
const theme = require('../theme')
const { dataBind } = require('./databind7')
const { filterFunctions } = require('./filters')
const { rs } = require('./rs7')

/**
 * Cmp for using as part of an input based control - text7 and combo both use input7
 * @param {object} opts
 * @param {boolean} props.disabled
 * @param {boolean} props.readOnly
 * @returns Cmp Object
 */
const input7 = opts => {
  let lastValue, // input text
    lastVal // data value

  const me = qc('input.input7')
    .attr({
      type: opts.obscure ? 'password' : 'text',
      autocomplete: 'off',
      spellcheck: 'false',
      maxlength: opts.maxLength,
      ...opts.attrs
    })
    .props({ opts })
    .on('input ow-change', () => me.displayValidity(true))
    .props({
      triggerChange() {
        if (lastVal !== me.val()) {
          if (me.el) me.trigger('ow-change')
          lastVal = me.val()
        }
      },

      value(v) {
        if (!arguments.length) return me.el ? me.el.value : me.attr('value') ?? ''

        if (v && typeof v !== 'string') {
          console.error('Invalid value passed into text7.value(v)', v)
          v = ''
        }
        if (me.el) me.el.value = v ?? ''
        else me.attr({ value: v ?? '' })
        return me
      },

      text(...args) {
        return this.value(...args)
      },

      disable(...args) {
        return disable(me, ...args)
      },

      val(v, model, populating) {
        const { format, parser, formatter } = me

        if (arguments.length) {
          const value = formatV(v, format, formatter) || ''
          me.text(value)

          if (populating) lastVal = me.val()
          else me.triggerChange()
        }
        v = me.text()

        if (v && parser) return parser(v)

        return v
      },

      validate(onInvalid, messageArray = []) {
        const { label, fieldName, required, schema } = me.opts

        var name = label || fieldName

        var v = me.val()
        var hasValue = !(
          v === undefined ||
          v === null ||
          v === '' ||
          (typeof v === 'string' && v.trim() === '')
        )
        if ((required || (required !== false && schema?.required)) && !hasValue) {
          if (onInvalid) {
            onInvalid(name, 'must have a value', me.el, messageArray)
            return false
          }
        }

        let { minLength, maxLength, min, max, validation, ctlType } = me.opts

        if (typeof v === 'number' && isNaN(v) && ctlType === 'combo') {
          if (onInvalid) {
            onInvalid(name, 'must select from the list.', me.el, messageArray)
            return false
          }
        }
        minLength = minLength || min
        maxLength = maxLength || max

        if (typeof v === 'string' && minLength && minLength < (v || '').length) {
          onInvalid?.(name, 'must be have min length of ' + minLength, me.el, messageArray)
          return false
        }
        if (typeof v === 'string' && maxLength && maxLength < (v || '').length) {
          onInvalid?.(name, 'must be have max length of ' + maxLength, me.el, messageArray)
          return false
        }

        if (validation && typeof validation === 'object') {
          if (validation.ne !== undefined && v === validation.ne) {
            onInvalid?.(name, 'cannot be ' + validation.ne, me.el, messageArray)
            return false
          }

          if (validation.url) {
            if (!new RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i).test(v)) {
              onInvalid?.(name, __('is not a valid URL'), me.el, messageArray)
              return false
            }
          }

          if (validation.regEx) {
            if (new RegExp(validation.regEx).test(v)) {
              onInvalid?.(name, __('should match ' + validation.regEx), me.el, messageArray)
              return false
            }
          }
        }

        let { isToField, fromCmp } = me.opts

        // Validation for fields with from value and to value
        if (['date', 'time', 'currency', 'float', 'int'].find(x => x === ctlType)) {
          if (isToField && fromCmp) {
            let fromField, toField
            let v = me.val()
            toField = typeof v === 'number' ? formatString(v) : v

            const vFrom = fromCmp.val()
            fromField = typeof vFrom === 'number' ? formatString(vFrom) : vFrom

            if (isNumeric(fromField) && isNumeric(toField)) {
              fromField = fromField * 1
              toField = toField * 1
            }
            if (fromField > toField) {
              if (onInvalid) {
                onInvalid(
                  fromCmp.label + ' field',
                  'From field must be always lower than to field',
                  me.el,
                  messageArray
                )
                return false
              }
            }
          }
        }

        return true
      },

      displayValidity(bValid, msg) {
        if (bValid) {
          me.removeClass('k-input-errbg').wrap().removeClass('k-input-errbg')
          me.invalidMsg = ''
          me.el.title = ''
        } else {
          me.invalidMsg = msg
          me.el.title = msg
          me.addClass('k-input-errbg').wrap().addClass('k-input-errbg')
        }
      },

      readFilter(filters) {
        const { opts } = me
        let v = me.val()

        // me.isSet = true // indicates to the outside that there's a filter set on this field/col

        me.op || (me.op = me.opts.op ?? me.defOp ?? 'eq')

        const operator = me.op

        let filter = {
          field: opts.fieldName,
          operator,
          value: v
        }

        if (opts.textFilterField && filter.value) {
          filter.field = opts.textFilterField
          filter.operator = operator ?? 'contains'
          filter.value = me.text() ?? null
        }

        if (opts.filterMap && filter.value) {
          const s = filter.value.toLowerCase()
          const matchFilter = v => filterFunctions[filter.operator](v.toLowerCase(), s)

          filter.value = Object.keys(opts.filterMap)
            .filter(matchFilter)
            .map(k => opts.filterMap[k])

          if (filter.value.length === 0) {
            ow.popInvalid(__('Invalid value for ' + filter.field))
            me.text('')
            return filters
          }
          filter.operator = 'in'
        }

        if (
          (filter.value !== '' && filter.value !== opts.blankValue) ||
          common.ow7.allowFilterUndefined[filter.operator]
        )
          filters.push(filter)

        return filters
      }
    })
    .on('init', () => (me.el.opts = me.opts))
    .on('focus', () => {
      //normalize
      var decimal = culture().numberFormat['.']
      if (!me.el.value) return
      if (opts === 'currency')
        me.el.value = ('' + ctlParsers.currency(me.el.value)).replace('.', decimal)
      else if (opts.type === 'float')
        me.el.value = ('' + ctlParsers.float(me.el.value)).replace('.', decimal)
    })
    .on('keydown', () => (lastValue = me.val()))
    .on('keyup', e => {
      if (e.which === 9) return
      if (e.which === 13 && !e.shiftKey) me.resolve?.() // enter

      // const hasChanged = lastValue !== me.el.value

      if (me.type === 'float' || me.type === 'currency' || me.type === 'int') {
        if (e.which > 46 || e.which === 8) {
          var decimal = culture().numberFormat['.'] //use standard decimal

          const { el } = me

          let isFloat = me.type === 'float' || me.type === 'currency'
          if (!isFloat) decimal = ''

          let validChars = Array.prototype.reduce.call(
            '-0123456789' + decimal,
            (t, c) => (t[c] = 1) && t,
            {}
          )
          let value = Array.prototype.filter.call(el.value, x => x in validChars).join('')

          // only a single decimal point
          value = value.split(decimal)
          if (value.length > 1) value[1] = decimal + value[1]
          value = value.join('')

          if (value !== el.value) el.value = value
        }
      }

      me.triggerChange()
    })
    .on('popup', () => {
      const defaultCallback = ($win, viewdata) => {
        if (viewdata.result) {
          var v = viewdata.result
          if (opts.fieldName) v = _v(viewdata.result, opts.fieldName)
          if (opts.model) {
            me.select?.(viewdata.result)
            _v(opts.model, opts.fieldName, v)
          }
          me.trigger('change')
          me.el.focus()
        }
      }

      if (typeof opts.popUp === 'function') opts.popUp(me, defaultCallback)
    })
    .on('blur', () => {
      if ({ float: true, int: true, currency: true }[me.type]) me.val(me.val())
    })

  const _w = qc(
    (opts.ow5 ? 'div' : 'span') + '.ow-ctl-wrap.input7.ow-textbox',
    opts.popUp
      ? [
          me,
          icon('magnifier')
            .bindState(
              () => me.disabled,
              (d, i) =>
                i.css({
                  cursor: !d ? 'pointer' : undefined,
                  color: d ? theme.disabledIcon : theme.iconBlue
                })
            )
            .on('click', e => {
              if (me.disabled) return killEvent(e)
              me.el.focus()
              me.trigger('popup')
            })
        ]
      : me
  ).props({
    input: me
  })

  if (opts.popUp) _w.addClass('text-icon-after')
  me.wrap = () => _w
  if (opts.width)
    opts.width === 'full'
      ? _w.addClass('w4')
      : ['w1', 'w2', 'w3', 'w4'].includes(me.width)
        ? _w.addClass(opts.width)
        : _w.css({ width: opts.width })

  if (opts.width) _w.addClass(opts.width)

  me.attr({ placeholder: opts.placeholder ?? opts.label ?? undefined })

  me._rs = rs({ ...opts }, _w)
  _w.rs = () => opts.rs ?? me._rs
  me.rs = () => opts.rs ?? me._rs

  if (opts.disabled) disable(me, true)

  return me
}

const formatV = (v, fmt, formatter) => {
  if (formatter) return formatter(v, fmt) || ''
  if (v && fmt) return formatString(v, fmt) || ''
  return v === null || v === undefined ? '' : formatString(v)
}

const text7 = opts => {
  if (typeof opts === 'string') opts = { name: opts }
  if (opts.name === undefined) opts.name = opts.fieldName ?? opts.label
  if (opts.label === undefined) opts.label = __(opts.name ?? opts.fieldName)

  if (!('blankValue' in opts)) {
    opts.blankValue = ''
    if (opts.type === 'currency') opts.blankValue = undefined
    if (opts.type === 'float') opts.blankValue = undefined
    if (opts.type === 'int') opts.blankValue = undefined
    if (opts.type === 'date') opts.blankValue = undefined
    if (opts.type === 'datetime') opts.blankValue = undefined
    if (opts.type === 'time') opts.blankValue = undefined
  }
  opts.parser = opts.parser || ctlParsers[opts.type] || null
  opts.formatter = opts.formatter || ctlFormatters[opts.type] || null

  const me = input7(opts).addClass('text7').props({ opts })

  dataBind(me)
  if (opts.isFilterControl) opts.op = opts.op ?? 'contains'

  if (opts.value !== undefined) me.val(opts.value, opts.model, true)

  if (opts.model) me.populate(opts.model)

  return me
}

module.exports = { text7, input7 }
